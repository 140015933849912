import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add, Check } from "@material-ui/icons";
import * as React from "react";
import { useQuery } from "react-query";
import { RouteComponentProps } from "react-router";
import { useAxios } from "../../../../api/useAxios";
import { useAuth } from "../../../../auth/AuthContext";
import ClickableTableRow from "../../../../components/ClickableTableRow";
import { RequirePermission } from "../../../../components/RequirePermission";
import Rows from "../../../../components/Rows";
import ServiceProviderAgreementCreator from "./components/create/ServiceProviderAgreementCreator";
import ServiceProviderAgreementEditor from "./components/edit/ServiceProviderAgreementEditor";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: "2.5rem 3rem 0.5rem",
    },
    titleContainer: {
      paddingBottom: "1.5rem",
    },
    title: {
      paddingRight: "1rem",
    },
    detailsContainer: {
      marginTop: "2rem",
      padding: "2rem",
    },
    saveButton: {
      marginLeft: "1.2rem",
    },
    headerButton: {
      float: "right",
    },
  })
);

const NemIdConfiguration = (props: RouteComponentProps<{}>) => {
  const axios = useAxios();
  const classes = useStyles();
  const { permissions } = useAuth();
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [
    currentServiceProviderAgreementId,
    setCurrentServiceProviderAgreementId,
  ] = React.useState("");

  const serviceProviderAgreementsState = useQuery(
    ["serviceProviderAgreements", new Date().getHours()],
    fetchServiceProviderAgreements
  );
  const { data: agreements, refetch: refreshAgreements } =
    serviceProviderAgreementsState;

  async function fetchServiceProviderAgreements() {
    const response = await axios.get<ServiceProviderAgreementDTO[]>(
      `/nem-id/service-provider-agreements`
    );
    return response.data.sort((a, b) =>
      a.name.localeCompare(
        b.name,
        navigator.languages.filter((x) => x.length === 2)?.[0] || "en"
      )
    );
  }

  function create(): void {
    setOpenCreateDialog(true);
  }

  function edit(id: string): void {
    setCurrentServiceProviderAgreementId(id);
    setOpenEditDialog(true);
  }

  function handleCreateDialogClose(): Promise<
    ServiceProviderAgreementDTO[] | undefined
  > {
    setOpenCreateDialog(false);
    return refreshAgreements();
  }

  function handleEditDialogClose(): Promise<
    ServiceProviderAgreementDTO[] | undefined
  > {
    setOpenEditDialog(false);
    setCurrentServiceProviderAgreementId("");
    return refreshAgreements();
  }

  function renderRow(item: ServiceProviderAgreementDTO) {
    if (permissions.includes("NEMID_WRITE")) {
      return (
        <Tooltip title="Edit Agreement" key={item.id}>
          <ClickableTableRow onClick={() => edit(item.id)} hover={true}>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.pidServiceProviderId}</TableCell>
            <TableCell>{item.ridServiceProviderId}</TableCell>
            <TableCell>
              {item.certificateType ? item.certificateType : "-"}
            </TableCell>
            <TableCell>{item.production ? <Check /> : null}</TableCell>
          </ClickableTableRow>
        </Tooltip>
      );
    } else {
      return (
        <TableRow key={item.id}>
          <TableCell>{item.name}</TableCell>
          <TableCell>{item.pidServiceProviderId}</TableCell>
          <TableCell>{item.ridServiceProviderId}</TableCell>
          <TableCell>
            {item.certificateType ? item.certificateType : "-"}
          </TableCell>
          <TableCell>{item.production ? <Check /> : null}</TableCell>
        </TableRow>
      );
    }
  }

  return (
    <div className={classes.container}>
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.titleContainer}
      >
        <Grid item>
          <Typography variant="h3" color="primary" className={classes.title}>
            Configure NemID
          </Typography>
        </Grid>
      </Grid>
      <Paper elevation={4} className={classes.detailsContainer}>
        <Typography variant="h5" className={classes.title}>
          Service Provider agreements
          <RequirePermission permission={"NEMID_WRITE"}>
            <Tooltip className={classes.headerButton} title="Create Agreement">
              <Button
                color="primary"
                variant="contained"
                onClick={() => create()}
              >
                <Add />
              </Button>
            </Tooltip>
          </RequirePermission>
        </Typography>
        <RequirePermission permission={"NEMID_READ"}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>PID</TableCell>
                <TableCell>RID</TableCell>
                <TableCell>Certificate Type</TableCell>
                <TableCell>Production</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Rows
                colSpan={6}
                data={agreements}
                dataApiState={serviceProviderAgreementsState}
                fetchingText="We are fetching the agreements, please wait..."
                noDataText="No Service provider agreements"
                errorText="Failed to fetch the agreements. Try again later"
                rowRender={renderRow}
              />
            </TableBody>
          </Table>
        </RequirePermission>
        <RequirePermission permission={"NEMID_WRITE"}>
          <ServiceProviderAgreementCreator
            isOpen={openCreateDialog}
            onClose={handleCreateDialogClose}
            afterSubmit={handleCreateDialogClose}
          />
          <ServiceProviderAgreementEditor
            isOpen={openEditDialog}
            onClose={handleEditDialogClose}
            afterSubmit={handleEditDialogClose}
            id={currentServiceProviderAgreementId}
          />
        </RequirePermission>
      </Paper>
    </div>
  );
};

export default NemIdConfiguration;
