import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { useQuery } from "react-query";
import { useAxios } from "../../../../../api/useAxios";
import { HTTPHeaderDTO } from "../../../../../models/HTTPHeader";
import { TopicDTO } from "../../../../../models/Topic";
import {
  CreateWebhookDTO,
  UpdateWebhookDTO,
  WebhookDTO,
} from "../../../../../models/Webhook";
import HTTPHeaderDialog from "./HTTPHeaderDialog";

const useStyles = makeStyles((theme) =>
  createStyles({
    errorText: {
      color: theme.palette.error.main,
    },
  })
);

interface Props {
  isOpen: boolean;
  onClose: (
    data?: CreateWebhookDTO | UpdateWebhookDTO,
    webhookId?: string
  ) => void;
  webhookData?: WebhookDTO;
}

const CreateOrUpdateWebhookDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  webhookData,
}) => {
  const classes = useStyles();
  const [httpHeaders, setHTTPHeaders] = React.useState<HTTPHeaderDTO[] | null>(
    webhookData?.httpHeaders || null
  );
  const [topicIds, setTopicIds] = React.useState<string[]>(
    webhookData?.topics.map((x) => x.id) || []
  );
  const [url, setUrl] = React.useState<string>(webhookData?.url || "");
  const [httpHeaderDialogOpen, setHTTPHeaderDialogOpen] = React.useState(false);
  const axios = useAxios();

  const topicQuery = useQuery("topics", fetchTopics);

  async function fetchTopics() {
    const result = await axios.get<TopicDTO[]>("/topics");
    return result.data;
  }

  React.useEffect(() => {
    if (webhookData) {
      setHTTPHeaders(webhookData.httpHeaders);
      setTopicIds(webhookData.topics.map((x) => x.id));
      setUrl(webhookData.url);
    }
  }, [webhookData]);

  function handleSubmit() {
    const submitData: CreateWebhookDTO | UpdateWebhookDTO = {
      httpHeaders: httpHeaders,
      topicIds: topicIds,
      url: url,
    };
    if (webhookData) {
      onClose(submitData, webhookData.id);
    } else {
      onClose(submitData);
    }
  }

  function handleTopicIdsChanged(event: React.ChangeEvent<{ value: unknown }>) {
    setTopicIds(event.target.value as string[]);
  }

  function handleHTTPHeaderDialogClosed(data?: HTTPHeaderDTO[]) {
    if (data) {
      setHTTPHeaders(data);
    }

    setHTTPHeaderDialogOpen(false);
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => onClose()}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle>{webhookData ? "Edit" : "Create"} webhook</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            label="URL"
            value={url}
            required
            onChange={(event) => setUrl(event.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel id="topic-select">Topics</InputLabel>
            <Select
              labelId="topic-select"
              id="topics"
              multiple
              value={topicIds}
              onChange={handleTopicIdsChanged}
              input={<Input />}
              renderValue={(selectedIds) =>
                (selectedIds as string[])
                  .map((id) => topicQuery.data?.find((x) => x.id === id)?.name)
                  .join(", ")
              }
            >
              {topicQuery.isLoading ? (
                <MenuItem disabled key="item-loading">
                  Loading topics...
                </MenuItem>
              ) : (
                topicQuery.data?.map((topic, index) => (
                  <MenuItem key={`topic-${index}`} value={topic.id}>
                    {topic.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <Box mt={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setHTTPHeaderDialogOpen(true)}
              >
                Edit HTTP Headers
              </Button>
            </Box>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <HTTPHeaderDialog
        httpHeaders={httpHeaders || undefined}
        isOpen={httpHeaderDialogOpen}
        handleClose={handleHTTPHeaderDialogClosed}
      />
    </>
  );
};

export default CreateOrUpdateWebhookDialog;
