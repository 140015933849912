import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import * as React from "react";
import { RefObject, useEffect } from "react";
import { useAxios } from "../../../../../../api/useAxios";

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: "4px",
      width: "100%",
      padding: "15px",
    },
    hidden: {
      // I don't use "display: none" here because I want it to be focusable
      width: "0",
      height: "0",
      border: "none",
      background: "transparent",
    },
  })
);

interface Props {
  onChange: (permissions: Permission[]) => any;
  selected?: Permission[];
  required?: boolean;
}

export const PermissionSelect = ({ required = false, ...props }: Props) => {
  const axios = useAxios();
  const classes = useStyles();
  const [selected, setSelected] = React.useState<Permission[]>([]);
  const [options, setOptions] = React.useState<PermissionDTO[]>([]);
  const [error, setError] = React.useState<boolean>(false);

  const hiddenInput = React.useRef() as RefObject<HTMLInputElement>;

  useEffect(() => {
    axios.get<PermissionDTO[]>("/users/permissions").then((response) => {
      setOptions(response.data);
    });
  }, [axios]);

  const selectedValue =
    props.selected != null ? JSON.stringify(props.selected) : "";
  React.useEffect(() => {
    setSelected(props.selected || []);
  }, [selectedValue]);

  function handleCheck(option: PermissionDTO, checked: boolean) {
    const result = checked
      ? [...Array.from(new Set(selected).add(option.permission))]
      : selected.filter((permission) => permission !== option.permission);
    setSelected(result);
    validate();
    props.onChange(result);
  }

  function validate() {
    const hasNotSelected = !selected?.length;
    setError(required && hasNotSelected);
    // In order for the required-property to be respected on form submit we make a hidden field required and fill it when needed
    hiddenInput.current!.value = hasNotSelected ? "" : "ok";
    hiddenInput.current!.blur();
  }

  return (
    <FormControl
      required={required}
      error={error}
      component="fieldset"
      className={classes.formControl}
    >
      <FormLabel component="legend">Assign Permissions</FormLabel>
      <FormGroup>
        {options.map((option) => (
          <Tooltip title={option.description} key={option.permission}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={selected.includes(option.permission)}
                  onChange={(event) =>
                    handleCheck(option, event.target.checked)
                  }
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              }
              label={option.display}
            />
          </Tooltip>
        ))}
        <input
          type="text"
          className={classes.hidden}
          ref={hiddenInput}
          onFocus={validate}
          required={required}
          name="permissionSelect"
        />
      </FormGroup>
    </FormControl>
  );
};
