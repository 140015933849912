import * as React from "react";
import { Switch, RouteComponentProps, Route } from "react-router";
import NemIdConfiguration from "./pages/NemIdConfiguration";

const NemId = (props: RouteComponentProps<{}>) => (
  <Switch>
    <Route path={props.match.path} exact component={NemIdConfiguration} />
  </Switch>
);

export default NemId;
