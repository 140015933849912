import {
  Backdrop,
  Button,
  CircularProgress,
  Fade,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Edit as EditIcon,
  PeopleAlt as PeopleAltIcon,
} from "@material-ui/icons";
import { format } from "date-fns";
import React from "react";
import {
  queryCache,
  useMutation,
  usePaginatedQuery,
  useQuery,
} from "react-query";
import { RouteComponentProps } from "react-router-dom";
import { useAxios } from "../../../../api/useAxios";
import BreadcrumbsNavigation from "../../../../components/BreadcrumbsNavigation";
import ClickableTableRow from "../../../../components/ClickableTableRow";
import DeleteButton from "../../../../components/DeleteButton";
import DetailsView from "../../../../components/DetailsView";
import EditConsentTypeDialog from "../../../../components/EditConsentTypeDialog";
import IconText from "../../../../components/IconText";
import Rows from "../../../../components/Rows";
import { ConsentTypeDetailsDTO } from "../../../../models/ConsentTypes";
import { AppDetailsConsentVersionDTO } from "../../../../models/Consents";
import { PagedDTO } from "../../../../models/Paged";

const useStyles = makeStyles((theme) =>
  createStyles({
    nameContainer: {
      paddingBottom: "4.5rem",
    },
    footerContainer: {
      paddingBottom: "2rem",
    },
    centeredLoadingDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "1.7rem",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

interface RouteProps {
  consentTypeGroupId: string;
  consentTypeId: string;
}

type Props = RouteComponentProps<RouteProps>;

const ConsentTypeDetails = ({ match, history }: Props) => {
  const classes = useStyles();
  const consentTypeId = match.params.consentTypeId;

  const {
    data: consentTypeDetails,
    refetch: refreshConsentTypeDetails,
    status: consentTypeDetailsStatus,
  } = useQuery(["consentTypeDetails", consentTypeId], fetchConsentTypeDetails);
  const [createFromCopyMutate] = useMutation(createConsentVersionFromCopy);

  const [consentPage, setConsentPage] = React.useState(0);
  const [consentPageSize] = React.useState(20);

  const consentsStatus = usePaginatedQuery(
    ["consentTypeDetailsConsent", consentTypeId, consentPage, consentPageSize],
    fetchConsentTypeConsents
  );

  const [isEditingConsentType, setIsEditingConsentType] = React.useState(false);
  const [isDeletingConsentType, setIsDeletingConsentType] =
    React.useState(false);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const axios = useAxios();

  const isChangingConsentType = isEditingConsentType || isDeletingConsentType;

  async function fetchConsentTypeDetails(key: string, consentTypeId: string) {
    const result = await axios.get<ConsentTypeDetailsDTO>(
      `/consentTypes/${consentTypeId}/details`
    );
    return result.data;
  }

  async function fetchConsentTypeConsents(
    key: string,
    consentTypeId: string,
    page = 0,
    pageSize = 20
  ) {
    const result = await axios.get<PagedDTO<AppDetailsConsentVersionDTO>>(
      `/consentTypes/${consentTypeId}/consentVersions/details?size=${pageSize}&page=${page}`
    );
    return result.data;
  }

  async function createConsentVersionFromCopy(consentVersionId: string) {
    await axios.post<ConsentVersionDTO>(
      `/consentTypes/${consentTypeId}/consentVersions/${consentVersionId}/copy`
    );
    queryCache.invalidateQueries("consentTypeDetailsConsent");
  }

  function deleteConsentType() {
    setIsDeletingConsentType(true);
    axios
      .delete(`/consentTypes/${consentTypeId}`)
      .then(() => {
        setIsDeletingConsentType(false);
        history.push(`/`);
      })
      .catch(() => {
        // TODO: Show error
        setIsDeletingConsentType(false);
      });
  }

  function clickedConsentRow(consentVersionId: string) {
    history.push(`${match.url}/consents/${consentVersionId}`);
  }

  function handleOpenEditModal() {
    setIsEditingConsentType(true);
  }

  function handleCloseEditModal() {
    setIsEditingConsentType(false);
  }

  function handleEditSuccess() {
    setIsEditingConsentType(false);
    refreshConsentTypeDetails();
  }

  function createNewConsent() {
    history.push(`${match.url}/newConsent`);
  }

  function handleChangePage(event: unknown, newPage: number) {
    setConsentPage(newPage);
  }

  const handleCopyClicked =
    (versionId: string) => async (event: React.MouseEvent) => {
      event.stopPropagation();
      setBackdropOpen(true);
      await createFromCopyMutate(versionId);
      setBackdropOpen(false);
    };

  return (
    <DetailsView
      details={
        <>
          <BreadcrumbsNavigation
            crumbs={[{ name: "Groups", path: "/" }]}
            current="Consent type details"
          />
          {consentTypeDetailsStatus === "loading" ? (
            <Fade
              in={consentTypeDetailsStatus === "loading"}
              style={{
                transitionDelay:
                  consentTypeDetailsStatus === "loading" ? "800ms" : "0ms",
              }}
              unmountOnExit
            >
              <div className={classes.centeredLoadingDiv}>
                <CircularProgress size={50} />
              </div>
            </Fade>
          ) : (
            <Grid
              container
              alignItems="center"
              justify="flex-start"
              item
              spacing={1}
              className={classes.nameContainer}
            >
              <Typography variant="h3" color="primary">
                {consentTypeDetails && consentTypeDetails.name}
              </Typography>
            </Grid>
          )}
          {isEditingConsentType && consentTypeDetails != null ? (
            <EditConsentTypeDialog
              consentType={consentTypeDetails}
              isOpen={true}
              onCancel={handleCloseEditModal}
              onSuccess={handleEditSuccess}
            />
          ) : null}
        </>
      }
      detailsFooter={
        <div className={classes.footerContainer}>
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleOpenEditModal}
                disabled={isChangingConsentType}
              >
                <IconText icon={EditIcon} fontSize="small">
                  Edit
                </IconText>
              </Button>
            </Grid>
            <Grid item>
              <DeleteButton
                variant="contained"
                title="Consent Type"
                buttonText="Delete"
                onDelete={deleteConsentType}
                disabled={isChangingConsentType}
              >
                Are you sure you want to delete the consent type?
              </DeleteButton>
            </Grid>
          </Grid>
        </div>
      }
      listHeader={<Typography variant="h4">Consents</Typography>}
      listActions={
        <Button variant="contained" color="primary" onClick={createNewConsent}>
          <IconText icon={AddIcon}>New Consent</IconText>
        </Button>
      }
      list={
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Published</TableCell>
                <TableCell>Validation Setting</TableCell>
                <TableCell>
                  <IconText
                    icon={PeopleAltIcon}
                    fontSize="small"
                    justify="flex-end"
                  >
                    Users
                  </IconText>
                </TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Rows
                colSpan={6}
                dataApiState={consentsStatus}
                data={
                  consentsStatus.resolvedData &&
                  consentsStatus.resolvedData.data
                }
                fetchingText="We are fetching the consent type's consents, please wait..."
                noDataText=""
                errorText=""
                rowRender={(item) => (
                  <ClickableTableRow
                    key={item.versionId}
                    onClick={() => clickedConsentRow(item.versionId)}
                  >
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      {format(item.createdAt, "yyyy-MM-dd HH:mm:ss")}
                    </TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>
                      {item.publishedAt &&
                        format(item.publishedAt, "yyyy-MM-dd HH:mm:ss")}
                    </TableCell>
                    <TableCell>
                      {item.usingGlobalUserValidationSetting &&
                      item.userValidationSettingName !== null ? (
                        <em>
                          {item.userValidationSettingName} (from datacontroller)
                        </em>
                      ) : !item.usingGlobalUserValidationSetting ? (
                        item.userValidationSettingName
                      ) : (
                        <strong>None assigned</strong>
                      )}
                    </TableCell>
                    <TableCell align="right">{item.usersCount}</TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleCopyClicked(item.versionId)}
                      >
                        Copy to new
                      </Button>
                    </TableCell>
                  </ClickableTableRow>
                )}
              />
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={consentsStatus?.resolvedData?.totalItems ?? 0}
            rowsPerPage={consentPageSize}
            page={consentPage}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onPageChange={handleChangePage}
          />
          <Backdrop
            className={classes.backdrop}
            open={backdropOpen}
            onClick={() => setBackdropOpen(false)}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      }
    />
  );
};

export default ConsentTypeDetails;
