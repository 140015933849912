import { Grid, createStyles, makeStyles } from "@material-ui/core";
import {
  Assignment as AssignmentIcon,
  Description as DescriptionIcon,
  PeopleAlt as PeopleAltIcon,
} from "@material-ui/icons";
import React from "react";
import { useQuery } from "react-query";
import { RouteComponentProps } from "react-router-dom";
import { useAxios } from "../../api/useAxios";
import { DashboardOverviewDTO } from "../../models/Dashboard";
import ConsentTypeBrowser from "./components/ConsentTypeBrowser";
import OverviewItem from "./components/OverviewItem";

type Props = RouteComponentProps<{}>;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: "0",
    },
    headerContainer: {
      backgroundColor: "#fff",
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    headerTitleContainer: {
      paddingBottom: "3rem",
    },
    groupContainer: {
      padding: "2.5rem 3rem 0",
    },
    tableContainerItem: {
      paddingBottom: theme.spacing(2),
    },
  })
);

const Dashboard: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { data: overview } = useQuery("dashboardOverview", fetchOverview);

  const axios = useAxios();

  async function fetchOverview() {
    const overviewResult = await axios.get<DashboardOverviewDTO>(
      "/dashboard/overview"
    );
    return overviewResult.data;
  }

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={0}
        className={classes.container}
        alignItems="stretch"
      >
        <Grid
          item
          container
          direction="column"
          alignItems="stretch"
          spacing={0}
          className={classes.headerContainer}
        >
          <Grid item container justify="space-evenly">
            <Grid item xs sm={4}>
              <OverviewItem
                icon={AssignmentIcon}
                title="Consent Types"
                count={overview && overview.consentTypeCount}
              />
            </Grid>
            <Grid item xs sm={4}>
              <OverviewItem
                icon={PeopleAltIcon}
                title="Users"
                count={overview && overview.userCount}
              />
            </Grid>
            <Grid item xs sm={4}>
              <OverviewItem
                icon={DescriptionIcon}
                title="Consents"
                count={overview && overview.consentVersionCount}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConsentTypeBrowser />
    </>
  );
};

export default Dashboard;
