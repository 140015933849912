import React from "react";
import {
  HashRouter as Router,
  Link as RouterLink,
  Route,
  Switch,
} from "react-router-dom";
import { useAuth } from "./auth/AuthContext";
import {
  Divider,
  Drawer,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import NotFound from "./pages/NotFound";
import Dashboard from "./pages/Dashboard";
import ConsentTypes from "./pages/ConsentTypes";
import Scopes from "./pages/Scopes";
import DataControllers from "./pages/DataControllers";
import {
  Build as BuildIcon,
  ChevronRight as ChevronRightIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  EmojiPeople as EmojiPeopleIcon,
  MeetingRoom as MeetingRoomIcon,
  PeopleAlt as PeopleAltIcon,
  Settings as SettingIcon,
  ScreenLockLandscape,
} from "@material-ui/icons";
import Users from "./pages/Users";
import Settings from "./pages/Settings";
import { createStyles, makeStyles } from "@material-ui/styles";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import { useDataController } from "./auth/DataControllerContext";
import NemId from "./pages/NemId";

const useStyles = makeStyles((theme) =>
  createStyles({
    titleBarButton: {
      marginLeft: "1.2rem",
    },
    centeredDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      flexDirection: "column",
    },
    appContainer: {
      padding: 0,
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    fullSizeDiv: {
      flex: 1,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: "240px",
      flexShrink: 0,
    },
    drawerPaper: {
      width: "240px",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: "0 1em",
      justifyContent: "flex-end",
    },
  })
);

const App = () => {
  const { isLoggedIn, isLoggingIn } = useAuth();
  const { datacontrollersLoaded, selectedDataController, dataControllers } =
    useDataController();
  if (isLoggedIn) {
    return <LoggedInPage key={selectedDataController?.id} />;
  }

  if (!isLoggingIn && datacontrollersLoaded && dataControllers.length === 0) {
    return <NoDataControllersPage />;
  }

  return <LoggingInPage />;
};

const LoggingInPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.centeredDiv}>
      <Typography variant="h2" component="h3">
        Loading...
      </Typography>
    </div>
  );
};

const NoDataControllersPage = () => {
  const classes = useStyles();

  return (
    <Fade in style={{ transitionDelay: "200ms" }}>
      <div className={classes.centeredDiv}>
        <Typography variant="h2" component="h3">
          You do not have any data controllers assigned.
        </Typography>
        <Typography variant="h4">
          Contact your administrator to get data controllers assigned to your
          account.
        </Typography>
      </div>
    </Fade>
  );
};

const LoggedInPage = () => {
  const { logout, isGlobalAdmin } = useAuth();
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);

  const handleDrawerClose = () => {
    setMenuOpen(false);
  };

  const toggleDrawer = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Router>
      <>
        <NavigationBar toggleDrawer={toggleDrawer} menuOpen={menuOpen} />
        <Drawer
          className={classes.drawer}
          anchor="right"
          open={menuOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerClose}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            {isGlobalAdmin && (
              <ListItem
                button
                key="manage-datacontrollers"
                color="inherit"
                component={RouterLink}
                onClick={handleDrawerClose}
                to="/datacontrollers"
              >
                <ListItemIcon>
                  <EmojiPeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Datacontrollers" />
              </ListItem>
            )}
            <ListItem
              button
              key="frontpage"
              color="inherit"
              component={RouterLink}
              onClick={handleDrawerClose}
              to="/"
            >
              <ListItemIcon>
                <AssignmentTurnedInIcon />
              </ListItemIcon>
              <ListItemText primary="Consents" />
            </ListItem>
            <ListItem
              button
              key="users"
              color="inherit"
              component={RouterLink}
              onClick={handleDrawerClose}
              to="/users"
            >
              <ListItemIcon>
                <PeopleAltIcon />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
            <ListItem
              button
              key="nemid"
              color="inherit"
              component={RouterLink}
              onClick={handleDrawerClose}
              to="/nem-id"
            >
              <ListItemIcon>
                <ScreenLockLandscape />
              </ListItemIcon>
              <ListItemText primary="NemID" />
            </ListItem>
            <ListItem
              button
              key="scopes"
              color="inherit"
              component={RouterLink}
              onClick={handleDrawerClose}
              to="/scopes"
            >
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText primary="API" />
            </ListItem>
            <ListItem
              button
              key="settings"
              color="inherit"
              component={RouterLink}
              onClick={handleDrawerClose}
              to="/settings"
            >
              <ListItemIcon>
                <SettingIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button key="logout" color="primary" onClick={logout}>
              <ListItemIcon>
                <MeetingRoomIcon />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItem>
          </List>
        </Drawer>
        <div className={classes.appContainer}>
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/consentTypes" component={ConsentTypes} />
            <Route path="/scopes" component={Scopes} />
            <Route path="/users" component={Users} />
            <Route path="/nem-id" component={NemId} />
            <Route path="/settings" component={Settings} />
            {isGlobalAdmin && (
              <Route path="/datacontrollers" component={DataControllers} />
            )}
            <Route component={NotFound} />
          </Switch>
        </div>
      </>
    </Router>
  );
};

export default App;
