import * as React from "react";
import { MutableRefObject, useEffect } from "react";
import { useAxios } from "../../../../../../api/useAxios";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { PermissionSelect } from "./PermissionSelect";

const useStyles = makeStyles((theme) =>
  createStyles({
    saveButton: {
      marginLeft: "1.2rem",
    },
    closeButton: {
      float: "right",
    },
  })
);

interface Props {
  isOpen: boolean;
  onClose: () => any;
  afterSubmit: () => any;
}

export const CreateUserDialog = (props: Props) => {
  const axios = useAxios();
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [user, setUser] = React.useState<CreateUserDTO>({} as CreateUserDTO);
  const formRef =
    React.useRef<HTMLFormElement>() as MutableRefObject<HTMLFormElement>;

  useEffect(() => {
    setUser({} as CreateUserDTO);
  }, [props.isOpen]);

  function save() {
    if (!formRef.current.reportValidity()) {
      return;
    }

    setIsSubmitting(true);
    axios.post("/users", user).finally(() => {
      setIsSubmitting(false);
      props.afterSubmit();
    });
  }

  function handleFieldUpdate(field: string, value: any) {
    (user as any)[field] = value;
    setUser({ ...user });
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Create User
        <IconButton onClick={props.onClose} className={classes.closeButton}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form ref={formRef}>
          <TextField
            label="Email"
            fullWidth={true}
            type="text"
            required={true}
            margin="normal"
            variant="outlined"
            value={user?.email || ""}
            onChange={(event) => handleFieldUpdate("email", event.target.value)}
          />
          <PermissionSelect
            onChange={(permissions) =>
              handleFieldUpdate("permissions", permissions)
            }
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          type="submit"
          onClick={save}
          className={classes.saveButton}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUserDialog;
