import * as React from "react";
import {
  Paper,
  Typography,
  Grid,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { SvgIconComponent } from "@material-ui/icons";
import IconText from "../../../components/IconText";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.primary.main,
      padding: "2rem 4rem",
    },
    text: {
      color: theme.palette.primary.contrastText,
    },
  })
);

interface OwnProps {
  icon: SvgIconComponent;
  title: string;
  count: number | undefined | null;
}

type Props = OwnProps;

const OverviewItem = ({ icon: IconComponent, title, count }: Props) => {
  const classes = useStyles();
  return (
    <Paper square className={classes.paper}>
      <Grid container direction="column" spacing={1} alignItems="center">
        <Grid item xs>
          <Typography variant="h2" className={classes.text} align="center">
            {count || "-"}
          </Typography>
        </Grid>
        <Grid item xs>
          <IconText
            icon={IconComponent}
            fontSize="large"
            className={classes.text}
          >
            <Typography variant="h5" className={classes.text} align="center">
              {title}
            </Typography>
          </IconText>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OverviewItem;
