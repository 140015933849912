import {
  Button,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { CheckCircleOutline, ErrorOutline, Refresh } from "@material-ui/icons";
import * as React from "react";
import { MutableRefObject } from "react";
import { useQuery } from "react-query";
import { useAxios } from "../../../../../../api/useAxios";
import Rows from "../../../../../../components/Rows";
import { ServiceProviderAgreementCreatorSubmit } from "./ServiceProviderAgreementCreator";

interface Props {
  agreement: ServiceProviderAgreementDTO;
  onSubmit?: MutableRefObject<
    ServiceProviderAgreementCreatorSubmit | undefined
  >;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      marginTop: "10px",
    },
    title: {
      paddingRight: "1rem",
    },
    detailsContainer: {
      marginTop: "2rem",
      padding: "2rem",
    },
    headerButton: {
      float: "right",
    },
    success: {
      color: "green",
    },
    error: {
      color: "red",
    },
  })
);

const ServiceProviderAgreementValidation = (props: Props) => {
  const axios = useAxios();
  const classes = useStyles();
  const validationState = useQuery(
    ["validation", new Date().getHours()],
    doValidation
  );
  const { data, refetch: rerunValidation } = validationState;

  if (props.onSubmit) {
    props.onSubmit.current = () => {
      return Promise.resolve(props.agreement);
    };
  }

  async function doValidation() {
    const response = await axios.get<ServiceProviderAgreementValidationDTO[]>(
      `/nem-id/service-provider-agreements/${props.agreement.id}/validate`
    );
    return response.data;
  }

  return (
    <div className={classes.wrapper}>
      <Typography variant="h5" className={classes.title}>
        Validations
        <Tooltip className={classes.headerButton} title="Re-run tests">
          <Button
            color="primary"
            variant="contained"
            onClick={() => rerunValidation()}
          >
            <Refresh />
          </Button>
        </Tooltip>
      </Typography>
      <Table>
        <TableBody>
          <Rows
            colSpan={6}
            data={data}
            dataApiState={validationState}
            fetchingText="Validating..."
            noDataText="No tests available"
            errorText="Could not execute validation - this has nothing to do with your configuration"
            rowRender={(item) => (
              <TableRow key={item.testName}>
                <TableCell>
                  <b>{item.testName}</b>
                </TableCell>
                <TableCell>
                  {item.result ? (
                    <CheckCircleOutline className={classes.success} />
                  ) : (
                    <ErrorOutline className={classes.error} />
                  )}
                </TableCell>
              </TableRow>
            )}
          />
        </TableBody>
      </Table>
    </div>
  );
};

export default ServiceProviderAgreementValidation;
