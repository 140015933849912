import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import * as React from "react";
import { MutableRefObject, useEffect } from "react";
import { useAxios } from "../../../../../../api/useAxios";
import DeleteButton from "../../../../../../components/DeleteButton";
import { PermissionSelect } from "./PermissionSelect";

const useStyles = makeStyles((theme) =>
  createStyles({
    saveButton: {
      marginLeft: "1.2rem",
    },
    closeButton: {
      float: "right",
    },
    formControl: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: "4px",
      width: "100%",
      padding: "15px",
      marginBottom: "15px",
    },
    form: {
      minWidth: "475px",
    },
  })
);

interface Props {
  isOpen: boolean;
  onClose: () => any;
  afterSubmit: (user: EditUserDTO) => any;
  id: string;
}

export const EditUserDialog = (props: Props) => {
  const axios = useAxios();
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [user, setUser] = React.useState<EditUserDTO>({} as EditUserDTO);
  const formRef =
    React.useRef<HTMLFormElement>() as MutableRefObject<HTMLFormElement>;

  useEffect(() => {
    // Fires when we open/close the dialog
    const fetchData = async () => {
      const result = await axios.get<EditUserDTO>(`/users/${props.id}`);
      setUser(result.data);
    };
    if (props.id && props.isOpen) {
      fetchData();
    } else {
      setUser({} as EditUserDTO);
    }
  }, [props.isOpen, props.id, axios]);

  function save() {
    if (!formRef.current.reportValidity()) {
      return;
    }

    setIsSubmitting(true);
    axios.put(`/users/${props.id}`, user).finally(() => {
      setIsSubmitting(false);
      props.afterSubmit(user);
    });
  }

  function remove() {
    setIsSubmitting(true);
    axios.delete(`/users/${props.id}`).finally(() => {
      setIsSubmitting(false);
      props.afterSubmit(user);
    });
  }

  function handleFieldUpdate(field: string, value: any) {
    (user as any)[field] = value;
    setUser({ ...user });
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Edit user '{user.username}'
        <IconButton onClick={props.onClose} className={classes.closeButton}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form ref={formRef} className={classes.form}>
          {!user.fromIdentityProvider ? (
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Reset</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={
                        user.resetProfile ?? false
                      } /* User.resetProfile can be undefined on init*/
                      onChange={(event) =>
                        handleFieldUpdate("resetProfile", event.target.checked)
                      }
                      inputProps={{ "aria-label": "Reset profile" }}
                    />
                  }
                  label="Ask user to update profile"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={user.resetPassword ?? false}
                      onChange={(event) =>
                        handleFieldUpdate("resetPassword", event.target.checked)
                      }
                      inputProps={{ "aria-label": "Reset password" }}
                    />
                  }
                  label="Reset password"
                />
              </FormGroup>
            </FormControl>
          ) : null}

          <PermissionSelect
            onChange={(permissions) =>
              handleFieldUpdate("permissions", permissions)
            }
            selected={user.permissions}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <DeleteButton title="this user" buttonText="Delete" onDelete={remove} />
        <Button
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          type="submit"
          onClick={save}
          className={classes.saveButton}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
