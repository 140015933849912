import { createStyles, makeStyles, Paper, Typography } from "@material-ui/core";
import * as React from "react";
import { MutableRefObject } from "react";
import { useAxios } from "../../../../../../api/useAxios";
import DeleteIconButton from "../../../../../../components/DeleteIconButton";
import AzureKeyVaultConfig from "../securityconfiguration/AzureKeyVaultConfig";
import Pkcs12Config from "../securityconfiguration/Pkcs12Config";
import { ServiceProviderAgreementCreatorSubmit } from "./ServiceProviderAgreementCreator";

interface Props {
  agreement: ServiceProviderAgreementDTO;
  onSubmit: MutableRefObject<ServiceProviderAgreementCreatorSubmit | undefined>;
  onCertificateUpdate: (
    cert:
      | ServiceProviderAgreementKeyvaultConfigurationDTO
      | ServiceProviderAgreementSecurityConfigurationDTO
  ) => any;
  certificate:
    | ServiceProviderAgreementKeyvaultConfigurationDTO
    | ServiceProviderAgreementSecurityConfigurationDTO;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      paddingRight: "1rem",
    },
    certificateContainer: {
      padding: "1rem",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    deleteCertificateButton: {
      float: "right",
    },
  })
);

const ServiceProviderAgreementSecurity = (props: Props) => {
  const axios = useAxios();
  const classes = useStyles();
  const [hasDeletedCertificate, setDeleteCertificate] =
    React.useState<boolean>(false);
  const [dirty, setDirty] = React.useState<boolean>(false);
  const [pkcs12, setPkcs12] =
    React.useState<ServiceProviderAgreementSecurityConfigurationDTO | null>(
      (props.certificate as ServiceProviderAgreementSecurityConfigurationDTO)
        .certificate
        ? (props.certificate as ServiceProviderAgreementSecurityConfigurationDTO)
        : null
    );
  const [azure, setAzure] =
    React.useState<ServiceProviderAgreementKeyvaultConfigurationDTO | null>(
      (props.certificate as ServiceProviderAgreementKeyvaultConfigurationDTO)
        .keyVaultUrl
        ? (props.certificate as ServiceProviderAgreementKeyvaultConfigurationDTO)
        : null
    );

  props.onSubmit.current = () => {
    // Save data if parent tells us to
    if (props.agreement.certificateDetails) {
      // If this is set, we have already been here once and we may not need to do anythinig new
      if (!dirty && !hasDeletedCertificate) {
        // Nothing has changed, so we'll do nothing
        return Promise.resolve(props.agreement);
      }
    }
    const id = props.agreement.id;
    switch (props.agreement.certificateType) {
      case "PKCS12":
        return axios
          .patch<ServiceProviderAgreementDTO>(
            `/nem-id/service-provider-agreements/${id}/certificate`,
            hasDeletedCertificate ? null : pkcs12
          )
          .then((response) => {
            setDirty(false);
            return response.data;
          });
      case "AZURE":
        return axios
          .patch<ServiceProviderAgreementDTO>(
            `/nem-id/service-provider-agreements/${id}/keyvault`,
            hasDeletedCertificate ? null : azure
          )
          .then((response) => {
            setDirty(false);
            return response.data;
          });
      default:
        throw new Error(
          `Unknown certificate type '${props.agreement.certificateType}'`
        );
    }
  };

  function updateState(setState: (cert: any) => any) {
    return (cert: any) => {
      setState(cert);
      props.onCertificateUpdate(cert);
      setDirty(true);
    };
  }

  function getSecurityConfigurationFields() {
    switch (props.agreement.certificateType) {
      case "PKCS12":
        return (
          <Pkcs12Config
            certificate={pkcs12}
            required={
              !props.agreement.certificateDetails || hasDeletedCertificate
            }
            onChange={updateState(setPkcs12)}
          />
        );
      case "AZURE":
        return (
          <AzureKeyVaultConfig
            certificate={azure}
            onChange={updateState(setAzure)}
            autoFocus={true}
            serviceProviderId={props.agreement.id}
            required={
              !props.agreement.certificateDetails || hasDeletedCertificate
            }
          />
        );
      default:
        return (
          <b>
            ERROR: Unknown security configuration type '
            {JSON.stringify(props.agreement.certificateType) || "[no value]"}'
          </b>
        );
    }
  }

  return (
    <div>
      {props.agreement.certificateDetails && !hasDeletedCertificate ? (
        <div>
          <Typography variant="h5" className={classes.title}>
            Current Certificate
          </Typography>
          <Paper elevation={2} className={classes.certificateContainer}>
            <DeleteIconButton
              title="certificate"
              className={classes.deleteCertificateButton}
              onDelete={() => setDeleteCertificate(true)}
            >
              Are you sure you want to delete this certificate? Note that the
              deletion is not saved before you hit the "Save and
              continue"-button
            </DeleteIconButton>
            <p>Issuer: {props.agreement.certificateDetails?.issuer}</p>
            <p>Subject: {props.agreement.certificateDetails?.subject}</p>
            <p>
              Valid From:{" "}
              {props.agreement.certificateDetails?.validFrom.toLocaleDateString()}
            </p>
            <p>
              Valid To:{" "}
              {props.agreement.certificateDetails?.validTo.toLocaleDateString()}
            </p>
          </Paper>
          <Typography variant="h5" className={classes.title}>
            {props.agreement.certificateType === "PKCS12"
              ? "Upload New Certificate"
              : "Configure Certificate"}
          </Typography>
        </div>
      ) : null}
      {getSecurityConfigurationFields()}
    </div>
  );
};

export default ServiceProviderAgreementSecurity;
