import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import { useDeleteApiKey } from "../../../../../api/useApiKeysApi";
import DeleteIconButton from "../../../../../components/DeleteIconButton";
import UpdateApiKeyDialog from "./UpdateApiKeyDialog";

interface Props {
  apiKeys: ApiKeyBareDTO[];
}

const ApiKeysTable = ({ apiKeys }: Props) => {
  const [deleteApiKey] = useDeleteApiKey();
  const [editingApiKey, setEditingApiKey] = useState<ApiKeyBareDTO | null>(
    null
  );
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apiKeys.map((apiKey) => (
            <TableRow key={apiKey.id}>
              <TableCell>
                <Typography variant="body2">{apiKey.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {apiKey.createdAt.toLocaleString()}
                </Typography>
              </TableCell>
              <TableCell>
                <Tooltip title="Edit">
                  <IconButton onClick={() => setEditingApiKey(apiKey)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <DeleteIconButton
                  title="API Key"
                  onDelete={() => {
                    deleteApiKey(apiKey.id);
                  }}
                >
                  Are you sure you want to delete the api key? It cannot be
                  restored
                </DeleteIconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {editingApiKey ? (
        <UpdateApiKeyDialog
          data={editingApiKey}
          isOpen
          onClose={() => setEditingApiKey(null)}
        />
      ) : null}
    </>
  );
};

export default ApiKeysTable;
