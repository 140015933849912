import * as React from "react";
import styled from "styled-components/macro";
import { Paper } from "@material-ui/core";
import { generateHtml } from "./consent-template";
import { PaperProps } from "@material-ui/core/Paper";

const HtmlContainer = styled(Paper)`
  margin: 1rem 0;
  padding: 0;
  flex: 1;
`;

const StyledIFrame = styled.iframe`
  border: 0;
  width: 100%;
  height: 100%;
`;

interface Props {
  html: string;
  htmlCss: string;
  rejectText: string;
  acceptText: string;
  pageTitle: string;
  elevation?: PaperProps["elevation"];
}

const ConsentPreview = ({
  htmlCss,
  html,
  rejectText,
  acceptText,
  pageTitle,
  elevation = 4,
}: Props) => {
  const tweakedCss = `html { height: 100%; } ${htmlCss}`;
  const iFrameSrc = generateHtml({
    css: tweakedCss,
    html,
    rejectText,
    acceptText,
    pageTitle,
  });
  return (
    <HtmlContainer elevation={elevation}>
      <StyledIFrame srcDoc={iFrameSrc} sandbox="" />
    </HtmlContainer>
  );
};

export default ConsentPreview;
