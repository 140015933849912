import React from "react";
import { setDataControllerHeaderValue } from "../api/axios";
import { DataControllerDTO } from "../models/Datacontrollers";

export interface DataControllerContextState {
  loaded: boolean;
  dataControllers: Array<DataControllerDTO>;
  selectedDataController: DataControllerDTO | null;
  setDataControllers: React.Dispatch<
    React.SetStateAction<Array<DataControllerDTO>>
  >;
  setSelectedDataController: React.Dispatch<
    React.SetStateAction<DataControllerDTO | null>
  >;
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const DataControllerContext = React.createContext<
  DataControllerContextState | undefined
>(undefined);

const key = "SELECTED-DATACONTROLLER";
const DataControllerProvider = ({ ...rest }) => {
  if (React.useContext(DataControllerContext) != null) {
    throw new Error(
      "DataControllerProvider cannot be instantiated more than once"
    );
  }

  const [loaded, setLoaded] =
    React.useState<DataControllerContextState["loaded"]>(false);

  const [dataControllers, setDataControllers] = React.useState<
    DataControllerContextState["dataControllers"]
  >([]);

  const [selectedDataController, setSelectedDataController] =
    React.useState<DataControllerContextState["selectedDataController"]>(null);

  const actualSelectedDataController = React.useMemo(() => {
    if (selectedDataController != null) {
      return selectedDataController;
    }

    let usedDataController: DataControllerDTO | null = selectedDataController;
    // On the initial reset, use the one selected in localstorage, if applicable
    if (usedDataController == null) {
      // Get from local storage by key
      const currentController = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      const storedController = currentController
        ? JSON.parse(currentController)
        : undefined;

      if (storedController !== undefined) {
        usedDataController =
          dataControllers.find((c) => c.id === storedController.id) ?? null;
      }
    }

    // If the data controller is still undefined, select the first in the list
    if (usedDataController == null && dataControllers != null) {
      // TODO Handle there being no data controller
      usedDataController = dataControllers[0];
    }

    return usedDataController;
  }, [selectedDataController, dataControllers]);

  React.useEffect(() => {
    if (actualSelectedDataController == null) {
      return;
    }

    setDataControllerHeaderValue(actualSelectedDataController.realmName);

    // Save to local storage
    window.localStorage.setItem(
      key,
      JSON.stringify(actualSelectedDataController)
    );
  }, [actualSelectedDataController]);

  const value: DataControllerContextState = React.useMemo(
    () => ({
      loaded,
      dataControllers,
      selectedDataController: actualSelectedDataController,
      setDataControllers,
      setSelectedDataController,
      setLoaded,
    }),
    [dataControllers, actualSelectedDataController]
  );

  return <DataControllerContext.Provider value={value} {...rest} />;
};

const useDataController = () => {
  const context = React.useContext(DataControllerContext);

  if (!context) {
    throw new Error(
      "useDataController must be used within a DataControllerProvider"
    );
  }

  const updateDataControllers = React.useCallback(
    (dataControllers: Array<DataControllerDTO>) => {
      context.setDataControllers(dataControllers);
      context.setSelectedDataController(null);
      context.setLoaded(true);
    },
    [context]
  );

  return {
    datacontrollersLoaded: context.loaded,
    dataControllers: context.dataControllers,
    selectedDataController: context.selectedDataController,
    updateDataControllers,
    selectDataController: context.setSelectedDataController,
  };
};

export { DataControllerProvider, useDataController };
