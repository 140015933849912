import React from "react";
import {
  AppBar,
  Button,
  Hidden,
  IconButton,
  Link,
  Toolbar,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import IconText from "../IconText";
import {
  Build as BuildIcon,
  Close as CloseIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Menu as MenuIcon,
  PeopleAlt as PeopleAltIcon,
  ScreenLockPortrait,
  Settings as SettingIcon,
  EmojiPeople as EmojiPeopleIcon,
} from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useAuth } from "../../auth/AuthContext";
import DataControllerSelect from "./DatacontrollerSelect";
import { RequirePermission } from "../RequirePermission";

interface Props {
  toggleDrawer: () => void;
  menuOpen: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    titleBarButton: {
      marginLeft: "1.2rem",
    },
    centeredDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    appContainer: {
      padding: 0,
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    fullSizeDiv: {
      flex: 1,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: "240px",
      flexShrink: 0,
    },
    drawerPaper: {
      width: "240px",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: "0 1em",
      justifyContent: "flex-end",
    },
  })
);

const NavigationBar = ({ menuOpen, toggleDrawer }: Props) => {
  const { logout, isGlobalAdmin } = useAuth();

  const classes = useStyles();

  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <div className={classes.fullSizeDiv}>
          <Link
            component={RouterLink}
            to="/"
            variant="h5"
            underline="none"
            color="inherit"
          >
            Admin Center
          </Link>
        </div>
        <Hidden smDown>
          <DataControllerSelect />
          {isGlobalAdmin && (
            <Button
              component={RouterLink}
              to="/datacontrollers"
              color="inherit"
              className={classes.titleBarButton}
            >
              <IconText icon={EmojiPeopleIcon}>Manage Datacontrollers</IconText>
            </Button>
          )}
          <Button
            component={RouterLink}
            to="/"
            color="inherit"
            className={classes.titleBarButton}
          >
            <IconText icon={AssignmentTurnedInIcon}>Consents</IconText>
          </Button>
          <RequirePermission permission="DATA_OWNERS_READ">
            <Button
              component={RouterLink}
              to="/users"
              color="inherit"
              className={classes.titleBarButton}
            >
              <IconText icon={PeopleAltIcon}>Users</IconText>
            </Button>
          </RequirePermission>
          <RequirePermission anyOf={["NEMID_READ", "NEMID_WRITE"]}>
            <Button
              component={RouterLink}
              to="/nem-id"
              color="inherit"
              className={classes.titleBarButton}
            >
              <IconText icon={ScreenLockPortrait}>NemID</IconText>
            </Button>
          </RequirePermission>
          <Button
            component={RouterLink}
            to="/scopes"
            color="inherit"
            className={classes.titleBarButton}
          >
            <IconText icon={BuildIcon}>API</IconText>
          </Button>
          <Button
            component={RouterLink}
            to="/settings"
            color="inherit"
            className={classes.titleBarButton}
          >
            <IconText icon={SettingIcon}>Settings</IconText>
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={logout}
            className={classes.titleBarButton}
          >
            Logout
          </Button>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
          >
            {!menuOpen ? <MenuIcon /> : <CloseIcon />}
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;
