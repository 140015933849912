import * as React from "react";
import {
  Link,
  Breadcrumbs,
  Typography,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      paddingTop: "1.5rem",
      paddingBottom: "1.5rem",
    },
  })
);

interface Props {
  crumbs: { name: string; path: string }[];
  current: string;
}

const BreadcrumbsNavigation: React.FC<Props> = ({ crumbs, current }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Breadcrumbs aria-label="breadcrumb">
        {crumbs.map((item) => (
          <Link
            key={item.path}
            color="inherit"
            component={RouterLink}
            to={item.path}
          >
            {item.name}
          </Link>
        ))}
        <Typography color="primary">{current}</Typography>
      </Breadcrumbs>
    </div>
  );
};

export default BreadcrumbsNavigation;
