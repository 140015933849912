import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import * as React from "react";
import { RequirePermission } from "../../../../components/RequirePermission";
import ApiKeysOverview from "./components/ApiKeysOverview";
import UserValidationSettingsOverview from "./components/UserValidationSettingsOverview";
import WebhooksOverview from "./components/WebhooksOverview";
import { ManageUsers } from "./components/users/ManageUsers";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: "2.5rem 3rem 0.5rem",
    },
    titleContainer: {
      paddingBottom: "1.5rem",
    },
    title: {
      paddingRight: "1rem",
    },
    panelHeading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    panelSecondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  })
);

type Panels =
  | "user-validation-settings"
  | "webhooks"
  | "user-administration-panel"
  | "api-keys";

const SettingsOverview = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<Panels | null>(null);

  const handleChange =
    (panel: Panels) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : null);
    };

  return (
    <div className={classes.container}>
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.titleContainer}
      >
        <Grid item>
          <Typography variant="h3" color="primary" className={classes.title}>
            Settings
          </Typography>
        </Grid>
      </Grid>
      <ExpansionPanel
        expanded={expanded === "user-validation-settings"}
        onChange={handleChange("user-validation-settings")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="user-validation-settings-bh-content"
          id="user-validation-settings-bh-header"
        >
          <Typography className={classes.panelHeading}>
            User Validation Settings
          </Typography>
          <Typography className={classes.panelSecondaryHeading}>
            Manage User Validation Settings for data controller
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <UserValidationSettingsOverview />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === "webhooks"}
        onChange={handleChange("webhooks")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="webhooks-bh-content"
          id="webhooks-bh-header"
        >
          <Typography className={classes.panelHeading}>Webhooks</Typography>
          <Typography className={classes.panelSecondaryHeading}>
            Manage Webhooks
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <WebhooksOverview />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === "api-keys"}
        onChange={handleChange("api-keys")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="api-keys-bh-content"
          id="api-keys-bh-header"
        >
          <Typography className={classes.panelHeading}>API Keys</Typography>
          <Typography className={classes.panelSecondaryHeading}>
            Manage API Keys
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <ApiKeysOverview />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <RequirePermission anyOf={["USERS_READ", "USERS_WRITE"]}>
        <ExpansionPanel
          expanded={expanded === "user-administration-panel"}
          onChange={handleChange("user-administration-panel")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="user-admini stration-panel-bh-content"
            id="user-administration-panel-bh-header"
          >
            <Typography className={classes.panelHeading}>Users</Typography>
            <Typography className={classes.panelSecondaryHeading}>
              Manage administrator users
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ManageUsers />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </RequirePermission>
    </div>
  );
};

export default SettingsOverview;
