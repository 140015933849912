import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Fade,
  CircularProgress,
} from "@material-ui/core";
import { EditConsentTypeDTO } from "../models/ConsentTypes";
import { useAxios } from "../api/useAxios";
import styled from "styled-components/macro";

const Loading = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
`;

interface Props {
  consentType: ConsentTypeDTO;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}

const EditConsentTypeDialog = ({
  consentType,
  isOpen,
  onCancel,
  onSuccess,
}: Props) => {
  const [name, setName] = React.useState<string | null>(consentType.name);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setName(consentType.name);
  }, [consentType]);

  const axios = useAxios();

  function onSubmit(event: React.FormEvent) {
    event.preventDefault();
    if (name == null || name === "") {
      // TODO: Show error
      return;
    }

    const editConsentTypeDTO: EditConsentTypeDTO = {
      name,
      redirectUris: [],
    };
    setIsLoading(true);

    axios
      .put(`/consentTypes/${consentType.id}`, editConsentTypeDTO)
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        // TODO: Show error
        setIsLoading(false);
      });
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Edit Consent Type</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit} id="edit-consent-type-form">
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            fullWidth
            value={name}
            onChange={(event) => setName(event.target.value)}
            required
          />
        </form>
        <Fade
          in={isLoading}
          style={{
            transitionDelay: isLoading ? "800ms" : "0ms",
          }}
          unmountOnExit
        >
          <Loading size={50} />
        </Fade>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          form="edit-consent-type-form"
          disabled={isLoading}
          onClick={onSubmit}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditConsentTypeDialog;
