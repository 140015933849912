import {
  Backdrop,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { AxiosError, AxiosResponse } from "axios";
import React from "react";
import { queryCache, useMutation, useQuery } from "react-query";
import { useAxios } from "../../api/useAxios";
import ClickableTableRow from "../../components/ClickableTableRow";
import {
  CreateOrUpdateDataControllerDTO,
  DataControllerDetailedDTO,
} from "../../models/Datacontrollers";
import CreateOrEditDataControllerDialog from "./components/CreateOrEditDataControllerDialog";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: "2.5rem 3rem 0.5rem",
    },
    backdrop: {
      zIndex: 10000,
      color: "#fff",
    },
  })
);

interface UpdateMutateProps {
  id: string;
  dto: CreateOrUpdateDataControllerDTO;
}

const DataControllers = () => {
  const classes = useStyles();
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [spinnerOpen, setSpinnerOpen] = React.useState(false);
  const [dialogShowError, setDialogShowError] = React.useState(false);
  const [dialogErrorText, setDialogErrorText] = React.useState("");
  const [currentlyEditingData, setCurrentlyEditingData] = React.useState<
    CreateOrUpdateDataControllerDTO | undefined
  >(undefined);
  const [currentlyEditingId, setCurrentlyEditingId] = React.useState<
    string | null
  >(null);
  const { data: dataControllers, status: dataControllersStatus } = useQuery(
    "detailed-data-controllers",
    fetchDetailedDataControllers
  );
  const [createMutate] = useMutation(createDataController);
  const [updateMutate] = useMutation(updateDataController);
  const axios = useAxios();

  async function fetchDetailedDataControllers() {
    try {
      const response: AxiosResponse<DataControllerDetailedDTO[]> =
        await axios.get(`/me/datacontrollers/detailed`);
      return response.data;
    } catch (e) {
      console.error("get datacontrollers error", e);
    }

    return null;
  }

  async function handleClose(dto?: CreateOrUpdateDataControllerDTO) {
    if (dto) {
      setSpinnerOpen(true);
      setDialogShowError(false);

      if (currentlyEditingId !== null) {
        await updateMutate({ id: currentlyEditingId, dto: dto });
      } else {
        await createMutate(dto);
      }

      setSpinnerOpen(false);
    } else {
      setCreateDialogOpen(false);
    }
  }

  async function createDataController(dto: CreateOrUpdateDataControllerDTO) {
    try {
      await axios.post(`/me/datacontrollers`, dto);
      setCreateDialogOpen(false);
      queryCache.invalidateQueries("detailed-data-controllers");
    } catch (e) {
      const error = e as AxiosError;
      if (!error) {
        console.error("error object is falsy", error);
        return;
      }
      if (error.response?.status === 400) {
        setDialogErrorText("Data Controller with realm name already exist.");
      } else {
        setDialogErrorText("Request failed.");
      }
      setDialogShowError(true);
    }
  }

  async function updateDataController(data: UpdateMutateProps) {
    try {
      await axios.put(`/me/datacontrollers/${data.id}`, data.dto);
      setCreateDialogOpen(false);
      setCurrentlyEditingId(null);
      setCurrentlyEditingData(undefined);
      queryCache.invalidateQueries("detailed-data-controllers");
    } catch (e) {
      const error = e as AxiosError;
      if (!error) {
        console.error("error object is falsy", error);
        return;
      }
      if (error.response?.status === 400) {
        setDialogErrorText("Data Controller with realm name already exist.");
      } else {
        setDialogErrorText("Request failed.");
      }
      setDialogShowError(true);
    }
  }

  function handleCreateDataControllerClicked() {
    setCurrentlyEditingId(null);
    setCurrentlyEditingData(undefined);
    setCreateDialogOpen(true);
  }

  function handleDataControllerClicked(
    dataController: DataControllerDetailedDTO
  ) {
    setCurrentlyEditingData({
      displayName: dataController.displayName,
      name: dataController.name,
      realmName: dataController.realmName,
    });
    setCurrentlyEditingId(dataController.id);
    setCreateDialogOpen(true);
  }

  return (
    <>
      <div className={classes.container}>
        <Typography variant="h3" color="primary">
          Manage Datacontrollers
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateDataControllerClicked}
        >
          Create new Datacontroller
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Display Name</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <DataControllerRows
              fetchStatus={dataControllersStatus}
              dataControllers={dataControllers}
              onClick={handleDataControllerClicked}
            />
          </TableBody>
        </Table>
        {/* <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={dataControllers.dataControllers.length}
                    rowsPerPage={1}
                    page={0}
                    backIconButtonProps={{
                        "aria-label": "previous page"
                    }}
                    nextIconButtonProps={{
                        "aria-label": "next page"
                    }}
                    onChangePage={() => console.log('page changed')}
                /> */}
      </div>
      <CreateOrEditDataControllerDialog
        open={createDialogOpen}
        handleClose={handleClose}
        showError={dialogShowError}
        errorText={dialogErrorText}
        data={currentlyEditingData}
      />
      <Backdrop className={classes.backdrop} open={spinnerOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

interface DataControllerRowProps {
  fetchStatus: "idle" | "loading" | "success" | "error";
  dataControllers: DataControllerDetailedDTO[] | null | undefined;
  onClick: (dataController: DataControllerDetailedDTO) => void;
}

const DataControllerRows = ({
  fetchStatus,
  dataControllers,
  onClick,
}: DataControllerRowProps) => {
  if (!dataControllers && fetchStatus === "loading") {
    return (
      <TableRow>
        <TableCell colSpan={3}>Loading...</TableCell>
      </TableRow>
    );
  }

  if (
    fetchStatus === "error" ||
    dataControllers === undefined ||
    dataControllers === null
  ) {
    return (
      <TableRow>
        <TableCell colSpan={3}>
          <Typography color="error">Something went wrong...</Typography>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {dataControllers.map((dataController) => (
        <ClickableTableRow
          key={`data-controller-${dataController.id}`}
          onClick={() => onClick(dataController)}
        >
          <TableCell>{dataController.name}</TableCell>
          <TableCell>{dataController.displayName}</TableCell>
          <TableCell></TableCell>
        </ClickableTableRow>
      ))}
    </>
  );
};

export default DataControllers;
