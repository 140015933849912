import * as React from "react";
import { Grid } from "@material-ui/core";
import { GridProps } from "@material-ui/core/Grid";
import { SvgIconComponent } from "@material-ui/icons";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import styled from "styled-components/macro";

const IconContainer = styled(Grid)`
  display: flex;
  align-items: center;
`;

interface OwnProps {
  icon: SvgIconComponent;
  spacing?: GridProps["spacing"];
  justify?: GridProps["justify"];
}

type Props = OwnProps & SvgIconProps;

const IconText: React.FC<Props> = ({
  icon: IconComponent,
  children,
  spacing,
  justify,
  ...svgIconProps
}) => {
  return (
    <Grid
      container
      spacing={spacing || 1}
      alignItems="center"
      justify={justify || "center"}
    >
      <IconContainer item>
        <IconComponent {...svgIconProps} />
      </IconContainer>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export default IconText;
