import { createStyles, makeStyles } from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    timestamps: {
      position: "absolute",
      left: "24px",
      width: "calc(100% - 340px)",
    },
  })
);

interface Props {
  agreement: ServiceProviderAgreementDTO;
}

export const Timestamps = (props: Props) => {
  const classes = useStyles();

  return (
    <>
      {!!(props.agreement as ServiceProviderAgreementDTO).createdDate ? (
        <div className={classes.timestamps}>
          <i>
            Created{" "}
            {(
              props.agreement as ServiceProviderAgreementDTO
            ).createdDate.toLocaleString()}
            {!!props.agreement.lastUpdated
              ? `, last updated ${props.agreement.lastUpdated?.toLocaleString()}`
              : ""}
          </i>
        </div>
      ) : null}
    </>
  );
};
