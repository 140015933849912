import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { useApiKeys } from "../../../../../api/useApiKeysApi";
import ApiKeysTable from "./ApiKeysTable";
import CreateApiKeyDialog from "./CreateApiKeyDialog";

const useStyles = makeStyles((theme) =>
  createStyles({
    errorText: {
      color: theme.palette.error.main,
    },
  })
);

const ApiKeysOverview: React.FC = () => {
  const [isAddingApiKey, setIsAddingApiKey] = useState(false);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography>
            Here you can add api keys to communicate with the consent service.
          </Typography>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsAddingApiKey(true)}
            >
              Add api key
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ApiKeysOverviewStatus />
        </Grid>
      </Grid>
      {isAddingApiKey ? (
        <CreateApiKeyDialog
          isOpen={true}
          onClose={() => setIsAddingApiKey(false)}
        />
      ) : null}
    </>
  );
};

const ApiKeysOverviewStatus: React.FC<{}> = () => {
  const {
    data: apiKeys,
    isLoading: isLoadingApiKeys,
    isError,
    isSuccess,
    error,
  } = useApiKeys();
  const classes = useStyles();

  if (isLoadingApiKeys) {
    return <LinearProgress />;
  }

  if (isError) {
    return (
      <Typography className={classes.errorText}>
        An error has occurred:{" "}
        {error != null && error instanceof Error
          ? error.message
          : "Unknown error"}
      </Typography>
    );
  }

  if (isSuccess && apiKeys !== undefined && apiKeys.length === 0) {
    return (
      <Typography>
        You currently have no api keys. Click the button above to create one.
      </Typography>
    );
  }

  if (apiKeys === undefined) {
    return (
      <Typography className={classes.errorText}>
        Returned api keys are undefined.
      </Typography>
    );
  }

  return <ApiKeysTable apiKeys={apiKeys} />;
};

export default ApiKeysOverview;
