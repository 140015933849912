import * as React from "react";
import { PropsWithChildren } from "react";
import style from "./progressbar.module.css";

interface Props<T> {
  isActive: boolean;
  width?: string;
  value: T;
  text: string;
  onClick?: (value: T) => any;
}

export const ProgressBarItem = (props: PropsWithChildren<Props<any>>) => {
  return (
    <li
      className={`${style.progressbarItem} ${
        props.isActive ? style.active : ""
      } ${props.onClick ? style.clickable : ""}`}
      style={{ width: props.width }}
      onClick={() => props.onClick?.(props.value)}
    >
      <div className={style.progressbarIcon}>{props.children}</div>
      <div className={style.progressbarText}>{props.text}</div>
    </li>
  );
};
