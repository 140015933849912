import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Snackbar,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { useCreateApiKey } from "../../../../../api/useApiKeysApi";

const useStyles = makeStyles((theme) =>
  createStyles({
    errorText: {
      color: theme.palette.error.main,
    },
  })
);

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CreateApiKeyDialog = ({ isOpen, onClose }: Props) => {
  const classes = useStyles();
  const [name, setName] = useState<string>("");
  const [createApiKey, { error, isLoading, isSuccess, data }] =
    useCreateApiKey();

  function handleSubmit() {
    if (name !== "") {
      createApiKey({ name });
    }
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => onClose()}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle>Create api key</DialogTitle>
        {isSuccess ? (
          <DialogContent>
            <ApiKeyDisplay apiKey={data?.key} />
          </DialogContent>
        ) : (
          <DialogContent>
            {error != null ? (
              <Typography className={classes.errorText}>
                {error instanceof Error
                  ? error.message
                  : typeof error === "string"
                  ? error
                  : "Unknown error occurred"}
              </Typography>
            ) : null}
            <TextField
              autoFocus
              margin="dense"
              fullWidth
              label="Name"
              value={name}
              required
              onChange={(event) => setName(event.target.value)}
            />
          </DialogContent>
        )}
        <DialogActions>
          {isSuccess ? (
            <Button
              type="submit"
              color="primary"
              disabled={isLoading}
              onClick={onClose}
            >
              Close
            </Button>
          ) : (
            <Button
              type="submit"
              color="primary"
              disabled={isLoading}
              onClick={handleSubmit}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const ApiKeyDisplay = ({ apiKey }: { apiKey: string | null | undefined }) => {
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  if (apiKey == null) {
    return (
      <Typography variant="body2">
        Something went wrong. Please try again
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="body2">Your key is:</Typography>
      <Input autoFocus margin="dense" fullWidth value={apiKey} readOnly />
      <Typography variant="body2">
        Please copy this, since it will not be shown again.
      </Typography>
      <Button
        type="button"
        variant="contained"
        onClick={async () => {
          try {
            await navigator.clipboard.writeText(apiKey);
            setSnackbarMessage("Copied to clipboard");
          } catch (exception) {
            console.error(exception);
            setSnackbarMessage("Failed to copy to clipboard");
          }
        }}
      >
        Copy
      </Button>
      <Snackbar
        open={snackbarMessage != null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={5000}
        onClose={() => setSnackbarMessage(null)}
        message={snackbarMessage}
      />
    </>
  );
};

export default CreateApiKeyDialog;
