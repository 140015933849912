import { Button, createStyles, makeStyles } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import * as React from "react";
import { MutableRefObject, useEffect } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      float: "right",
      position: "relative",
      height: "56px",
      right: "-70px",
      top: "-1px",
      boxShadow: "none !important",
    },
    fileNameDisplay: {
      position: "absolute",
      paddingLeft: "15px",
      font: "inherit",
      color: "currentColor",
      lineHeight: "100%",
    },
    placeholder: {
      color: "rgba(0, 0, 0, 0.5)",
    },
    dropZone: {
      border: "1px dashed #000",
      borderRadius: "4px",
      borderColor: "rgba(0, 0, 0, 0.23)",
      height: "56px",
      marginTop: "15px",
      width: "calc(100% - 70px)",
    },
    dropZoneHover: {
      borderColor: "rgba(0,0,0,1)",
    },
    error: {
      borderColor: "red !important",
      color: "red !important",
    },
    label: {
      backgroundColor: "#FFFFFF",
      top: "-10px",
      left: "10px",
      paddingLeft: "5px",
      paddingRight: "5px",
      position: "relative",
      color: "rgba(0, 0, 0, 0.5)",
    },
  })
);

interface Props {
  file?: FileDTO;
  accept?: string[];
  required?: boolean;
  onChange: (file?: FileDTO) => any;
  label: string;
}

const FileUploadField = (props: Props) => {
  const classes = useStyles();
  const [fileName, setFileName] = React.useState<string>("");
  const [errorState, setErrorState] = React.useState<boolean>(false);

  const dropZoneRef =
    React.useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const fileInputRef =
    React.useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;

  const label = `${props.label}${props.required ? " *" : ""}`;

  useEffect(() => {
    let div = dropZoneRef.current;
    div.addEventListener("dragenter", handleDragIn);
    div.addEventListener("dragleave", handleDragOut);
    div.addEventListener("dragover", handleDragIn);
    div.addEventListener("drop", handleDrop);

    fileInputRef.current.oninvalid = () => setErrorState(true);
  }, []);

  async function handleUpload(files: FileList | null) {
    if (!files || !files.length) {
      setFileName("");
      props.onChange();
    } else {
      if (
        props.accept?.some((x) => files[0].name.endsWith(x)) ||
        !props.accept?.length
      ) {
        // if we accept file type or no types are configured we're happy
        setErrorState(false);
        const file = await wrap(files[0]);
        setFileName(file.name);
        props.onChange(file);
      } else {
        //Otherwise tell user they've fucked up
        setErrorState(true);
      }
    }
  }

  function handleDragIn(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer?.items?.length) {
      dropZoneRef.current.classList.add(classes.dropZoneHover);
    }
  }

  function handleDragOut(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer?.items?.length) {
      dropZoneRef.current.classList.remove(classes.dropZoneHover);
    }
  }

  function handleDrop(event: DragEvent) {
    handleDragOut(event);
    if (event.dataTransfer) {
      fileInputRef.current.files = event.dataTransfer.files;
      handleUpload(event.dataTransfer.files);
    }
  }

  function wrap(file: File): Promise<FileDTO> {
    return new Promise((resolve, reject) => {
      const { name, type } = file;
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        resolve({
          name,
          type,
          base64Content: btoa(event.target!.result as string),
        });
      });
      reader.addEventListener("error", (event) => {
        reject(event);
      });
      reader.addEventListener("abort", (event) => {
        reject(event);
      });
      reader.readAsBinaryString(file);
    });
  }

  return (
    <div
      ref={dropZoneRef}
      className={`${classes.dropZone} ${errorState ? classes.error : ""}`}
    >
      <label className={`${classes.label} ${errorState ? classes.error : ""}`}>
        {label}
      </label>

      <input
        accept="*"
        required={props.required}
        ref={fileInputRef}
        style={{ display: "none" }}
        id="file-input"
        type="file"
        onChange={(event) => handleUpload(event.target.files)}
      />
      <div
        className={`${classes.fileNameDisplay} ${
          fileName ? "" : classes.placeholder
        } ${errorState ? classes.error : ""}`}
      >
        {fileName || "Drag a file here to upload"}
      </div>

      <label htmlFor="file-input">
        <Button
          color="primary"
          variant="contained"
          component="span"
          className={classes.button}
        >
          <CloudUpload />
        </Button>
      </label>
    </div>
  );
};

export default FileUploadField;
