import React from "react";
import { Grid, Typography } from "@material-ui/core";
import FolderButton from "./FolderButton";

interface Props {
  groups: DashboardConsentTypeGroupDTO[] | undefined;
  consentTypes: ConsentTypeGroupDetailsConsentTypeDTO[] | undefined;
  groupsStatus: "loading" | "idle" | "error" | "success";
  consentTypesStatus: "loading" | "idle" | "error" | "success";
  isInGroup: boolean;
  onGroupSelected: (id: string) => void;
  onGoBack?: () => void;
  onTypeClicked: (id: string) => void;
  onMenu: (
    event: React.MouseEvent<HTMLDivElement>,
    isFolder: boolean,
    id: string
  ) => void;
}

const FolderView = ({
  groups,
  consentTypes,
  groupsStatus,
  isInGroup,
  consentTypesStatus,
  onGroupSelected,
  onGoBack,
  onTypeClicked,
  onMenu,
}: Props) => {
  const handleTypeClicked = (id: string) => () => onTypeClicked(id);
  const handleGroupClicked = (id: string) => () => onGroupSelected(id);
  const handleContextMenu =
    (isFolder: boolean, id: string) =>
    (event: React.MouseEvent<HTMLDivElement>) =>
      onMenu(event, isFolder, id);

  if (
    groupsStatus === "loading" ||
    (isInGroup && consentTypesStatus === "loading")
  ) {
    return (
      <Grid item sm={3}>
        <Typography>We are fetching data, please wait...</Typography>
      </Grid>
    );
  }

  if (groupsStatus === "error" || consentTypesStatus === "error") {
    return (
      <Grid item sm={12}>
        <Typography color="error">
          Something went wrong when fetching data...
        </Typography>
      </Grid>
    );
  }

  if (isInGroup) {
    return (
      <>
        {
          <Grid key={`back-button`} item sm={3}>
            <FolderButton onClick={onGoBack} isFolder>
              ...
            </FolderButton>
          </Grid>
        }
        {consentTypes &&
          consentTypes.map((consentType, index) => (
            <Grid
              key={`consent-type-button-${index}`}
              item
              sm={3}
              onContextMenu={handleContextMenu(false, consentType.id)}
            >
              <FolderButton onClick={handleTypeClicked(consentType.id)}>
                {consentType.name}
              </FolderButton>
            </Grid>
          ))}
      </>
    );
  }

  return (
    <>
      {groups &&
        groups.map((group, index) => (
          <Grid
            key={`group-button-${index}`}
            item
            sm={3}
            onContextMenu={handleContextMenu(true, group.id)}
          >
            <FolderButton onClick={handleGroupClicked(group.id)} isFolder>
              {group.name}
            </FolderButton>
          </Grid>
        ))}
      {consentTypes &&
        consentTypes.map((consentType, index) => (
          <Grid
            key={`consent-type-button-${index}`}
            item
            sm={3}
            onContextMenu={handleContextMenu(false, consentType.id)}
          >
            <FolderButton onClick={handleTypeClicked(consentType.id)}>
              {consentType.name}
            </FolderButton>
          </Grid>
        ))}
    </>
  );
};

export default FolderView;
