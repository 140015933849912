import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import { UpdateScopeDTO, ScopeDetailsDTO } from "../../../../../models/Scopes";

interface Props {
  scope: ScopeDetailsDTO;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (id: string, updateScopeDTO: UpdateScopeDTO) => void;
}

const EditScopeDialog = ({ scope, isOpen, onClose, onSubmit }: Props) => {
  const [name, setName] = React.useState<string | null>(scope.name);
  const [description, setDescription] = React.useState<string | null>(
    scope.description
  );

  function submit() {
    if (name == null || name === "") {
      // TODO: Show error
      return;
    }

    onSubmit(scope.id, { name, description: description || "" });
  }

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">New Scope</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          margin="dense"
          id="description"
          label="Description"
          fullWidth
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button type="submit" onClick={submit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditScopeDialog;
