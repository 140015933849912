import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import { CreateConsentTypeDTO } from "../../../models/ConsentTypes";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (createConsentTypeDTO: CreateConsentTypeDTO) => void;
}

const CreateConsentTypeDialog = ({ isOpen, onClose, onSubmit }: Props) => {
  const [name, setName] = React.useState<string | null>(null);
  function submit() {
    if (name == null || name === "") {
      // TODO: Show error
      return;
    }

    // TODO: Maybe add redirect uris
    onSubmit({ name, redirectUris: [] });
  }

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">New Consent Type</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          value={name || ""}
          onChange={(event) => setName(event.target.value)}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          type="submit"
          onClick={submit}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateConsentTypeDialog;
