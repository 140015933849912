import * as React from "react";
import { Button } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { ButtonProps } from "@material-ui/core/Button";
import IconText from "./IconText";
import ConfirmDialog from "./ConfirmDialog";

interface OwnProps {
  title: string;
  buttonText: String;
  onDelete: () => void;
}

type Props = OwnProps & ButtonProps;

const DeleteButton: React.FC<Props> = ({
  title,
  buttonText,
  onDelete,
  children,
  ...rest
}) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  function onClose() {
    setIsDialogOpen(false);
  }

  function onOpen() {
    setIsDialogOpen(true);
  }

  return (
    <>
      <Button onClick={onOpen} {...rest}>
        <IconText icon={DeleteIcon}>{buttonText}</IconText>
      </Button>
      <ConfirmDialog
        open={isDialogOpen}
        title={title}
        onDelete={onDelete}
        onClose={onClose}
      >
        {children}
      </ConfirmDialog>
    </>
  );
};

export default DeleteButton;
