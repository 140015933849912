import { KeycloakInstance } from "keycloak-js";
import * as React from "react";
import { useAxios } from "../api/useAxios";
import { DataControllerDTO } from "../models/Datacontrollers";
import { useDataController } from "./DataControllerContext";
import { keycloak } from "./index";

export interface AuthContextState {
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  permissions: Permission[];
  setPermissions: React.Dispatch<React.SetStateAction<Permission[]>>;
  keycloak: KeycloakInstance;
}

const AuthContext = React.createContext<AuthContextState | undefined>(
  undefined
);

const AuthProvider: React.FC<{}> = ({ ...rest }) => {
  const { updateDataControllers } = useDataController();

  const axios = useAxios();

  if (React.useContext(AuthContext) != null) {
    throw new Error("AuthProvider cannot be instantiated more than once");
  }

  const [isLoggedIn, setIsLoggedIn] =
    React.useState<AuthContextState["isLoggedIn"]>(false);

  const [isLoggingIn, setIsLoggingIn] =
    React.useState<AuthContextState["isLoggingIn"]>(false);

  const [permissions, setPermissions] = React.useState<
    AuthContextState["permissions"]
  >([]);

  React.useEffect(() => {
    setIsLoggingIn(true);
    keycloak.init({ onLoad: "login-required" }).finally(() => {
      setIsLoggingIn(false);
    });
  }, []);

  keycloak.onAuthSuccess = () => {
    console.log("logged in");
    axios
      .get<DataControllerDTO[]>("/me/datacontrollers")
      .then((response) => updateDataControllers(response.data))
      .then(() => axios.get<Permission[]>("/me/permissions"))
      .then((response) => {
        setPermissions(response.data);
        setIsLoggedIn(true);
        setIsLoggingIn(false);
      })
      .catch(() => {
        setIsLoggedIn(false);
        setIsLoggingIn(false);
      });
  };

  keycloak.onAuthLogout = () => {
    console.log("logged out");
    setIsLoggedIn(false);
    setIsLoggingIn(false);
  };

  const value: AuthContextState = React.useMemo(
    () => ({ isLoggedIn, isLoggingIn, permissions, setPermissions, keycloak }),
    [isLoggedIn, isLoggingIn, permissions]
  );

  return <AuthContext.Provider value={value} {...rest} />;
};

export const TestAuthProvider = ({
  permissions = [],
  setPermissions = () => {},
  isLoggedIn = true,
  isLoggingIn = false,
  keycloak: KeycloakInstance = keycloak,
  children,
}: Partial<AuthContextState> & { children: React.ReactNode }) => {
  const value: AuthContextState = React.useMemo(
    () => ({ isLoggedIn, isLoggingIn, permissions, setPermissions, keycloak }),
    [permissions]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const axios = useAxios();
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  const keycloak = context.keycloak;
  const login = () => {
    keycloak.login();
  };

  const logout = () => {
    keycloak.logout();
  };

  const isGlobalAdmin = keycloak.idTokenParsed
    ? (keycloak.idTokenParsed as any)["global-admin"] === true
    : false;

  const updatePermissions = React.useCallback(() => {
    axios.get<Permission[]>("/me/permissions").then((response) => {
      context.setPermissions(response.data);
    });
  }, [context.setPermissions]);

  return {
    isLoggedIn: context.isLoggedIn,
    isLoggingIn: context.isLoggingIn,
    permissions: context.permissions,
    updatePermissions,
    login,
    logout,
    isGlobalAdmin,
  };
};

export { AuthProvider, useAuth };
