import {
  Button,
  CircularProgress,
  Fade,
  Grid,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Save as SaveIcon } from "@material-ui/icons";
import * as React from "react";
import { useQuery } from "react-query";
import { RouteComponentProps } from "react-router";
import styled from "styled-components/macro";
import { useAxios } from "../../../../api/useAxios";
import ConsentPreview from "../../../../components/ConsentPreview";
import IconText from "../../../../components/IconText";
import SplitView from "../../../../components/SplitView";
import {
  ConsentDocumentDTO,
  UpdateConsentVersionDTO,
} from "../../../../models/Consents";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: "relative",
      padding: "1.5rem 1.5rem 0 3rem",
      backgroundColor: "white",
      height: "100%",
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    header: {
      paddingBottom: "1.5rem",
    },
    previewContainer: {
      height: "100%",
      padding: "1rem 2.5rem 1rem 1rem",
    },
  })
);

const CenteredLoadingDiv = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.7rem;
`;

interface OwnProps {
  consentVersion: ConsentVersionDTO;
}

interface RouteProps {
  consentTypeGroupId: string;
  consentTypeId: string;
  consentVersionId: string;
}

type Props = OwnProps & RouteComponentProps<RouteProps>;

const EditConsent = ({ match, history }: Props) => {
  const classes = useStyles();
  const [name, setName] = React.useState<string>("");
  const [html, setHtml] = React.useState<string>("");
  const [css, setCss] = React.useState<string>("");
  const [acceptText, setAcceptText] = React.useState<string>("");
  const [rejectText, setRejectText] = React.useState<string>("");
  const [pageTitle, setPageTitle] = React.useState<string>("");
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const axios = useAxios();

  const { data: consentVersion, status: consentVersionStatus } = useQuery(
    ["consentVersion", match.params.consentVersionId],
    fetchConsentVersion
  );
  const { data: consentDocument, status: consentDocumentStatus } = useQuery(
    ["consentDocument", match.params.consentVersionId],
    fetchConsentDocument
  );

  React.useEffect(() => {
    const data = consentVersion;
    if (data == null) {
      return;
    }
    setName(data.name);
  }, [consentVersion]);

  React.useEffect(() => {
    const data = consentDocument;
    if (data == null) {
      return;
    }

    setHtml(data.html);
    setCss(data.css);
    setAcceptText(data.acceptText);
    setRejectText(data.rejectText);
    setPageTitle(data.pageTitle);
  }, [consentDocument]);

  async function fetchConsentVersion(key: string, consentVersionId: string) {
    const result = await axios.get<ConsentVersionDTO>(
      `/consentVersions/${consentVersionId}`
    );
    return result.data;
  }

  async function fetchConsentDocument(key: string, consentVersionId: string) {
    const result = await axios.get<ConsentDocumentDTO>(
      `/consentVersions/${consentVersionId}/document`
    );
    return result.data;
  }

  function onSuccess() {
    goToDetails();
  }

  function onCancel() {
    goToDetails();
  }

  function goToDetails() {
    history.push(
      `/consentTypes/${match.params.consentTypeId}/consents/${match.params.consentVersionId}`
    );
  }

  function onSubmit(event: React.FormEvent) {
    event.preventDefault();

    if (
      name === "" ||
      html === "" ||
      css === "" ||
      acceptText === "" ||
      rejectText === "" ||
      pageTitle === ""
    ) {
      // TODO: Show error
      return;
    }

    setIsSubmitting(true);

    const updateConsentDTO: UpdateConsentVersionDTO = {
      name,
      document: {
        html,
        css,
        acceptText,
        rejectText,
        pageTitle,
      },
    };

    axios
      .put<ConsentVersionDTO>(
        `/consentVersions/${match.params.consentVersionId}`,
        updateConsentDTO
      )
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        // TODO: Show error
        setIsSubmitting(false);
      });
  }

  if (consentVersionStatus === "error") {
    return <h2>Could not find consent</h2>;
  }

  if (consentVersion && consentVersion.status !== "Draft") {
    return <h2>Consent is not a draft</h2>;
  }

  const isLoading =
    isSubmitting ||
    consentVersionStatus === "loading" ||
    consentDocumentStatus === "loading";

  return (
    <SplitView
      left={
        <div className={classes.container}>
          <Typography variant="h3" color="primary" className={classes.header}>
            Edit Consent
          </Typography>
          <form onSubmit={onSubmit}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <TextField
                  autoFocus
                  id="name"
                  label="Consent Name"
                  fullWidth
                  disabled={isLoading}
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  required
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={3}
                >
                  <Grid item xs={6}>
                    <TextField
                      id="html"
                      label="HTML"
                      fullWidth
                      disabled={isLoading}
                      value={html}
                      onChange={(event) => setHtml(event.target.value)}
                      required
                      multiline
                      variant="outlined"
                      rows={12}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="css"
                      label="CSS"
                      fullWidth
                      disabled={isLoading}
                      value={css}
                      onChange={(event) => setCss(event.target.value)}
                      required
                      multiline
                      variant="outlined"
                      rows={12}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={3}
                >
                  <Grid item xs={6}>
                    <TextField
                      id="acceptText"
                      label="Accept Text"
                      fullWidth
                      disabled={isLoading}
                      value={acceptText}
                      onChange={(event) => setAcceptText(event.target.value)}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="rejectText"
                      label="Reject Text"
                      fullWidth
                      disabled={isLoading}
                      value={rejectText}
                      onChange={(event) => setRejectText(event.target.value)}
                      required
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  id="pageTitle"
                  label="Page Title"
                  fullWidth
                  disabled={isLoading}
                  value={pageTitle}
                  onChange={(event) => setPageTitle(event.target.value)}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="flex-end"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      disabled={isLoading}
                      onClick={onCancel}
                      color="secondary"
                      size="large"
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={isLoading}
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      <IconText icon={SaveIcon} fontSize="small">
                        Save
                      </IconText>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Fade
              in={isLoading}
              style={{
                transitionDelay: isLoading ? "800ms" : "0ms",
              }}
              unmountOnExit
            >
              <CenteredLoadingDiv>
                <CircularProgress size={50} />
              </CenteredLoadingDiv>
            </Fade>
          </form>
        </div>
      }
      right={
        <Grid container className={classes.previewContainer}>
          <ConsentPreview
            html={html}
            htmlCss={css}
            rejectText={rejectText}
            acceptText={acceptText}
            pageTitle={pageTitle}
          />
        </Grid>
      }
      leftSize={7}
      rightSize={5}
    />
  );
};

export default EditConsent;
