import React from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import CopyToClipboard from "./CopyToClipboard";

interface Props {
  codeText: string;
  copyable?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    codeContainer: {
      border: "1px solid black",
      borderRadius: "4px",
      padding: "1em",
      boxShadow: "#90a4ae 1px 1px 4px",
      overflow: "auto",
      maxHeight: "730px",
      cursor: "text",
      position: "relative",
    },
  })
);

const Code: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.codeContainer}>
      <pre>
        <code>{props.codeText}</code>
      </pre>
      {props.copyable && <CopyToClipboard text={props.codeText} />}
    </div>
  );
};

export default Code;
