import * as React from "react";
import { Grid, makeStyles, createStyles } from "@material-ui/core";
import { GridProps } from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) =>
  createStyles({
    outerGrid: {
      flex: 1,
    },
    leftItem: {
      display: "flex",
      alignItems: "stretch",
      flexDirection: "column",
    },
    rightItem: {
      paddingLeft: "1.5rem",
    },
  })
);

export interface SplitViewProps {
  left: React.ReactNode;
  right: React.ReactNode;
  leftSize?: GridProps["xs"];
  rightSize?: GridProps["xs"];
  className?: string;
}

const SplitView = ({
  left,
  right,
  leftSize,
  rightSize,
  className,
}: SplitViewProps) => {
  const classes = useStyles();
  return (
    <Grid item xs container className={className}>
      <Grid item xs container className={classes.outerGrid}>
        <Grid
          item
          xs={leftSize != null ? leftSize : 3}
          className={classes.leftItem}
        >
          {left}
        </Grid>
        <Grid
          item
          xs={rightSize != null ? rightSize : 9}
          className={classes.rightItem}
        >
          {right}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SplitView;
