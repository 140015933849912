import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { Delete as DeleteIcon, Save as SaveIcon } from "@material-ui/icons";
import * as React from "react";
import { QueryResult, usePaginatedQuery } from "react-query";
import styled from "styled-components/macro";
import { useAxios } from "../../../../../api/useAxios";
import IconText from "../../../../../components/IconText";
import Rows from "../../../../../components/Rows";
import { PagedDTO } from "../../../../../models/Paged";
import { UserValidationSettingDTO } from "../../../../../models/UserValidationSettings";

const Loading = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
`;

interface Props {
  consentVersionId: string;
  currentSettingApi: QueryResult<UserValidationSettingDTO>;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}

const SelectUserValidationSetting = ({
  consentVersionId,
  currentSettingApi,
  isOpen,
  onCancel,
  onSuccess,
}: Props) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const currentPageSize = 20;

  const userValidationSettingsStatus = usePaginatedQuery(
    ["userValidationSettings", currentPage, currentPageSize],
    fetchUserValidationSettings
  );

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [selectedSettingId, setSelectedSettingId] = React.useState<
    string | null
  >(null);
  const axios = useAxios();

  React.useEffect(() => {
    if (
      currentSettingApi.data &&
      (currentSettingApi.data as any) !== "" &&
      currentSettingApi.status === "success"
    ) {
      setSelectedSettingId(currentSettingApi.data.id);
    }
  }, [currentSettingApi]);

  const isLoading =
    isSubmitting || userValidationSettingsStatus.status === "loading";

  async function fetchUserValidationSettings(
    key: string,
    page = 0,
    pageSize = 20
  ) {
    const result = await axios.get<PagedDTO<UserValidationSettingDTO>>(
      `/userValidationSettings?size=${currentPageSize}&page=${currentPage}`
    );
    return result.data;
  }

  function onDeleteClicked(event: React.FormEvent) {
    event.preventDefault();
    setSelectedSettingId(null);

    setIsSubmitting(true);
    axios
      .delete(`/consentVersions/${consentVersionId}/userValidationSetting`)
      .then(() => {
        setIsSubmitting(false);
        onSuccess();
      })
      .catch(() => {
        // TODO: Show error
        setIsSubmitting(false);
      });
  }

  function onSubmit(event: React.FormEvent) {
    event.preventDefault();

    setIsSubmitting(true);
    axios
      .put(
        `/consentVersions/${consentVersionId}/userValidationSetting/${selectedSettingId}`
      )
      .then(() => {
        setIsSubmitting(false);
        onSuccess();
      })
      .catch(() => {
        // TODO: Show error
        setIsSubmitting(false);
      });
  }

  function isSelected(id: string) {
    return selectedSettingId === id;
  }

  function clickedSettingId(id: string) {
    if (isSelected(id)) {
      setSelectedSettingId(null);
    } else {
      setSelectedSettingId(id);
    }
  }

  function handleChangePage(event: unknown, newPage: number) {
    setCurrentPage(newPage);
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">
        Select user validation setting
      </DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit} id="edit-user-validation-setting-form">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Client ID</TableCell>
                <TableCell>Client Secret</TableCell>
                <TableCell>Use Admin Realm</TableCell>
                <TableCell>Validation URL</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Rows
                colSpan={6}
                dataApiState={userValidationSettingsStatus}
                data={
                  userValidationSettingsStatus.resolvedData &&
                  userValidationSettingsStatus.resolvedData.data
                }
                fetchingText="We are fetching the user validation settings, please wait..."
                noDataText=""
                errorText=""
                rowRender={(item) => (
                  <TableRow key={item.id}>
                    <TableCell component="th" scope="row">
                      {item.name}
                    </TableCell>
                    <TableCell>{item.clientId}</TableCell>
                    <TableCell>{item.clientSecret}</TableCell>
                    <TableCell>{item.useAdminRealm ? "YES" : "NO"}</TableCell>
                    <TableCell>{item.validationUrl}</TableCell>
                    <TableCell>
                      <Radio
                        checked={isSelected(item.id)}
                        onChange={() => clickedSettingId(item.id)}
                      />
                    </TableCell>
                  </TableRow>
                )}
              />
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={
              (userValidationSettingsStatus.resolvedData &&
                userValidationSettingsStatus.resolvedData.totalItems) ||
              0
            }
            rowsPerPage={currentPageSize}
            page={currentPage}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onPageChange={handleChangePage}
          />
        </form>
        <Fade
          in={isLoading}
          style={{
            transitionDelay: isLoading ? "800ms" : "0ms",
          }}
          unmountOnExit
        >
          <Loading size={50} />
        </Fade>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button
          type="submit"
          form="edit-user-validation-setting-form"
          disabled={isLoading}
          onClick={onDeleteClicked}
          color="secondary"
          variant="contained"
        >
          <IconText icon={DeleteIcon} fontSize="small">
            Remove active setting
          </IconText>
        </Button>
        <Button
          type="submit"
          form="edit-user-validation-setting-form"
          disabled={isLoading}
          onClick={onSubmit}
          color="primary"
          variant="contained"
        >
          <IconText icon={SaveIcon} fontSize="small">
            Save
          </IconText>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectUserValidationSetting;
