import {
  Button,
  CircularProgress,
  Fade,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import CodeIcon from "@material-ui/icons/Code";
import * as React from "react";
import { QueryResult, useQuery } from "react-query";
import { RouteComponentProps } from "react-router";
import { useAxios } from "../../../../api/useAxios";
import BreadcrumbsNavigation from "../../../../components/BreadcrumbsNavigation";
import ConsentPreview from "../../../../components/ConsentPreview";
import DetailsView from "../../../../components/DetailsView";
import IconText from "../../../../components/IconText";
import Rows from "../../../../components/Rows";
import { ConsentDocumentDTO } from "../../../../models/Consents";
import { ScopeDTO } from "../../../../models/Scopes";
import { UserValidationSettingDTO } from "../../../../models/UserValidationSettings";
import EditScopes from "./EditScopes";
import SelectUserValidationSetting from "./components/SelectUserValidationSetting";
import ViewSourceDialog from "./components/ViewSourceDialog";

const useStyles = makeStyles((theme) =>
  createStyles({
    titleContainer: {
      paddingBottom: "3rem",
    },
    scopeTableContainer: {
      paddingTop: theme.spacing(2),
    },
    centeredLoadingDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "1.7rem",
    },
    previewContainer: {
      paddingTop: theme.spacing(1),
      paddingLeft: "2rem",
      maxWidth: "400px",
      maxHeight: "500px",
    },
    publishContainer: {
      backgroundColor: theme.palette.grey[800],
      padding: "1.5rem 5rem 1.5rem 1.5rem",
      color: "white",
    },
  })
);

interface RouteProps {
  consentTypeGroupId: string;
  consentTypeId: string;
  consentVersionId: string;
}

type Props = RouteComponentProps<RouteProps>;

interface UserValidationSettingRowProps {
  userValidationSetting: QueryResult<UserValidationSettingDTO>;
}

const UserValidationSettingRow = ({
  userValidationSetting,
}: UserValidationSettingRowProps) => {
  if (userValidationSetting.status === "loading") {
    return (
      <TableRow>
        <TableCell colSpan={5}>Loading setting...</TableCell>
      </TableRow>
    );
  }

  if (
    userValidationSetting.status === "success" &&
    userValidationSetting.data
  ) {
    return (
      <TableRow key={userValidationSetting.data.id}>
        <TableCell component="th" scope="row">
          {userValidationSetting.data.name}
        </TableCell>
        <TableCell>{userValidationSetting.data.clientId}</TableCell>
        <TableCell>{userValidationSetting.data.clientSecret}</TableCell>
        <TableCell>
          {userValidationSetting.data.useAdminRealm ? "YES" : "NO"}
        </TableCell>
        <TableCell>{userValidationSetting.data.validationUrl}</TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell colSpan={5}>
        No user validation setting assigned. Will use data controller setting as
        fallback.
      </TableCell>
    </TableRow>
  );
};

const ConsentDetails = ({ match, history }: Props) => {
  const classes = useStyles();

  const {
    data: consentDetails,
    refetch: refreshConsentDetails,
    status: consentDetailsStatus,
  } = useQuery(
    ["consentDetailsConsentVersion", match.params.consentVersionId],
    fetchConsentDetails
  );

  const { data: consentDocument, status: consentDocumentStatus } = useQuery(
    ["consentDetailsConsentDocument", match.params.consentVersionId],
    fetchConsentDocument
  );

  const scopesStatus = useQuery(
    ["consentDetailsScopes", match.params.consentVersionId],
    fetchScopes
  );

  const userValidationSettingStatus = useQuery(
    ["consentDetailsUserValidationSetting", match.params.consentVersionId],
    fetchUserValidationSetting,
    {
      retry: 0,
    }
  );

  const [isEditingScopes, setIsEditingScopes] = React.useState<boolean>(false);
  const [
    isSelectingUserValidationSetting,
    setIsSelectingUserValidationSetting,
  ] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [sourceDialogOpen, setSourceDialogOpen] = React.useState(false);
  const axios = useAxios();

  const isDraft =
    (consentDetails && consentDetails.status === "Draft") || false;

  async function fetchConsentDetails(key: string, consentVersionId: string) {
    const result = await axios.get<ConsentVersionDTO>(
      `/consentVersions/${consentVersionId}`
    );
    return result.data;
  }

  async function fetchConsentDocument(key: string, consentVersionId: string) {
    const result = await axios.get<ConsentDocumentDTO>(
      `/consentVersions/${consentVersionId}/document`
    );
    return result.data;
  }

  async function fetchScopes(key: string, consentVersionId: string) {
    const result = await axios.get<ScopeDTO[]>(
      `/consentVersions/${consentVersionId}/scopes`
    );
    return result.data;
  }

  async function fetchUserValidationSetting(
    key: string,
    consentVersionId: string
  ) {
    const result = await axios.get<UserValidationSettingDTO>(
      `/consentVersions/${consentVersionId}/userValidationSetting`
    );
    return result.data;
  }

  function editConsent() {
    history.push(`${match.url}/edit`);
  }

  function onStartEditingScopes() {
    setIsEditingScopes(true);
  }

  function onCancelEditScopes() {
    setIsEditingScopes(false);
  }

  function onSuccessEditScopes() {
    setIsEditingScopes(false);
    scopesStatus.refetch();
  }

  function onStartSelectingUserValidationSetting() {
    setIsSelectingUserValidationSetting(true);
  }

  function onCancelSelectingUserValidationSetting() {
    setIsSelectingUserValidationSetting(false);
  }

  function onSuccessSelectingUserValidationSetting() {
    setIsSelectingUserValidationSetting(false);
    userValidationSettingStatus.refetch();
  }

  function publishConsent() {
    if (consentDetails == null) {
      return;
    }
    setIsSubmitting(true);

    axios
      .post(`/consentVersions/${consentDetails.versionId}/publish`)
      .then(() => {
        refreshConsentDetails();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  let details: React.ReactNode;
  if (
    isSubmitting ||
    consentDetailsStatus === "loading" ||
    consentDocumentStatus === "loading"
  ) {
    details = (
      <Fade
        in={consentDetailsStatus === "loading"}
        style={{
          transitionDelay: consentDetailsStatus === "loading" ? "800ms" : "0ms",
        }}
        unmountOnExit
      >
        <div className={classes.centeredLoadingDiv}>
          <CircularProgress size={50} />
        </div>
      </Fade>
    );
  } else {
    if (consentDetails) {
      details = (
        <Grid item container xs direction="column">
          <Grid item className={classes.titleContainer}>
            <Typography variant="h3" color="primary">
              {(consentDetails && consentDetails.name) || ""}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              This is a preview of how your consent will look.
            </Typography>
          </Grid>
          <Grid item container xs className={classes.previewContainer}>
            {consentDocument ? (
              <ConsentPreview
                html={consentDocument.html}
                htmlCss={consentDocument.css}
                rejectText={consentDocument.rejectText}
                acceptText={consentDocument.acceptText}
                pageTitle={consentDocument.pageTitle}
                elevation={8}
              />
            ) : null}
          </Grid>
        </Grid>
      );
    } else {
      // TODO: Show error
    }
  }

  return (
    <>
      {isDraft ? (
        <div className={classes.publishContainer}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Typography variant="h4">Activate Consent</Typography>
              <Typography variant="subtitle1">
                By activating the consent you expire the currently active
                consent.
              </Typography>
            </Grid>
            <Grid item container xs direction="row" justify="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={publishConsent}
                >
                  Publish
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : null}
      <DetailsView
        details={
          <Grid container item xs direction="column" spacing={3}>
            <Grid item>
              <BreadcrumbsNavigation
                crumbs={[
                  { name: "Groups", path: "/" },
                  {
                    name: "Consent type",
                    path: `/consentTypes/${match.params.consentTypeId}`,
                  },
                ]}
                current="Consent details"
              />
            </Grid>
            <Grid item container xs direction="column">
              {details}
            </Grid>
          </Grid>
        }
        detailsFooter={
          isDraft ? (
            <Button variant="contained" color="primary" onClick={editConsent}>
              <IconText icon={EditIcon} fontSize="small">
                Edit document
              </IconText>
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setSourceDialogOpen(true)}
            >
              <IconText icon={CodeIcon} fontSize="small">
                View source
              </IconText>
            </Button>
          )
        }
        listHeader={<Typography variant="h4">Scopes</Typography>}
        listActions={
          isDraft ? (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={onStartEditingScopes}
              >
                <IconText icon={EditIcon} fontSize="small">
                  Edit scopes
                </IconText>
              </Button>
              {isEditingScopes ? (
                <EditScopes
                  consentVersionId={match.params.consentVersionId}
                  currentScopeIds={
                    (scopesStatus.data &&
                      scopesStatus.data.map((scope) => scope.id)) ||
                    []
                  }
                  isOpen={true}
                  onCancel={onCancelEditScopes}
                  onSuccess={onSuccessEditScopes}
                />
              ) : null}
            </>
          ) : null
        }
        list={
          <Grid
            container
            direction="column"
            spacing={3}
            className={classes.scopeTableContainer}
          >
            <Grid item>
              <Typography>
                The scopes currently provided by this consent. Once a consent is
                active it is no longer possible to add scopes.
                <br />
                These scopes should be presented in the consent document the
                user is shown.
              </Typography>
            </Grid>
            <Grid item>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Scope Name</TableCell>
                    <TableCell>Note</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <Rows
                    colSpan={2}
                    dataApiState={scopesStatus}
                    data={scopesStatus.data}
                    fetchingText="We are fetching the consent's scopes, please wait..."
                    noDataText={
                      <Typography>
                        There are currently no scopes associated with this
                        consent. Please add some by using "Edit scopes" above.
                      </Typography>
                    }
                    errorText=""
                    rowRender={(item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.description}</TableCell>
                      </TableRow>
                    )}
                  />
                </TableBody>
              </Table>
            </Grid>
            <Grid item>
              <Typography variant="h4">User Validation Setting</Typography>
            </Grid>
            <Grid item>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Client ID</TableCell>
                    <TableCell>Client Secret</TableCell>
                    <TableCell>Use Admin Realm</TableCell>
                    <TableCell>Validation URL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <UserValidationSettingRow
                    userValidationSetting={userValidationSettingStatus}
                  />
                </TableBody>
              </Table>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={onStartSelectingUserValidationSetting}
              >
                <IconText icon={EditIcon} fontSize="small">
                  Choose user validation setting
                </IconText>
              </Button>
              <SelectUserValidationSetting
                consentVersionId={match.params.consentVersionId}
                currentSettingApi={userValidationSettingStatus}
                isOpen={isSelectingUserValidationSetting}
                onCancel={onCancelSelectingUserValidationSetting}
                onSuccess={onSuccessSelectingUserValidationSetting}
              />
            </Grid>
          </Grid>
        }
      />
      {consentDocument ? (
        <ViewSourceDialog
          consentDocument={consentDocument}
          isOpen={sourceDialogOpen}
          handleClose={() => setSourceDialogOpen(false)}
        />
      ) : null}
    </>
  );
};

export default ConsentDetails;
