import * as React from "react";
import { TableRow, TableCell, Typography } from "@material-ui/core";
import { PaginatedQueryResult, QueryResult } from "react-query";

interface Props<T> {
  colSpan: number;
  fetchingText: React.ReactNode;
  errorText: React.ReactNode;
  noDataText: React.ReactNode;
  dataApiState: PaginatedQueryResult<unknown> | QueryResult<unknown>;
  data: T[] | undefined;
  forcedLoading?: boolean;
  rowRender: (item: T) => React.ReactNode;
}

function Rows<T>({
  dataApiState,
  colSpan,
  fetchingText,
  errorText,
  noDataText,
  forcedLoading,
  data,
  rowRender,
}: Props<T>) {
  if (
    dataApiState.status === "loading" ||
    dataApiState.status === "error" ||
    !data ||
    data.length === 0
  ) {
    let text: React.ReactNode;
    if (forcedLoading || dataApiState.status === "loading") {
      text = fetchingText;
    } else if (dataApiState.status === "error") {
      text = (
        <Typography color="error" variant="h6" component="span">
          {errorText}
        </Typography>
      );
    } else {
      text = noDataText;
    }
    return (
      <TableRow>
        <TableCell colSpan={colSpan}>{text}</TableCell>
      </TableRow>
    );
  }
  return (
    <>
      {data.map((item) => {
        return rowRender(item);
      })}
    </>
  );
}

export default Rows;
