import React from "react";
import { Menu, MenuItem, ListItemIcon, Typography } from "@material-ui/core";
import {
  CreateNewFolder as CreateNewFolderIcon,
  AddBox as AddBoxIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
export type MouseState = {
  mouseX: number | null;
  mouseY: number | null;
};
export type ContextMenuPlacementState = "clear" | "group" | "consent-type";
export type MenuAction =
  | "create-group"
  | "create-consent-type"
  | "edit-consent-type"
  | "edit-group"
  | "delete-consent-type"
  | "delete-group";

interface MenuItemsProps {
  contextMenuPlacementState: ContextMenuPlacementState;
  canDelete: boolean;
  onMenuAction: (menuAction: MenuAction) => void;
  onMenuClose: () => void;
  activeGroupId: string | null;
}

const MenuItems = ({
  contextMenuPlacementState,
  onMenuAction,
  onMenuClose,
  activeGroupId,
  canDelete,
}: MenuItemsProps) => {
  if (contextMenuPlacementState === "clear") {
    if (activeGroupId === null) {
      return (
        <>
          <MenuItem onClick={() => onMenuAction("create-group")}>
            <ListItemIcon>
              <CreateNewFolderIcon />
            </ListItemIcon>
            <Typography variant="inherit">New group</Typography>
          </MenuItem>
          <MenuItem onClick={() => onMenuAction("create-consent-type")}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <Typography variant="inherit">New consent type</Typography>
          </MenuItem>
          <MenuItem onClick={onMenuClose}>
            <ListItemIcon>
              <CancelIcon />
            </ListItemIcon>
            <Typography variant="inherit">Cancel</Typography>
          </MenuItem>
        </>
      );
    } else {
      return (
        <>
          <MenuItem onClick={() => onMenuAction("create-consent-type")}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <Typography variant="inherit">New consent type</Typography>
          </MenuItem>
          <MenuItem onClick={onMenuClose}>
            <ListItemIcon>
              <CancelIcon />
            </ListItemIcon>
            <Typography variant="inherit">Cancel</Typography>
          </MenuItem>
        </>
      );
    }
  } else {
    const editAction: MenuAction =
      contextMenuPlacementState === "group"
        ? "edit-group"
        : "edit-consent-type";
    const deleteAction: MenuAction =
      contextMenuPlacementState === "group"
        ? "delete-group"
        : "delete-consent-type";

    return (
      <>
        <MenuItem onClick={() => onMenuAction(editAction)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <Typography variant="inherit">
            {contextMenuPlacementState === "group"
              ? "Edit group"
              : "Edit consent type"}
          </Typography>
        </MenuItem>
        {canDelete && (
          <MenuItem onClick={() => onMenuAction(deleteAction)}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <Typography variant="inherit">
              {contextMenuPlacementState === "group"
                ? "Delete group"
                : "Delete consent type"}
            </Typography>
          </MenuItem>
        )}
        <MenuItem onClick={onMenuClose}>
          <ListItemIcon>
            <CancelIcon />
          </ListItemIcon>
          <Typography variant="inherit">Cancel</Typography>
        </MenuItem>
      </>
    );
  }
};

interface Props extends MenuItemsProps {
  mouseState: MouseState;
}

const ContextMenu = ({
  mouseState,
  onMenuClose,
  canDelete,
  contextMenuPlacementState,
  activeGroupId,
  onMenuAction,
}: Props) => {
  function handleContextMenu(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    onMenuClose();
  }

  return (
    <Menu
      keepMounted
      open={mouseState.mouseY !== null}
      onClose={onMenuClose}
      onContextMenu={handleContextMenu}
      anchorReference="anchorPosition"
      anchorPosition={
        mouseState.mouseY !== null && mouseState.mouseX !== null
          ? { top: mouseState.mouseY, left: mouseState.mouseX }
          : undefined
      }
    >
      <MenuItems
        contextMenuPlacementState={contextMenuPlacementState}
        canDelete={canDelete}
        activeGroupId={activeGroupId}
        onMenuAction={onMenuAction}
        onMenuClose={onMenuClose}
      />
    </Menu>
  );
};

export default ContextMenu;
