import * as React from "react";
import { useAuth } from "../auth/AuthContext";

export type RequirePermissionProps =
  | { permission: Permission; anyOf?: never; allOf?: never }
  | { permission?: never; anyOf: Permission[]; allOf?: never }
  | { permission?: never; anyOf?: never; allOf: Permission[] };

/**
 * Use this to toggle parts of the UI based on required permissions.
 * If the user needs just one out of a list of possible permissions, you can specify it in the {@code anyOf}-property.
 * Similarly, if the user needs ALL os some list of permissions, you can use the  {@code allOf}-property.
 * @example
 * <RequirePermission permission="USER_READ">
 *     <p>You're allowed to read users!</p>
 * </RequirePermission>
 *
 * @example
 * <RequirePermission anyOf={["USER_READ", "USER_WRITE", "USER_DELETE"]}>
 *     <p>We get it, you have something to do with users!</p>
 * </RequirePermission>
 *
 *  * @example
 * <RequirePermission allOf={["USER_READ", "USER_WRITE", "USER_DELETE"]}>
 *     <p>Wow, you sure have some user-relater permissions</p>
 * </RequirePermission>
 */
export const RequirePermission = (
  props: React.PropsWithChildren<RequirePermissionProps>
) => {
  const { permissions } = useAuth();
  const showContent = hasRequiredPermissions();

  function hasRequiredPermissions(): boolean {
    if (props.permission) {
      return permissions.includes(props.permission);
    } else if (props.anyOf) {
      return props.anyOf.some((permission) => permissions.includes(permission));
    } else if (props.allOf) {
      return props.allOf.every((permission) =>
        permissions.includes(permission)
      );
    }
    throw new Error(
      "Please specify exactly one of 'permission', 'anyOf' or 'allOf'"
    );
  }

  return <>{showContent ? props.children : null}</>;
};
