import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import { CreateOrUpdateDataControllerDTO } from "../../../models/Datacontrollers";

interface Props {
  open: boolean;
  handleClose: (dto?: CreateOrUpdateDataControllerDTO) => void;
  showError?: boolean;
  errorText?: string;
  data?: CreateOrUpdateDataControllerDTO;
}

const CreateOrEditDataControllerDialog = ({
  open,
  handleClose,
  showError,
  errorText,
  data,
}: Props) => {
  const [displayName, setDisplayName] = React.useState<string>(
    data?.displayName ?? ""
  );
  const [name, setName] = React.useState(data?.name ?? "");
  const [realmName, setRealmName] = React.useState(data?.realmName ?? "");

  React.useEffect(() => {
    setDisplayName(data?.displayName ?? "");
    setName(data?.name ?? "");
    setRealmName(data?.realmName ?? "");
  }, [data]);

  function handleSave() {
    const dto: CreateOrUpdateDataControllerDTO = {
      displayName: displayName,
      name: name,
      realmName: realmName,
    };

    handleClose(dto);
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        {data ? "Update" : "Create new"} datacontroller
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          value={name}
          onChange={(event) => setName(event.target.value)}
          required
          fullWidth
        />
        <TextField
          margin="dense"
          id="display-name"
          label="Datacontroller Display Name"
          value={displayName}
          onChange={(event) => setDisplayName(event.target.value)}
          required
          fullWidth
        />
        <TextField
          margin="dense"
          id="realm-name"
          label="Realm Name"
          value={realmName}
          onChange={(event) => setRealmName(event.target.value)}
          required
          fullWidth
        />
        {showError && <Typography color="error">{errorText}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateOrEditDataControllerDialog;
