import * as React from "react";
import SplitView, { SplitViewProps } from "./SplitView";
import { Grid, makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    leftOuterBox: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      padding: "1.5rem 1.5rem 1.5rem 3rem",
      backgroundColor: "white",
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    leftDetailsBox: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    leftFooterBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    rightOuterBox: {
      padding: "1.5rem 1.5rem 1.5rem 0",
    },
  })
);

interface Props {
  details: React.ReactNode;
  detailsFooter: React.ReactNode;
  listHeader: React.ReactNode;
  listActions: React.ReactNode;
  list: React.ReactNode;
  leftSize?: SplitViewProps["leftSize"];
  rightSize?: SplitViewProps["rightSize"];
}

const DetailsView = (props: Props) => {
  const classes = useStyles();
  const left = (
    <div className={classes.leftOuterBox}>
      <div className={classes.leftDetailsBox}>{props.details}</div>
      <div className={classes.leftFooterBox}>{props.detailsFooter}</div>
    </div>
  );
  const right = (
    <div className={classes.rightOuterBox}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>{props.listHeader}</Grid>
        <Grid item>{props.listActions}</Grid>
      </Grid>
      {props.list}
    </div>
  );
  return (
    <SplitView
      left={left}
      right={right}
      leftSize={props.leftSize}
      rightSize={props.rightSize}
    />
  );
};

export default DetailsView;
