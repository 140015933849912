import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { format } from "date-fns";
import * as React from "react";
import { useQuery } from "react-query";
import { useAxios } from "../../../../../api/useAxios";
import Rows from "../../../../../components/Rows";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      paddingBottom: "1rem",
    },
  })
);

interface Props {
  userId: string;
}

const UserDetails = (props: Props) => {
  const classes = useStyles();

  const consentsStatus = useQuery(["consents", props.userId], fetchConsents);
  const { data: consents, refetch: refreshConsents } = consentsStatus;

  const { data: dataOwner } = useQuery(
    ["dataOwner", props.userId],
    fetchDataOwner
  );

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const axios = useAxios();

  async function fetchConsents(key: string, userId: string) {
    const consentsResult = await axios.post<DataOwnerConsentDTO[]>(
      `/dataOwners/consents?userId=${userId}`
    );
    return consentsResult.data;
  }

  async function fetchDataOwner(key: string, userId: string) {
    const consentsResult = await axios.post<DataOwnerDTO>(
      `/dataOwners?userId=${userId}`
    );
    return consentsResult.data;
  }

  function revokeConsent(consentId: string) {
    setIsSubmitting(true);
    axios
      .post(`/consents/${consentId}/revoke`)
      .then(() => {
        refreshConsents();
      })
      .catch(() => {
        // TODO: Show error
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  return (
    <>
      <Typography variant="h5" className={classes.title}>
        Consents{dataOwner != null ? ` for ${dataOwner.name}` : ""}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Consent Type Group</TableCell>
            <TableCell>Consent Type</TableCell>
            <TableCell>Consent Version</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Revoked</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Rows
            colSpan={6}
            dataApiState={consentsStatus}
            data={consents}
            fetchingText="We are fetching the consents, please wait..."
            noDataText=""
            errorText="Unable to find consents. Is User ID correct?"
            forcedLoading={isSubmitting}
            rowRender={(item) => (
              <TableRow key={item.consent.id}>
                <TableCell>{item.vendorName}</TableCell>
                <TableCell>{item.appName}</TableCell>
                <TableCell>{item.consentVersionName}</TableCell>
                <TableCell>
                  {format(item.consent.createdAt, "yyyy-MM-dd HH:mm:ss")}
                </TableCell>
                <TableCell>
                  {item.consent.revokedAt &&
                    format(item.consent.revokedAt, "yyyy-MM-dd HH:mm:ss")}
                </TableCell>
                <TableCell align="right">
                  {item.consent.revokedAt == null ? (
                    <Button
                      variant="contained"
                      onClick={() => revokeConsent(item.consent.id)}
                    >
                      Revoke
                    </Button>
                  ) : null}
                </TableCell>
              </TableRow>
            )}
          />
        </TableBody>
      </Table>
    </>
  );
};

export default UserDetails;
