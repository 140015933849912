const TEMPLATE = `
<html lang="da">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0">
    <title>{{pageTitle}}</title>
    <style>
    {{css}}
    </style>
</head>

<body>
    <div class="consent-container">
        <div id="consent" class="consent-document">
            {{html}}
        </div>

        <div class="footer-container">
            <form name="consentForm" id="consentForm" class="consentForm" method="post">
                <button id="acceptButton" class="accept-button" name="accept" value="accept" type="submit">{{acceptText}}</button>
                <button id="rejectButton" class="reject-button" name="reject" value="reject" type="submit">{{rejectText}}</button>
            </form>
        </div>
    </div>
</body>
</html>
`;

interface GenerateHTMLInput {
  html: string;
  css: string;
  rejectText: string;
  acceptText: string;
  pageTitle: string;
}

export const generateHtml = (input: GenerateHTMLInput) => {
  return TEMPLATE.replace("{{html}}", input.html)
    .replace("{{css}}", input.css)
    .replace("{{rejectText}}", input.rejectText)
    .replace("{{acceptText}}", input.acceptText)
    .replace("{{pageTitle}}", input.pageTitle);
};
