import React from "react";
import { Button, makeStyles, createStyles } from "@material-ui/core";
import {
  Assignment as AssignmentIcon,
  Folder as FolderIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      width: "100%",
      padding: "1em 1em",
    },
  })
);

interface Props {
  onClick?: () => void;
  onContextMenu?: (
    event: React.MouseEvent<HTMLButtonElement>,
    isFolder: boolean
  ) => void;
  isFolder?: boolean;
}

const FolderButton = ({
  onClick,
  isFolder,
  onContextMenu,
  children,
}: React.PropsWithChildren<Props>) => {
  const classes = useStyles();
  const icon = isFolder ? <FolderIcon /> : <AssignmentIcon />;
  const handleContextMenu =
    onContextMenu &&
    ((isFolder: boolean) => (event: React.MouseEvent<HTMLButtonElement>) =>
      onContextMenu(event, isFolder));

  return (
    <Button
      className={classes.button}
      variant="outlined"
      size="large"
      onClick={onClick}
      startIcon={icon}
      onContextMenu={handleContextMenu?.call(false, isFolder || false)}
    >
      {children}
    </Button>
  );
};

export default FolderButton;
