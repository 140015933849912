import Axios from "axios";
import { parseISO } from "date-fns";
import { keycloak } from "../auth";
import { DataControllerDTO } from "../models/Datacontrollers";

export const IS_LOCALHOST_API = process.env.REACT_APP_API === "localhost";

let API_BASE_URL: string;
if (IS_LOCALHOST_API) {
  API_BASE_URL = "http://localhost:8081/api";
} else {
  API_BASE_URL = "api";
}

export const axios = Axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
});

let selectedDataController = "";
let isRefreshingToken = false;
type AccessTokenCallback = (error: any | null) => void;
let tokenSubscribers: AccessTokenCallback[] = [];

export const setDataControllerHeaderValue = (
  dataController: DataControllerDTO["realmName"]
) => {
  selectedDataController = dataController;
};

const subscribeTokenRefresh = (callback: AccessTokenCallback) => {
  tokenSubscribers.push(callback);
};

const onTokenRefreshed = (error: any | null) => {
  tokenSubscribers.forEach((callback) => callback(error));
  tokenSubscribers = [];
};

axios.interceptors.request.use(
  (config) => {
    const token = keycloak.token;

    if (token != null && config != null && config.url != null) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["X-DATACONTROLLER"] = selectedDataController;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * We get dates from the spring backend in UTC zulu time - however, axios has decided that such a string is perfect
 * just the way it is, and so it won't deserialize it to a js Date.
 * This function recursively traverses through any object we get from the server and transforms the date strings to
 * real dates
 * @param obj
 */
function transformDates(obj: any) {
  if (obj == null) {
    return obj;
  }
  switch (typeof obj) {
    case "object":
      Object.keys(obj).forEach((key) => {
        obj[key] = transformDates(obj[key]);
      });
      return obj;
    case "string":
      if (
        /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])\.?([0-9][0-9]?[0-9]?)?(\\.[0-9]+)?(Z)?$/.test(
          obj
        )
      ) {
        // The above regex was shamelessly snatched from here: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s07.html
        return parseISO(obj);
      }
      return obj;
    default:
      return obj;
  }
}

axios.interceptors.response.use(
  (response) => {
    const startTime = new Date();
    response.data = transformDates(response.data);
    console.debug(
      `Deserialized dates in ${new Date().getTime() - startTime.getTime()} ms`
    );
    return response;
  },
  (error) => {
    if (error.response == null) {
      return Promise.reject(error);
    }
    const {
      config,
      response: { status },
    } = error;

    if (status !== 401) {
      return Promise.reject(error);
    }

    // if (
    //   config.url.includes('/keycloak/login') ||
    //   config.url.includes('/keycloak/logout') ||
    //   config.url.includes('/keycloak/refresh')
    // ) {
    //   return Promise.reject(error);
    // }

    const originalRequest = config;

    // TODO Handle 401 from backend
    if (
      !isRefreshingToken &&
      originalRequest.headers["X-Retry-Request"] === undefined
    ) {
      isRefreshingToken = true;
      const currentRefreshToken = keycloak.refreshToken;

      if (currentRefreshToken == null) {
        // TODO: Force logout
        // return forceLogout(store);
      }

      keycloak
        .updateToken(5)
        .success((_) => {
          isRefreshingToken = false;
          onTokenRefreshed(null);
        })
        .error((error) => {
          isRefreshingToken = false;
          onTokenRefreshed(error);
          // TODO: Force logout
        });
    } else {
      return Promise.reject(error);
    }

    const retryOriginalRequest = new Promise((resolve, reject) => {
      subscribeTokenRefresh((refreshingError) => {
        if (refreshingError != null) {
          return reject(refreshingError);
        }
        originalRequest.headers.Authorization = `Bearer ${keycloak.token}`;
        originalRequest.headers["X-Retry-Request"] = true;
        return resolve(axios(originalRequest));
      });
    });

    return retryOriginalRequest;
  }
);
