import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import LeakAddIcon from "@material-ui/icons/LeakAdd";
import RefreshIcon from "@material-ui/icons/Refresh";
import { AxiosResponse } from "axios";
import React from "react";
import { useAxios } from "../../../../../api/useAxios";
import DeleteIconButton from "../../../../../components/DeleteIconButton";
import {
  WebhookDTO,
  WebhookPingDTO,
  WebhookPingResultDTO,
} from "../../../../../models/Webhook";

interface Props {
  webhooks: WebhookDTO[];
  handleEdit: (webhookId: string) => void;
  handleDelete: (webhookId: string) => void;
  handleTokenRefresh: (webhookId: string) => void;
}

interface PingState {
  isPinging: boolean;
  pingSuccess: boolean;
  isShowingResult: boolean;
  currentPingIndex: number;
}

const WebhookTable: React.FC<Props> = (props) => {
  const [pingState, setPingState] = React.useState<PingState>({
    isPinging: false,
    isShowingResult: false,
    pingSuccess: false,
    currentPingIndex: 0,
  });
  const axios = useAxios();
  const [isRefreshTokenDialogOpen, setIsRefreshTokenDialogOpen] =
    React.useState(false);
  const [refreshTokenWebhookIndex, setRefreshTokenWebhookIndex] =
    React.useState<number | null>(null);

  async function handlePing(webhookIndex: number) {
    if (pingState.isPinging) return;

    setPingState({
      ...pingState,
      isPinging: true,
      currentPingIndex: webhookIndex,
    });

    const webhook = props.webhooks[webhookIndex];
    const webhookPingDTO: WebhookPingDTO = {
      url: webhook.url,
      httpHeaders: webhook.httpHeaders,
      token: webhook.token,
    };
    const result = await axios.post<
      WebhookPingDTO,
      AxiosResponse<WebhookPingResultDTO>
    >("/webhooks/ping", webhookPingDTO);
    setPingState({
      currentPingIndex: webhookIndex,
      pingSuccess: result.data.success,
      isPinging: false,
      isShowingResult: true,
    });
  }

  function handleEdit(webhookIndex: number) {
    const webhook = props.webhooks[webhookIndex];
    props.handleEdit(webhook.id);
  }

  function handleDelete(webhookIndex: number) {
    const webhook = props.webhooks[webhookIndex];
    props.handleDelete(webhook.id);
  }

  function handleTokenRefresh(webhookIndex: number) {
    const webhook = props.webhooks[webhookIndex];
    setRefreshTokenWebhookIndex(null);
    setIsRefreshTokenDialogOpen(false);
    props.handleTokenRefresh(webhook.id);
  }

  React.useEffect(() => {
    if (pingState.isPinging) return;
    const timer = setTimeout(() => {
      setPingState((s) => ({ ...s, isShowingResult: false }));
    }, 2000);

    return () => clearTimeout(timer);
  }, [pingState.isPinging]);

  function renderPing(index: number) {
    if (pingState.isShowingResult && pingState.currentPingIndex === index) {
      if (pingState.pingSuccess) {
        return (
          <Fade in={pingState.pingSuccess}>
            <CheckIcon color="primary" />
          </Fade>
        );
      } else {
        return (
          <Fade in={!pingState.pingSuccess}>
            <ClearIcon color="error" />
          </Fade>
        );
      }
    } else {
      if (pingState.isPinging && pingState.currentPingIndex === index) {
        return <CircularProgress size={20} />;
      } else {
        return (
          <Fade in={true}>
            <Tooltip title="Ping URL">
              <IconButton
                disabled={pingState.isPinging}
                onClick={() => handlePing(index)}
              >
                <LeakAddIcon />
              </IconButton>
            </Tooltip>
          </Fade>
        );
      }
    }
  }

  function handleTokenRefreshButtonClicked(webhookIndex: number) {
    setRefreshTokenWebhookIndex(webhookIndex);
    setIsRefreshTokenDialogOpen(true);
  }

  function renderTokenRefreshButton(webhookIndex: number) {
    return (
      <Tooltip title="Generate new token" aria-label="generate new token">
        <IconButton
          onClick={() => handleTokenRefreshButtonClicked(webhookIndex)}
        >
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>URL</TableCell>
            <TableCell>Topics</TableCell>
            <TableCell>Token</TableCell>
            <TableCell>Headers</TableCell>
            <TableCell>Last Updated</TableCell>
            <TableCell>Ping</TableCell>
            <TableCell>Edit</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.webhooks.map((webhook, index) => (
            <TableRow key={`webhook-${index}`}>
              <TableCell>
                <Typography variant="body2">{webhook.url}</Typography>
              </TableCell>
              <TableCell>
                <List>
                  {webhook.topics.map((topic, index) => (
                    <ListItem key={`topic-${index}`} disableGutters dense>
                      <ListItemText
                        primary={topic.friendlyName}
                        secondary={topic.name}
                      />
                    </ListItem>
                  ))}
                </List>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {webhook.token} {renderTokenRefreshButton(index)}
                </Typography>
              </TableCell>
              <TableCell>
                {webhook.httpHeaders ? (
                  <List>
                    {webhook.httpHeaders.map((httpHeader, index) => (
                      <ListItem
                        key={`http-header-${index}`}
                        disableGutters
                        dense
                      >
                        <ListItemText
                          primary={`${httpHeader.name}: ${httpHeader.value}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  "None"
                )}
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {webhook.lastUpdated.toLocaleString()}
                </Typography>
              </TableCell>
              <TableCell>{renderPing(index)}</TableCell>
              <TableCell>
                <Tooltip title="Edit">
                  <IconButton onClick={() => handleEdit(index)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>
                <DeleteIconButton
                  title="Webhook"
                  onDelete={() => handleDelete(index)}
                >
                  Are you sure you want to delete the webhook?
                </DeleteIconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog
        open={isRefreshTokenDialogOpen}
        onClose={() => setIsRefreshTokenDialogOpen(false)}
        aria-labelledby="refresh-token-dialog-title"
      >
        <DialogTitle id="refresh-token-dialog-title">
          Generate new token
        </DialogTitle>
        <DialogContent>
          Are you sure you want to generate a new token (the old token cannot be
          restored)?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsRefreshTokenDialogOpen(false)}>No</Button>
          <Button
            onClick={() => handleTokenRefresh(refreshTokenWebhookIndex || 0)}
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WebhookTable;
