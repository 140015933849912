import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
} from "@material-ui/core";
import React from "react";
import { HTTPHeaderDTO } from "../../../../../models/HTTPHeader";
import HTTPHeaderElement from "./HTTPHeaderElement";

interface Props {
  handleClose: (data?: HTTPHeaderDTO[]) => void;
  isOpen: boolean;
  httpHeaders?: HTTPHeaderDTO[];
}

const HTTPHeaderDialog: React.FC<Props> = ({
  handleClose,
  isOpen,
  httpHeaders,
}) => {
  const [headers, setHeaders] = React.useState<HTTPHeaderDTO[]>(
    httpHeaders || []
  );

  function addHeader() {
    const header: HTTPHeaderDTO = {
      name: "",
      value: "",
    };
    setHeaders([...headers, header]);
  }

  React.useEffect(() => {
    if (httpHeaders) {
      setHeaders(httpHeaders);
    }
  }, [httpHeaders]);

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={() => handleClose()}
      maxWidth="lg"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Headers</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <HTTPHeaderElements
                headers={headers}
                handleHeadersChanged={(newHeaders) => setHeaders(newHeaders)}
              />
              <Box mt={2}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={addHeader}
                  color="primary"
                >
                  Add header
                </Button>
              </Box>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleClose(headers)} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface HTTPHeaderElementsProps {
  headers: HTTPHeaderDTO[];
  handleHeadersChanged: (newHeaders: HTTPHeaderDTO[]) => void;
}

const HTTPHeaderElements: React.FC<HTTPHeaderElementsProps> = ({
  headers,
  handleHeadersChanged,
}) => {
  function handleHeaderChanged(index: number, dto: HTTPHeaderDTO) {
    let newFile: HTTPHeaderDTO = dto;
    let items = [...headers];
    items[index] = newFile;
    handleHeadersChanged(items);
  }

  function removeHeader(index: number) {
    let newItems = [
      ...headers.slice(0, index),
      ...headers.slice(index + 1, headers.length),
    ];
    handleHeadersChanged(newItems);
  }

  return (
    <>
      {headers.map((header, i) => (
        <HTTPHeaderElement
          key={"header-" + i}
          headerDTO={header}
          index={i}
          handleHeaderChanged={(value) => handleHeaderChanged(i, value)}
          handleRemoveHeader={() => removeHeader(i)}
        />
      ))}
    </>
  );
};

export default HTTPHeaderDialog;
