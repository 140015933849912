import * as React from "react";
import { Switch, RouteComponentProps, Route } from "react-router";
import SettingsOverview from "./pages/SettingsOverview";

const Settings = (props: RouteComponentProps<{}>) => (
  <Switch>
    <Route path={props.match.path} exact component={SettingsOverview} />
  </Switch>
);

export default Settings;
