import * as React from "react";
import { createStyles, makeStyles, TextField } from "@material-ui/core";
import FileUploadField from "../../../../../../components/FileUploadField";

interface Props {
  required?: boolean;
  onChange: (
    cert: ServiceProviderAgreementSecurityConfigurationDTO | null
  ) => any;
  certificate?: ServiceProviderAgreementSecurityConfigurationDTO | null;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    fullWidth: {
      width: "100%",
    },
  })
);

const Pkcs12Config = (props: Props) => {
  const classes = useStyles();
  const [certificate, setCertificate] =
    React.useState<ServiceProviderAgreementSecurityConfigurationDTO>(
      props.certificate ??
        ({} as ServiceProviderAgreementSecurityConfigurationDTO)
    );

  function handleChange(
    property: keyof ServiceProviderAgreementSecurityConfigurationDTO,
    value: any
  ): void {
    certificate[property] = value;
    setCertificate({ ...certificate });
    props.onChange(
      Object.values(certificate).every((x) => x == null) ? null : certificate
    );
  }

  return (
    <div className={classes.fullWidth}>
      <FileUploadField
        label="Upload Certificate"
        required={!!props.required}
        accept={[".p12", ".pfx"]}
        onChange={(data) => handleChange("certificate", data || null)}
      />
      <TextField
        label="Keystore password"
        fullWidth={true}
        type="password"
        required={!!props.required}
        margin="normal"
        variant="outlined"
        value={certificate?.password || ""}
        autoComplete="new-password"
        onChange={(event) => handleChange("password", event.target.value)}
      />
    </div>
  );
};

export default Pkcs12Config;
