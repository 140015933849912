import * as React from "react";
import { RouteComponentProps, Route, Switch } from "react-router";
import ConsentTypeDetails from "./pages/ConsentTypeDetails";
import NewConsent from "./pages/NewConsent";
import ConsentDetails from "./pages/ConsentDetails";
import EditConsent from "./pages/EditConsent";

type Props = RouteComponentProps<{}>;

const ConsentTypes = ({ match }: Props) => {
  return (
    <Switch>
      <Route
        path={match.path + "/:consentTypeId"}
        exact
        component={ConsentTypeDetails}
      />
      <Route
        path={match.path + "/:consentTypeId/newConsent"}
        exact
        component={NewConsent}
      />
      <Route
        path={match.path + "/:consentTypeId/consents/:consentVersionId"}
        exact
        component={ConsentDetails}
      />
      <Route
        path={match.path + "/:consentTypeId/consents/:consentVersionId/edit"}
        exact
        component={EditConsent}
      />
    </Switch>
  );
};

export default ConsentTypes;
