import * as React from "react";
import SplitView from "../../../../components/SplitView";
import { RouteComponentProps } from "react-router";
import {
  TextField,
  Grid,
  Button,
  Fade,
  CircularProgress,
  Typography,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import ConsentPreview from "../../../../components/ConsentPreview";
import styled from "styled-components/macro";
import { useAxios } from "../../../../api/useAxios";
import { CreateConsentVersionDTO } from "../../../../models/Consents";
import IconText from "../../../../components/IconText";
import { Save as SaveIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: "relative",
      padding: "1.5rem 1.5rem 0 3rem",
      backgroundColor: "white",
      height: "100%",
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    header: {
      paddingBottom: "1.5rem",
    },
    previewContainer: {
      height: "100%",
      padding: "1rem 2.5rem 1rem 1rem",
    },
  })
);

const CenteredLoadingDiv = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.7rem;
`;

interface RouteProps {
  consentTypeGroupId: string;
  consentTypeId: string;
}

type Props = RouteComponentProps<RouteProps>;

const NewConsent = ({ match, history }: Props) => {
  const classes = useStyles();
  const [name, setName] = React.useState<string>("");
  const [html, setHtml] = React.useState<string>("");
  const [css, setCss] = React.useState<string>("");
  const [acceptText, setAcceptText] = React.useState<string>("");
  const [rejectText, setRejectText] = React.useState<string>("");
  const [pageTitle, setPageTitle] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const axios = useAxios();

  function onSuccess(consentVersionId: string) {
    history.push(
      `/consentTypes/${match.params.consentTypeId}/consents/${consentVersionId}`
    );
  }

  function onCancel() {
    history.push(`/consentTypes/${match.params.consentTypeId}`);
  }

  function onSubmit(event: React.FormEvent) {
    event.preventDefault();

    if (
      name === "" ||
      html === "" ||
      css === "" ||
      acceptText === "" ||
      rejectText === "" ||
      pageTitle === ""
    ) {
      // TODO: Show error
      return;
    }

    setIsLoading(true);

    const createConsentDTO: CreateConsentVersionDTO = {
      name,
      document: {
        html,
        css,
        acceptText,
        rejectText,
        pageTitle,
      },
    };

    axios
      .post<ConsentVersionDTO>(
        `/consentTypes/${match.params.consentTypeId}/consentVersions`,
        createConsentDTO
      )
      .then((response) => {
        onSuccess(response.data.versionId);
      })
      .catch(() => {
        // TODO: Show error
        setIsLoading(false);
      });
  }

  return (
    <SplitView
      left={
        <div className={classes.container}>
          <Typography variant="h3" color="primary" className={classes.header}>
            New Consent
          </Typography>
          <form onSubmit={onSubmit}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <TextField
                  autoFocus
                  id="name"
                  label="Consent Name"
                  fullWidth
                  disabled={isLoading}
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  required
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={3}
                >
                  <Grid item xs={6}>
                    <TextField
                      id="html"
                      label="HTML"
                      fullWidth
                      disabled={isLoading}
                      value={html}
                      onChange={(event) => setHtml(event.target.value)}
                      required
                      multiline
                      variant="outlined"
                      rows={12}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="css"
                      label="CSS"
                      fullWidth
                      disabled={isLoading}
                      value={css}
                      onChange={(event) => setCss(event.target.value)}
                      required
                      multiline
                      variant="outlined"
                      rows={12}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item xs={6}>
                    <TextField
                      id="acceptText"
                      label="Accept Text"
                      fullWidth
                      disabled={isLoading}
                      value={acceptText}
                      onChange={(event) => setAcceptText(event.target.value)}
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="rejectText"
                      label="Reject Text"
                      fullWidth
                      disabled={isLoading}
                      value={rejectText}
                      onChange={(event) => setRejectText(event.target.value)}
                      required
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  id="pageTitle"
                  label="Page Title"
                  fullWidth
                  disabled={isLoading}
                  value={pageTitle}
                  onChange={(event) => setPageTitle(event.target.value)}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="flex-end"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      disabled={isLoading}
                      onClick={onCancel}
                      color="secondary"
                      size="large"
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={isLoading}
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      <IconText icon={SaveIcon} fontSize="small">
                        Save
                      </IconText>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Fade
              in={isLoading}
              style={{
                transitionDelay: isLoading ? "800ms" : "0ms",
              }}
              unmountOnExit
            >
              <CenteredLoadingDiv>
                <CircularProgress size={50} />
              </CenteredLoadingDiv>
            </Fade>
          </form>
        </div>
      }
      right={
        <Grid container xs className={classes.previewContainer}>
          <ConsentPreview
            html={html}
            htmlCss={css}
            rejectText={rejectText}
            acceptText={acceptText}
            pageTitle={pageTitle}
          />
        </Grid>
      }
      leftSize={7}
      rightSize={5}
    />
  );
};

export default NewConsent;
