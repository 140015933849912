import * as React from "react";
import { Switch, RouteComponentProps, Route } from "react-router";
import ScopeOverview from "./pages/ScopeOverview";

const Scopes = (props: RouteComponentProps<{}>) => (
  <Switch>
    <Route path={props.match.path} exact component={ScopeOverview} />
  </Switch>
);

export default Scopes;
