import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Add as AddIcon, Edit as EditIcon } from "@material-ui/icons";
import * as React from "react";
import { usePaginatedQuery } from "react-query";
import { v4 as uuid } from "uuid";
import { useAxios } from "../../../../api/useAxios";
import DeleteIconButton from "../../../../components/DeleteIconButton";
import IconText from "../../../../components/IconText";
import Rows from "../../../../components/Rows";
import { PagedDTO } from "../../../../models/Paged";
import {
  CreateScopeDTO,
  ScopeDetailsDTO,
  UpdateScopeDTO,
} from "../../../../models/Scopes";
import CreateScopeDialog from "./components/CreateScopeDialog";
import EditScopeDialog from "./components/EditScopeDialog";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: "2.5rem 3rem 0.5rem",
    },
    titleContainer: {
      paddingBottom: "1.5rem",
    },
    title: {
      paddingRight: "1rem",
    },
  })
);

const ScopeOverview = () => {
  const classes = useStyles();
  const [newScopeNonce, setNewScopeNonce] = React.useState(uuid());
  const [openCreateModal, setOpenCreateModal] = React.useState(false);
  const [selectedScopeId, setSelectedScopeId] = React.useState<string | null>(
    null
  );
  const [scopePage] = React.useState(0);

  const scopesStatus = usePaginatedQuery(["scopes", scopePage], fetchScopes);
  const { resolvedData: scopesPaged, refetch: refreshScopes } = scopesStatus;
  const scopes = scopesPaged?.data;

  const axios = useAxios();

  const selectedScope =
    scopes && scopes.find((scope) => scope.id === selectedScopeId);

  async function fetchScopes(key: string, page = 0) {
    const scopesResult = await axios.get<PagedDTO<ScopeDetailsDTO>>(
      "/scopes?size=20&page=" + page
    );
    return scopesResult.data;
  }

  function handleOpenCreateModal() {
    setOpenCreateModal(true);
  }

  function handleCloseCreateModal() {
    setOpenCreateModal(false);
    setNewScopeNonce(uuid());
  }

  function handleCreateSubmit(createScopeDTO: CreateScopeDTO) {
    handleCloseCreateModal();
    axios
      .post(`/scopes`, createScopeDTO)
      .then(() => {
        refreshScopes();
      })
      .catch(() => {
        // TODO: Show error
      });
  }

  function handleCloseEditModal() {
    setSelectedScopeId(null);
  }

  function handleEditSubmit(scopeId: string, updateScopeDTO: UpdateScopeDTO) {
    handleCloseEditModal();
    axios
      .put(`/scopes/${scopeId}`, updateScopeDTO)
      .then(() => {
        refreshScopes();
      })
      .catch(() => {
        // TODO: Show error
      });
  }

  function editScope(scopeId: string) {
    setSelectedScopeId(scopeId);
  }

  function deleteScope(scopeId: string) {
    axios
      .delete(`/scopes/${scopeId}`)
      .then(() => {
        refreshScopes();
      })
      .catch(() => {
        // TODO: Show error
      });
  }

  return (
    <div className={classes.container}>
      <Grid
        container
        spacing={0}
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.titleContainer}
      >
        <Grid item>
          <Typography variant="h3" color="primary" className={classes.title}>
            Scopes
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={handleOpenCreateModal}>
            <IconText icon={AddIcon}>New scope</IconText>
          </Button>
        </Grid>
      </Grid>

      <Typography>
        Manage the scopes of your API. These scopes should map to the roles or
        authorities defined in your API.
        <br />
        Once a scope has been assigned to a consent version it is not currently
        possible to delete or edit it again.
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Scope Name</TableCell>
            <TableCell>Note</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Rows
            colSpan={3}
            dataApiState={scopesStatus}
            data={scopes}
            fetchingText="We are fetching the scopes, please wait..."
            noDataText=""
            errorText=""
            rowRender={(item) => (
              <TableRow key={item.id}>
                <TableCell component="th" scope="row">
                  {item.name}
                </TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell align="right">
                  {item.editable ? (
                    <IconButton onClick={() => editScope(item.id)}>
                      <EditIcon />
                    </IconButton>
                  ) : null}
                  {item.deletable ? (
                    <DeleteIconButton
                      title="Scope"
                      onDelete={() => deleteScope(item.id)}
                    >
                      Are you sure you want to delete the scope:{" "}
                      <strong>{item.name}</strong>
                    </DeleteIconButton>
                  ) : null}
                </TableCell>
              </TableRow>
            )}
          />
        </TableBody>
      </Table>
      <CreateScopeDialog
        key={newScopeNonce}
        isOpen={openCreateModal}
        onClose={handleCloseCreateModal}
        onSubmit={handleCreateSubmit}
      />
      {selectedScope != null ? (
        <EditScopeDialog
          scope={selectedScope}
          isOpen={true}
          onClose={handleCloseEditModal}
          onSubmit={handleEditSubmit}
        />
      ) : null}
    </div>
  );
};

export default ScopeOverview;
