import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Chip,
  MenuItem,
  Input,
  ListSubheader,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import {
  UserValidationSettingDetailsDTO,
  UpdateUserValidationSettingDTO,
} from "../../../../../models/UserValidationSettings";
import { GroupConsentTypeConsentVersionGroupingDTO } from "../../../../../models/GroupConsentTypeConsentVersionGrouping";

interface Props {
  setting: UserValidationSettingDetailsDTO;
  isOpen: boolean;
  availableConsentVersionGroupings:
    | GroupConsentTypeConsentVersionGroupingDTO[]
    | null
    | undefined;
  consentIdsAlreadyAssigned: string[];
  onClose: () => void;
  onSubmit: (
    id: string,
    updateSettingDTO: UpdateUserValidationSettingDTO
  ) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    errorText: {
      color: theme.palette.error.main,
    },
  })
);

const EditSettingDialog = ({
  setting,
  isOpen,
  availableConsentVersionGroupings,
  consentIdsAlreadyAssigned,
  onClose,
  onSubmit,
}: Props) => {
  const classes = useStyles();
  const [name, setName] = React.useState<string | null>(setting.name);
  const [clientId, setClientId] = React.useState<string | null>(
    setting.clientId
  );
  const [clientSecret, setClientSecret] = React.useState<string | null>(
    setting.clientSecret
  );
  const [validationUrl, setValidationUrl] = React.useState<string | null>(
    setting.validationUrl
  );
  const [useAdminRealm, setUseAdminRealm] = React.useState<boolean>(
    setting.useAdminRealm
  );
  const [consentVersionIds, setConsentVersionIds] = React.useState<string[]>(
    setting.consentVersionIds
  );
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
    React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  function submit() {
    if (
      clientId == null ||
      clientId === "" ||
      name == null ||
      name === "" ||
      clientSecret == null ||
      clientSecret === "" ||
      validationUrl == null ||
      validationUrl === ""
    ) {
      setErrorMessage(
        "One or more of the values are empty, please make sure all fields are filled out."
      );
      return;
    }

    if (consentVersionIds.some((x) => consentIdsAlreadyAssigned.includes(x))) {
      setIsConfirmDialogOpen(true);
    } else {
      onSubmit(setting.id, {
        clientId: clientId,
        name: name,
        clientSecret: clientSecret,
        validationUrl: validationUrl,
        useAdminRealm: useAdminRealm,
        consentVersionIds: consentVersionIds,
      });
    }
  }

  function onConfirmConfirmed() {
    if (
      clientId == null ||
      clientId === "" ||
      name == null ||
      name === "" ||
      clientSecret == null ||
      clientSecret === "" ||
      validationUrl == null ||
      validationUrl === ""
    ) {
      setErrorMessage(
        "One or more of the values are empty, please make sure all fields are filled out."
      );
      return;
    }

    onSubmit(setting.id, {
      clientId: clientId,
      clientSecret: clientSecret,
      validationUrl: validationUrl,
      useAdminRealm: useAdminRealm,
      consentVersionIds: consentVersionIds,
    });
  }

  function handleConsentVersionIdsChanged(
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): void {
    const selectedValues = event.target.value as string[];
    setConsentVersionIds(
      selectedValues.filter((x) => x != null && x.trim() !== "")
    );
  }

  function onConfirmClose() {
    setIsConfirmDialogOpen(false);
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Edit User Validation Setting
        </DialogTitle>
        <DialogContent>
          {errorMessage == null ? null : (
            <span className={classes.errorText}>{errorMessage}</span>
          )}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="name"
            fullWidth
            value={name}
            required
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            margin="dense"
            id="clientId"
            label="Client ID"
            fullWidth
            value={clientId}
            required
            onChange={(event) => setClientId(event.target.value)}
          />
          <TextField
            margin="dense"
            id="clientSecret"
            label="Client Secret"
            fullWidth
            value={clientSecret}
            required
            onChange={(event) => setClientSecret(event.target.value)}
          />
          <TextField
            margin="dense"
            id="validationUrl"
            label="Validation URL"
            fullWidth
            value={validationUrl}
            required
            onChange={(event) => setValidationUrl(event.target.value)}
          />
          <FormControlLabel
            label="Use Admin Realm"
            control={
              <Checkbox
                id="useAdminRealm"
                onChange={(event) => setUseAdminRealm(event.target.checked)}
                checked={useAdminRealm || false}
              />
            }
          />
          <FormControl fullWidth>
            <InputLabel id="consentVersionIdsLabel">
              Consent Version IDs (select multiple)
            </InputLabel>
            <Select
              labelId="consentVersionIdsLabel"
              id="consentVersionIds"
              multiple
              value={consentVersionIds}
              onChange={(event) => handleConsentVersionIdsChanged(event)}
              input={<Input id="consentVersionIds" />}
              renderValue={(selected: any) => (
                <div className={classes.chips}>
                  {selected.map((value: string) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
            >
              {availableConsentVersionGroupings?.map((grouping) => [
                <ListSubheader>
                  {grouping.consentTypeGroup.name} - {grouping.consentType.name}
                </ListSubheader>,
                grouping.consentVersions.map((cv) => (
                  <MenuItem
                    key={cv.versionId}
                    value={cv.versionId}
                    selected={consentVersionIds.includes(cv.versionId)}
                  >
                    {cv.name} ({cv.versionId})
                  </MenuItem>
                )),
              ])}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button type="submit" onClick={submit} color="primary">
            Save
          </Button>
        </DialogActions>
        <Dialog
          open={isConfirmDialogOpen}
          onClose={onConfirmClose}
          aria-labelledby="delete-dialog-title"
        >
          <DialogTitle id="delete-dialog-title">Confirm action</DialogTitle>
          <DialogContent>
            <p>
              One or more of the selected consent versions already have a user
              validation setting assigned.
            </p>
            <p>Are you sure you want to proceed?</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={onConfirmClose}>Cancel</Button>
            <Button onClick={onConfirmConfirmed} variant="contained">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    </>
  );
};

export default EditSettingDialog;
