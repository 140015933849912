import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  IconButton,
  LinearProgress,
  Paper,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import orange from "@material-ui/core/colors/orange";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Error as ErrorIcon,
  StarBorder as StarBorderIcon,
  Star as StarIcon,
} from "@material-ui/icons";
import { AxiosResponse } from "axios";
import * as React from "react";
import { usePaginatedQuery, useQuery } from "react-query";
import { v4 as uuid } from "uuid";
import { useAxios } from "../../../../../api/useAxios";
import DeleteIconButton from "../../../../../components/DeleteIconButton";
import IconText from "../../../../../components/IconText";
import { ConsentVersionWithVendorAndAppDTO } from "../../../../../models/Consents";
import { GroupConsentTypeConsentVersionGroupingDTO } from "../../../../../models/GroupConsentTypeConsentVersionGrouping";
import {
  CreateUserValidationSettingDTO,
  UpdateUserValidationSettingDTO,
  UserValidationSettingDetailsDTO,
} from "../../../../../models/UserValidationSettings";
import CreateSettingDialog from "./CreateSettingDialog";
import EditSettingDialog from "./EditSettingDialog";

enum StateActions {
  OPEN_CREATE_MODAL = "OPEN_CREATE_MODAL",
  CLOSE_CREATE_MODAL = "CLOSE_CREATE_MODAL",
  SET_CACHED_USER_VALIDATION_SETTINGS = "SET_CACHED_USER_VALIDATION_SETTINGS",
  SET_USER_VALIDATION_SETTINGS_IS_LOADED = "SET_USER_VALIDATION_SETTINGS_IS_LOADED",
  SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE",
  CLOSE_SNACKBAR = "CLOSE_SNACKBAR",
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: "100%",
    },
    titleContainer: {
      paddingBottom: "1.5rem",
    },
    title: {
      paddingRight: "1rem",
    },
    detailsContainer: {
      marginTop: "2rem",
      padding: "2rem",
      maxWidth: "1200px",
    },
    isAssignedToDataController: {
      backgroundColor: orange["100"],
    },
    square: {
      backgroundColor: orange["100"],
    },
    paper: {
      margin: "0.5em 0",
    },
    snackbarError: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    snackbarErrorIcon: {
      marginBottom: "-0.25em",
      marginRight: "0.25em",
    },
    cardGrid: {
      display: "flex",
    },
    card: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      flexBasis: "100%",
    },
  })
);

const WhiteTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    fontSize: "0.9rem",
  },
}))(Tooltip);

const loadStateReducer = (
  state: any,
  action: { data: any; type: StateActions }
) => {
  switch (action.type) {
    case StateActions.OPEN_CREATE_MODAL:
      return {
        ...state,
        openCreateModal: true,
      };
    case StateActions.CLOSE_CREATE_MODAL:
      return {
        ...state,
        openCreateModal: false,
        newSettingNonce: uuid(),
      };
    case StateActions.SET_CACHED_USER_VALIDATION_SETTINGS:
      return {
        ...state,
        userValidationSettingsIsLoaded: true,
        cachedUserValidationSettings: action.data,
        assignedConsentVersionIds: action.data.flatMap(
          (x: any) => x.consentVersionIds
        ),
      };
    case StateActions.SET_USER_VALIDATION_SETTINGS_IS_LOADED:
      return {
        ...state,
        userValidationSettingsIsLoaded: action.data,
      };
    case StateActions.SHOW_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.data,
        userValidationSettingsIsLoaded: false,
        openSnackbar: true,
      };
    case StateActions.CLOSE_SNACKBAR: {
      return {
        ...state,
        openSnackbar: false,
      };
    }
    default:
      throw new Error();
  }
};

const UserValidationSettingsOverview = () => {
  const classes = useStyles();
  const axios = useAxios();

  const [userValidationSettingsPage] = React.useState(0);

  const {
    status: userValidationSettingsStatus,
    resolvedData: userValidationSettingsResponse,
    refetch: refetchUserValidationSettings,
  } = usePaginatedQuery(
    ["userValidationSettings", userValidationSettingsPage],
    fetchUserValidationSettings
  );

  const {
    status: availableConsentVersionGroupingsStatus,
    data: availableConsentVersionGroupings,
  } = useQuery(
    "availableContentVersionGroupings",
    fetchAvailableConsentVersionGroupings
  );

  const [selectedSettingId, setSelectedSettingId] = React.useState<
    string | null
  >(null);
  const [
    confirmRemoveSettingFromDataControllerOpen,
    setConfirmRemoveSettingFromDataControllerOpen,
  ] = React.useState<boolean>(false);
  const [
    removeFromDataControllerSettingId,
    setRemoveFromDataControllerSettingId,
  ] = React.useState<string | null>(null);

  const [state, dispatch] = React.useReducer(loadStateReducer, {
    openSnackbar: false,
    errorMessage: "",
    newSettingNonce: uuid(),
    openCreateModal: false,
    userValidationSettingsIsLoaded: false,
    assignedConsentVersionIds: [],
    cachedUserValidationSettings: null,
  });
  const selectedSetting =
    state.cachedUserValidationSettings &&
    state.cachedUserValidationSettings.find(
      (setting: UserValidationSettingDetailsDTO) =>
        setting.id === selectedSettingId
    );

  const consentVersionsWithVendorAndApp = React.useMemo<
    ConsentVersionWithVendorAndAppDTO[] | null
  >(() => {
    if (
      availableConsentVersionGroupingsStatus === "success" &&
      availableConsentVersionGroupings &&
      availableConsentVersionGroupings.length !== 0
    ) {
      return availableConsentVersionGroupings.flatMap((grouping) =>
        grouping.consentVersions.map((consentVersion) => ({
          app: grouping.consentType,
          vendor: grouping.consentTypeGroup,
          createdAt: consentVersion.createdAt,
          name: consentVersion.name,
          publishedAt: consentVersion.publishedAt,
          status: consentVersion.status,
          versionId: consentVersion.versionId,
          userValidationSettingId: consentVersion.userValidationSettingId,
        }))
      );
    } else {
      return null;
    }
  }, [
    availableConsentVersionGroupings,
    availableConsentVersionGroupingsStatus,
  ]);

  function handleOpenCreateModal() {
    dispatch({ data: null, type: StateActions.OPEN_CREATE_MODAL });
  }

  React.useEffect(waitForUserValidationSettingsLoadedCallback, [
    userValidationSettingsResponse,
  ]);
  function waitForUserValidationSettingsLoadedCallback() {
    if (
      userValidationSettingsStatus === "success" &&
      userValidationSettingsResponse !== undefined
    ) {
      dispatch({
        data: userValidationSettingsResponse.data,
        type: StateActions.SET_CACHED_USER_VALIDATION_SETTINGS,
      });
    }
  }

  function renderConsentVersions(
    consentWithVendorAndApps: ConsentVersionWithVendorAndAppDTO[]
  ) {
    const items = consentWithVendorAndApps.map((item) => (
      <li key={item.versionId + "-list"}>
        <WhiteTooltip
          title={
            <>
              <span>
                <b>Vendor:</b> {item.vendor.name}
              </span>
              <br />
              <span>
                <b>App:</b> {item.app.name}
              </span>
              <br />
              <span>
                <b>Consent name:</b> {item.name}
              </span>
            </>
          }
        >
          <span>{item.versionId}</span>
        </WhiteTooltip>
      </li>
    ));
    return <ul>{items}</ul>;
  }

  async function fetchUserValidationSettings(key: string, page = 0) {
    const result = await axios("/userValidationSettings?size=20&page=" + page);
    return result.data;
  }

  async function fetchAvailableConsentVersionGroupings(key: string) {
    const result = await axios.get<GroupConsentTypeConsentVersionGroupingDTO[]>(
      "/consentVersions"
    );
    return result.data;
  }

  function onRemoveActiveSettingFromDataControllerClicked(id: string) {
    setRemoveFromDataControllerSettingId(id);
    setConfirmRemoveSettingFromDataControllerOpen(true);
  }

  function onRemoveActiveSettingFromDataControllerConfirmed() {
    handleRemoveActiveSetting(removeFromDataControllerSettingId || "");
    setConfirmRemoveSettingFromDataControllerOpen(false);
  }

  function handleCloseCreateModal() {
    dispatch({ data: null, type: StateActions.CLOSE_CREATE_MODAL });
  }

  function handleCreateSubmit(
    createSettingDTO: CreateUserValidationSettingDTO
  ) {
    handleCloseCreateModal();
    dispatch({
      data: false,
      type: StateActions.SET_USER_VALIDATION_SETTINGS_IS_LOADED,
    });
    axios
      .post(`/userValidationSettings`, createSettingDTO)
      .then(() => {
        refetchUserValidationSettings();
      })
      .catch((reason: any) => {
        dispatch({ data: reason, type: StateActions.SHOW_ERROR_MESSAGE });
      });
  }

  function deleteSetting(settingId: string) {
    dispatch({
      data: false,
      type: StateActions.SET_USER_VALIDATION_SETTINGS_IS_LOADED,
    });
    axios
      .delete(`/userValidationSettings/${settingId}`)
      .then(() => {
        refetchUserValidationSettings();
      })
      .catch((reason: any) => {
        dispatch({ data: reason, type: StateActions.SHOW_ERROR_MESSAGE });
      });
  }

  function handleCloseEditModal() {
    setSelectedSettingId(null);
  }

  function handleEditSubmit(
    settingId: string,
    updateSettingDTO: UpdateUserValidationSettingDTO
  ) {
    handleCloseEditModal();
    dispatch({
      data: false,
      type: StateActions.SET_USER_VALIDATION_SETTINGS_IS_LOADED,
    });
    axios
      .put(`/userValidationSettings/${settingId}`, updateSettingDTO)
      .then(
        (response: AxiosResponse<UserValidationSettingDetailsDTO | null>) => {
          if (
            response.data !== null &&
            state.cachedUserValidationSettings !== null
          ) {
            let copiedUserValidationSettings = [
              ...state.cachedUserValidationSettings,
            ];
            let settingIndex = copiedUserValidationSettings.findIndex(
              (x) => x.id === settingId
            );
            copiedUserValidationSettings[settingIndex] = response.data;

            for (let i = 0; i < copiedUserValidationSettings.length; i++) {
              let setting = copiedUserValidationSettings[i];
              if (setting.id === settingId) continue;
              for (let consentVersionId of response.data.consentVersionIds) {
                let consentVersionIndex =
                  setting.consentVersionIds.indexOf(consentVersionId);
                if (consentVersionIndex !== -1) {
                  setting.consentVersionIds.splice(consentVersionIndex, 1);
                }
              }
            }

            dispatch({
              data: copiedUserValidationSettings,
              type: StateActions.SET_CACHED_USER_VALIDATION_SETTINGS,
            });
          }
        }
      )
      .catch((reason: any) => {
        dispatch({ data: reason, type: StateActions.SHOW_ERROR_MESSAGE });
      });
  }

  function editSetting(settingId: string) {
    setSelectedSettingId(settingId);
  }

  function handleSetActiveSetting(settingId: string) {
    dispatch({
      data: false,
      type: StateActions.SET_USER_VALIDATION_SETTINGS_IS_LOADED,
    });
    axios
      .post(`/controller/activeUserValidationSetting?settingId=${settingId}`)
      .then(
        (response: AxiosResponse<UserValidationSettingDetailsDTO | null>) => {
          if (
            response.data !== null &&
            state.cachedUserValidationSettings !== null
          ) {
            let copiedUserValidationSettings = [
              ...state.cachedUserValidationSettings,
            ];

            let otherSettingIndex = copiedUserValidationSettings.findIndex(
              (x) => x.assignedDataControllerId !== null
            );
            if (otherSettingIndex !== -1) {
              copiedUserValidationSettings[
                otherSettingIndex
              ].assignedDataControllerId = null;
            }

            let settingIndex = copiedUserValidationSettings.findIndex(
              (x) => x.id === settingId
            );
            if (settingIndex !== -1) {
              copiedUserValidationSettings[
                settingIndex
              ].assignedDataControllerId =
                response.data.assignedDataControllerId;
            }
            dispatch({
              data: copiedUserValidationSettings,
              type: StateActions.SET_CACHED_USER_VALIDATION_SETTINGS,
            });
          } else {
            console.warn(
              "something went wrong in setactivesetting callback..."
            );
          }
        }
      )
      .catch((reason: any) => {
        dispatch({ data: reason, type: StateActions.SHOW_ERROR_MESSAGE });
      });
  }

  function handleRemoveActiveSetting(settingId: string) {
    dispatch({
      data: false,
      type: StateActions.SET_USER_VALIDATION_SETTINGS_IS_LOADED,
    });
    axios
      .delete(`/controller/activeUserValidationSetting`)
      .then(
        (response: AxiosResponse<UserValidationSettingDetailsDTO | null>) => {
          if (state.cachedUserValidationSettings !== null) {
            let copiedUserValidationSettings = [
              ...state.cachedUserValidationSettings,
            ];

            let settingIndex = copiedUserValidationSettings.findIndex(
              (x) => x.id === settingId
            );
            if (settingIndex !== -1) {
              copiedUserValidationSettings[
                settingIndex
              ].assignedDataControllerId = null;
              dispatch({
                data: copiedUserValidationSettings,
                type: StateActions.SET_CACHED_USER_VALIDATION_SETTINGS,
              });
            }
          } else {
            console.warn(
              "something went wrong in setactivesetting callback..."
            );
          }
        }
      )
      .catch((reason: any) => {
        dispatch({ data: reason, type: StateActions.SHOW_ERROR_MESSAGE });
      });
  }

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch({ data: null, type: StateActions.CLOSE_SNACKBAR });
  };

  return (
    <div className={classes.container}>
      <Grid
        container
        spacing={0}
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.titleContainer}
      >
        <Grid item>
          <Typography variant="h4" color="primary" className={classes.title}>
            Manage User Validation Settings
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={handleOpenCreateModal}>
            <IconText icon={AddIcon}>New user validation setting</IconText>
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={0}
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} md={3}>
          <Paper className={classes.paper}>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Avatar variant="square" className={classes.square}></Avatar>
              </Grid>
              <Grid item>
                <Typography variant="body1" color="textPrimary">
                  Active on Data Controller
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Hidden mdDown>
        <LinearProgress
          style={{ opacity: state.userValidationSettingsIsLoaded ? 0 : 1 }}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Client ID</TableCell>
              <TableCell>Client Secret</TableCell>
              <TableCell>Use Admin Realm</TableCell>
              <TableCell>Validation URL</TableCell>
              <TableCell>Consent Version IDs</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.cachedUserValidationSettings !== null ? (
              state.cachedUserValidationSettings.map((item: any) => (
                <TableRow
                  key={item.id}
                  className={
                    item.assignedDataControllerId !== undefined &&
                    item.assignedDataControllerId !== null
                      ? classes.isAssignedToDataController
                      : ""
                  }
                >
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell>{item.clientId}</TableCell>
                  <TableCell>{item.clientSecret}</TableCell>
                  <TableCell>{item.useAdminRealm ? "YES" : "NO"}</TableCell>
                  <TableCell>{item.validationUrl}</TableCell>
                  <TableCell>
                    {consentVersionsWithVendorAndApp !== null
                      ? renderConsentVersions(
                          consentVersionsWithVendorAndApp.filter((x) =>
                            item.consentVersionIds.includes(x.versionId)
                          )
                        )
                      : ""}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Edit setting">
                      <IconButton onClick={() => editSetting(item.id)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <DeleteIconButton
                      title="User Validation Setting"
                      onDelete={() => deleteSetting(item.id)}
                    >
                      {!item.deletable ? (
                        <>
                          <p>
                            This setting already has one or more consent
                            versions assigned, or is assigned globally to the
                            data controller.
                          </p>
                          <p>
                            <strong>
                              If you delete this setting the consent versions
                              and/or the data controller will not have any user
                              validation settings assigned.
                            </strong>
                          </p>
                        </>
                      ) : (
                        <>
                          Are you sure you want to delete the setting:{" "}
                          <strong>{item.id}</strong>
                        </>
                      )}
                    </DeleteIconButton>
                    {item.assignedDataControllerId != null ? (
                      <Tooltip title="Remove as active user validation setting on data controller">
                        <IconButton
                          onClick={() =>
                            onRemoveActiveSettingFromDataControllerClicked(
                              item.id
                            )
                          }
                        >
                          <StarIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Add as active user validation setting on data controller">
                        <IconButton
                          onClick={() => handleSetActiveSetting(item.id)}
                        >
                          <StarBorderIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7}>
                  Loading user validation settings...
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Hidden>
      <Hidden lgUp>
        <LinearProgress
          style={{ opacity: state.userValidationSettingsIsLoaded ? 0 : 1 }}
        />
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="stretch"
        >
          {state.cachedUserValidationSettings !== null ? (
            <>
              {state.cachedUserValidationSettings.map((item: any) => (
                <Grid
                  key={item.id}
                  item
                  xs={12}
                  sm={6}
                  className={classes.cardGrid}
                >
                  <Card
                    className={
                      item.assignedDataControllerId !== undefined &&
                      item.assignedDataControllerId !== null
                        ? `${classes.isAssignedToDataController} ${classes.card}`
                        : classes.card
                    }
                  >
                    <CardContent>
                      <Typography variant="body2" component="p">
                        <strong>Name:</strong> {item.name}
                        <br />
                        <strong>Client ID:</strong> {item.clientId}
                        <br />
                        <strong>Client Secret:</strong> {item.clientSecret}
                        <br />
                        <strong>Use Admin Realm:</strong>{" "}
                        {item.useAdminRealm ? "YES" : "NO"}
                        <br />
                        <strong>Consent Version IDs:</strong>
                      </Typography>
                      {consentVersionsWithVendorAndApp !== null
                        ? renderConsentVersions(
                            consentVersionsWithVendorAndApp.filter((x) =>
                              item.consentVersionIds.includes(x.versionId)
                            )
                          )
                        : null}
                    </CardContent>
                    <CardActions>
                      <Tooltip title="Edit setting">
                        <IconButton onClick={() => editSetting(item.id)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <DeleteIconButton
                        title="User Validation Setting"
                        onDelete={() => deleteSetting(item.id)}
                      >
                        {!item.deletable ? (
                          <>
                            <p>
                              This setting already has one or more consent
                              versions assigned, or is assigned globally to the
                              data controller.
                            </p>
                            <p>
                              <strong>
                                If you delete this setting the consent versions
                                and/or the data controller will not have any
                                user validation settings assigned.
                              </strong>
                            </p>
                          </>
                        ) : (
                          <>
                            Are you sure you want to delete the setting:{" "}
                            <strong>{item.id}</strong>
                          </>
                        )}
                      </DeleteIconButton>
                      {item.assignedDataControllerId != null ? (
                        <Tooltip title="Remove as active user validation setting on data controller">
                          <IconButton
                            onClick={() =>
                              onRemoveActiveSettingFromDataControllerClicked(
                                item.id
                              )
                            }
                          >
                            <StarIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Add as active user validation setting on data controller">
                          <IconButton
                            onClick={() => handleSetActiveSetting(item.id)}
                          >
                            <StarBorderIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </>
          ) : null}
        </Grid>
      </Hidden>
      <CreateSettingDialog
        key={state.newSettingNonce}
        isOpen={state.openCreateModal}
        onClose={handleCloseCreateModal}
        onSubmit={handleCreateSubmit}
      />
      {selectedSetting != null ? (
        <EditSettingDialog
          setting={selectedSetting}
          availableConsentVersionGroupings={availableConsentVersionGroupings}
          consentIdsAlreadyAssigned={state.assignedConsentVersionIds.filter(
            (x: any) => !selectedSetting.consentVersionIds.includes(x)
          )}
          isOpen={true}
          onClose={handleCloseEditModal}
          onSubmit={handleEditSubmit}
        />
      ) : null}
      <Dialog
        open={confirmRemoveSettingFromDataControllerOpen}
        onClose={() => setConfirmRemoveSettingFromDataControllerOpen(false)}
        aria-labelledby="remove-setting-dialog-title"
      >
        <DialogTitle id="remove-setting-dialog-title">
          Confirm removing setting from data controller
        </DialogTitle>
        <DialogContent>
          <p>
            If you remove the active setting on the data controller some consent
            versions might be left without a user validation setting.
          </p>
          <p>Are you sure you want to proceed?</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmRemoveSettingFromDataControllerOpen(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={onRemoveActiveSettingFromDataControllerConfirmed}
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={state.openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <SnackbarContent
          message={
            <>
              <ErrorIcon className={classes.snackbarErrorIcon} />
              <span>{`Something went wrong: "${state.errorMessage}"`}</span>
            </>
          }
          classes={{ root: classes.snackbarError }}
        />
      </Snackbar>
    </div>
  );
};

export default UserValidationSettingsOverview;
