import React from "react";
import { Box, Typography, Grid, TextField, Button } from "@material-ui/core";
import { HTTPHeaderDTO } from "../../../../../models/HTTPHeader";

interface Props {
  headerDTO: HTTPHeaderDTO;
  index: number;
  handleHeaderChanged: (value: HTTPHeaderDTO) => void;
  handleRemoveHeader: () => void;
}

const HTTPHeaderElement: React.FC<Props> = (props) => {
  return (
    <Box mt={2}>
      <Typography>Header {props.index + 1}</Typography>
      <Box mt={1}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={11}>
            <Grid container spacing={1}>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={props.headerDTO.name || ""}
                  onChange={(event) =>
                    props.handleHeaderChanged({
                      ...props.headerDTO,
                      name: event.target.value,
                    })
                  }
                  label="Name"
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={props.headerDTO.value || ""}
                  onChange={(event) =>
                    props.handleHeaderChanged({
                      ...props.headerDTO,
                      value: event.target.value,
                    })
                  }
                  label="Value"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Button fullWidth onClick={(event) => props.handleRemoveHeader()}>
              X
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HTTPHeaderElement;
