import React from "react";
import { IconButton, makeStyles, createStyles, Fade } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

interface Props {
  text: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    iconButton: {
      position: "absolute",
      top: "0.1em",
      right: "0.1em",
      zIndex: 100,
    },
    hidden: {
      display: "none",
    },
    div: {
      fontSize: 0,
    },
  })
);

type UserFeedbackState = "idle" | "error" | "success";

const TIMEOUT = 500;
const COPY_ICON_TIMEOUT = 200;

const CopyToClipboard: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [feedbackState, setFeedbackState] =
    React.useState<UserFeedbackState>("idle");
  const [statusIsFading, setStatusIsFading] = React.useState(false);

  async function copyClicked() {
    try {
      await navigator.clipboard.writeText(props.text);
      setFeedbackState("success");
    } catch (e) {
      setFeedbackState("error");
    }
    setStatusIsFading(true);
  }

  function finishedShowingStatus() {
    setFeedbackState("idle");
  }

  return (
    <IconButton
      className={classes.iconButton}
      onClick={copyClicked}
      disabled={feedbackState !== "idle"}
    >
      <div
        className={`${feedbackState !== "idle" ? classes.hidden : ""} ${
          classes.div
        }`}
      >
        <Fade in={feedbackState === "idle"} timeout={COPY_ICON_TIMEOUT}>
          <FileCopyIcon />
        </Fade>
      </div>
      <div
        className={`${feedbackState !== "error" ? classes.hidden : ""} ${
          classes.div
        }`}
      >
        <Fade
          in={feedbackState === "error" && statusIsFading}
          onEntered={() => setStatusIsFading(false)}
          onExited={finishedShowingStatus}
          timeout={TIMEOUT}
        >
          <ErrorIcon />
        </Fade>
      </div>
      <div
        className={`${feedbackState !== "success" ? classes.hidden : ""} ${
          classes.div
        }`}
      >
        <Fade
          in={feedbackState === "success" && statusIsFading}
          onEntered={() => setStatusIsFading(false)}
          onExited={finishedShowingStatus}
          timeout={TIMEOUT}
        >
          <CheckCircleIcon />
        </Fade>
      </div>
    </IconButton>
  );
};

export default CopyToClipboard;
