import * as React from "react";
import { Switch, RouteComponentProps, Route } from "react-router";
import UserOverview from "./pages/UserOverview";

const Users = (props: RouteComponentProps<{}>) => (
  <Switch>
    <Route path={props.match.path} exact component={UserOverview} />
  </Switch>
);

export default Users;
