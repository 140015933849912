import * as React from "react";
import { MutableRefObject, useEffect } from "react";
import {
  createStyles,
  FormControl,
  FormControlLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  Tooltip,
} from "@material-ui/core";
import { ServiceProviderAgreementCreatorSubmit } from "./ServiceProviderAgreementCreator";

interface Props {
  agreement: EditableServiceProviderAgreementDTO;
  onSubmit: MutableRefObject<ServiceProviderAgreementCreatorSubmit | undefined>;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    productionSwitch: {
      marginBottom: "15px",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

function certificateTypeDisplay(type?: CertificateType | "") {
  switch (type) {
    case "AZURE":
      return "Azure KeyVault";
    case "PKCS12":
      return "Upload Pkcs12";
    default:
      return "";
  }
}

const ServiceProviderAgreementInitialize = (props: Props) => {
  const classes = useStyles();
  const isEditing = !!(props.agreement as ServiceProviderAgreementDTO).id;
  const [isProduction, setProduction] = React.useState<boolean>(false);
  const [certificateType, setCertificateType] = React.useState<
    CertificateType | ""
  >("");

  useEffect(() => {
    // Propagate values from outside to this
    setProduction(!!props.agreement.production);
    setCertificateType(props.agreement.certificateType || "");
  }, [props.agreement.production, props.agreement.certificateType]);

  props.onSubmit.current = () => {
    // Save data if parent tells us to
    const result = Object.assign(props.agreement, {
      certificateType,
      production: isProduction,
    });
    return Promise.resolve(result as ServiceProviderAgreementDTO);
  };

  return (
    <div>
      <Tooltip title="Toggle on to mark this as a production service provider agreement. Toggle off if it's for test purposes. This can't be changed later.">
        <FormControl
          variant="outlined"
          className={classes.formControl}
          fullWidth={true}
        >
          <FormControlLabel
            className={classes.productionSwitch}
            control={
              <Switch
                disabled={isEditing}
                checked={isProduction}
                onChange={(event) => setProduction(event.target.checked)}
                name="isProduction"
                color="primary"
              />
            }
            label="Production"
          />
        </FormControl>
      </Tooltip>

      <Tooltip title="Select how you want to store your certificate: Either upload it directly to the Admin Center, or store it in an Azure KeyVault. This can't be changed later.">
        <FormControl
          variant="outlined"
          className={classes.formControl}
          fullWidth={true}
          required
        >
          <InputLabel id="selectCertificateTypeLabel">
            Certificate Type
          </InputLabel>
          <Select
            labelId="selectCertificateTypeLabel"
            id="selectCertificateType"
            disabled={isEditing}
            value={certificateType}
            onChange={(event) =>
              setCertificateType(event.target.value as CertificateType)
            }
            label="Certificate Type"
          >
            <MenuItem value={"PKCS12"}>
              {certificateTypeDisplay("PKCS12")}
            </MenuItem>
            <MenuItem value={"AZURE"}>
              {certificateTypeDisplay("AZURE")}
            </MenuItem>
          </Select>
        </FormControl>
      </Tooltip>
    </div>
  );
};

export default ServiceProviderAgreementInitialize;
