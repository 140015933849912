import * as React from "react";
import {
  Grid,
  makeStyles,
  createStyles,
  Typography,
  TextField,
  Paper,
} from "@material-ui/core";
import UserDetails from "./components/UserDetails";
import useDebounce from "../../../../utils/useDebounce";
import { RequirePermission } from "../../../../components/RequirePermission";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: "2.5rem 3rem 0.5rem",
    },
    titleContainer: {
      paddingBottom: "1.5rem",
    },
    title: {
      paddingRight: "1rem",
    },
    detailsContainer: {
      marginTop: "2rem",
      padding: "2rem",
      maxWidth: "1200px",
    },
  })
);

const UserOverview = () => {
  const classes = useStyles();
  const [userId, setUserId] = React.useState<string>("");

  const debouncedUserId = useDebounce(userId, 500);

  return (
    <div className={classes.container}>
      <RequirePermission permission="DATA_OWNERS_READ">
        <Grid
          container
          spacing={0}
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.titleContainer}
        >
          <Grid item>
            <Typography variant="h3" color="primary" className={classes.title}>
              Manage Users
            </Typography>
          </Grid>
        </Grid>

        <Typography>
          Enter User ID of user below to manage their consents.
        </Typography>

        <TextField
          label="User ID"
          type="search"
          margin="normal"
          variant="outlined"
          value={userId}
          onChange={(event) => setUserId(event.target.value)}
        />

        {debouncedUserId !== "" ? (
          <Paper elevation={4} className={classes.detailsContainer}>
            <UserDetails userId={debouncedUserId} />
          </Paper>
        ) : null}
      </RequirePermission>
    </div>
  );
};

export default UserOverview;
