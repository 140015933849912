import {
  Button,
  Container,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import React from "react";
import { useQuery, useQueryCache } from "react-query";
import { useHistory } from "react-router-dom";
import uuid from "uuid";
import { useAxios } from "../../../api/useAxios";
import ConfirmDialog from "../../../components/ConfirmDialog";
import EditConsentTypeDialog from "../../../components/EditConsentTypeDialog";
import IconText from "../../../components/IconText";
import { EditConsentTypeGroupDTO } from "../../../models/ConsentTypeGroups";
import { DashboardConsentTypeGroupDTO } from "../../../models/Dashboard";
import ContextMenu, {
  ContextMenuPlacementState,
  MenuAction,
  MouseState,
} from "./ContextMenu";
import CreateConsentTypeDialog from "./CreateConsentTypeDialog";
import CreateGroupDialog from "./CreateGroupDialog";
import EditGroupDialog from "./EditGroupDialog";
import FolderView from "./FolderView";

const useStyles = makeStyles((theme) =>
  createStyles({
    groupContainer: {
      padding: "2.5rem 3rem 0",
    },
    tableContainerItem: {
      paddingBottom: theme.spacing(2),
    },
    folderContainer: {
      padding: "1em 0 10em",
    },
  })
);

type DialogOpen =
  | "none"
  | "group"
  | "consent-type"
  | "edit-consent-type"
  | "edit-group"
  | "delete";
type DeleteState = "none" | "group" | "consent-type";
const DASHBOARD_GROUPS_KEY = "dashboardConsentTypeGroups";
const DASHBOARD_TYPES_KEY = "dashboardConsentTypes";

const ConsentTypeBrowser = () => {
  const classes = useStyles();
  const axios = useAxios();
  const history = useHistory();
  const queryCache = useQueryCache();

  const [activeGroupId, setActiveGroupId] = React.useState<string | null>(null);
  const [newConsentTypeGroupNonce, setNewConsentTypeGroupNonce] =
    React.useState(uuid());
  const [dialogOpen, setDialogOpen] = React.useState<DialogOpen>("none");
  const [mouseState, setMouseState] = React.useState<MouseState>({
    mouseX: null,
    mouseY: null,
  });
  const [currentlyEditingConsentTypeId, setCurrentlyEditingConsentTypeId] =
    React.useState<string | null>(null);
  const [currentlyEditingGroupId, setCurrentlyEditingGroupId] = React.useState<
    string | null
  >(null);
  const [contextMenuPlacementState, setContextMenuPlacementState] =
    React.useState<ContextMenuPlacementState>("clear");
  const [deleteState, setDeleteState] = React.useState<DeleteState>("none");

  const { data: consentTypeGroups, status: consentTypeGroupsStatus } = useQuery(
    DASHBOARD_GROUPS_KEY,
    fetchConsentTypeGroups
  );

  const { data: consentTypes, status: consentTypesStatus } = useQuery(
    [DASHBOARD_TYPES_KEY, activeGroupId || ""],
    fetchConsentTypes
  );

  const canDelete = React.useMemo(() => {
    if (consentTypeGroups === undefined || currentlyEditingGroupId === null) {
      return true;
    }

    if (contextMenuPlacementState === "group") {
      const group = consentTypeGroups.find(
        (x) => x.id === currentlyEditingGroupId
      );
      if (group === undefined || group.consentTypeCount === 0) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  }, [contextMenuPlacementState, currentlyEditingGroupId, consentTypeGroups]);

  const currentlyEditingConsentType = React.useMemo<ConsentTypeDTO>(() => {
    const defaultConsentType: ConsentTypeDTO = {
      clientId: "",
      id: "-1",
      name: "",
      groupId: "",
    };
    if (consentTypes === undefined || currentlyEditingConsentTypeId === null) {
      return defaultConsentType;
    }

    const consentTypeDetails = consentTypes.find(
      (x) => x.id === currentlyEditingConsentTypeId
    );
    if (consentTypeDetails === undefined) {
      return defaultConsentType;
    }

    return {
      id: consentTypeDetails.id,
      name: consentTypeDetails.name,
      groupId: "",
      clientId: consentTypeDetails.clientId,
    };
  }, [currentlyEditingConsentTypeId, consentTypes]);

  const currentlyEditingGroup = React.useMemo<ConsentTypeGroupDTO>(() => {
    const defaultGroup: ConsentTypeGroupDTO = {
      cvr: "",
      id: "-1",
      name: "",
    };

    if (consentTypeGroups === undefined || currentlyEditingGroupId === null) {
      return defaultGroup;
    }

    const groupDetails = consentTypeGroups.find(
      (x) => x.id === currentlyEditingGroupId
    );
    if (groupDetails === undefined) {
      return defaultGroup;
    }

    return {
      id: groupDetails.id,
      name: groupDetails.name,
      cvr: groupDetails.cvr,
    };
  }, [currentlyEditingGroupId, consentTypeGroups]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setContextMenuPlacementState("clear");
    setMouseState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  function handleItemContextMenu(
    event: React.MouseEvent<HTMLDivElement>,
    isFolder: boolean,
    id: string
  ) {
    event.preventDefault();
    event.stopPropagation();
    setContextMenuPlacementState(isFolder ? "group" : "consent-type");
    if (isFolder) {
      setCurrentlyEditingGroupId(id);
    } else {
      setCurrentlyEditingConsentTypeId(id);
    }
    setMouseState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  }

  async function fetchConsentTypes(
    key: string,
    groupId: string
  ): Promise<ConsentTypeGroupDetailsConsentTypeDTO[] | undefined> {
    if (groupId === "") {
      const result = await axios.get<
        PagedDTO<ConsentTypeGroupDetailsConsentTypeDTO>
      >(`/consentTypes?size=60&page=0&withoutGroup=true`);
      return result.data.data;
    }

    const result = await axios.get<
      PagedDTO<ConsentTypeGroupDetailsConsentTypeDTO>
    >(`/consentTypeGroups/${activeGroupId}/consentTypes?size=60&page=0`);
    return result.data.data;
  }

  async function fetchConsentTypeGroups() {
    const groupResult = await axios.get<DashboardConsentTypeGroupDTO[]>(
      "/dashboard/consentTypeGroups"
    );
    return groupResult.data;
  }

  function handleTypeClicked(id: string) {
    history.push(`/consentTypes/${id}`);
  }

  function handleOpenModal(dialogMode?: DialogOpen) {
    let dialogValue: DialogOpen =
      activeGroupId === null ? "group" : "consent-type";
    if (dialogMode) {
      dialogValue = dialogMode;
    }
    setDialogOpen(dialogValue);
  }

  function handleCloseModal() {
    setDialogOpen("none");
    setNewConsentTypeGroupNonce(uuid());
  }

  function handleGroupSelected(id: string) {
    setActiveGroupId(id);
  }

  function handleGoBack() {
    setActiveGroupId(null);
  }

  function handleMenuAction(action: MenuAction) {
    setMouseState({ mouseX: null, mouseY: null });
    switch (action) {
      case "create-consent-type":
        handleOpenModal("consent-type");
        break;
      case "create-group":
        handleOpenModal("group");
        break;
      case "edit-consent-type":
        handleOpenModal("edit-consent-type");
        break;
      case "edit-group":
        handleOpenModal("edit-group");
        break;
      case "delete-consent-type":
        setDeleteState("consent-type");
        handleOpenModal("delete");
        break;
      case "delete-group":
        setDeleteState("group");
        handleOpenModal("delete");
        break;
    }
  }

  async function deleteConsentType() {
    if (currentlyEditingConsentTypeId === null) {
      return Promise.resolve();
    }

    try {
      await axios.delete(`/consentTypes/${currentlyEditingConsentTypeId}`);
      queryCache.invalidateQueries(DASHBOARD_TYPES_KEY);
    } catch (e) {
      console.warn("Consent Type deletion failed", e);
    }
  }

  async function deleteGroup() {
    if (currentlyEditingGroupId === null) {
      return Promise.resolve();
    }

    try {
      await axios.delete(`/consentTypeGroups/${currentlyEditingGroupId}`);
      queryCache.invalidateQueries(DASHBOARD_GROUPS_KEY);
    } catch (e) {
      console.warn("Group deletion failed", e);
    }
  }

  async function handleDelete() {
    if (deleteState === "group") {
      await deleteGroup();
    } else if (deleteState === "consent-type") {
      await deleteConsentType();
    }

    handleCloseModal();
  }

  function handleMenuClose() {
    setMouseState({ mouseX: null, mouseY: null });
  }

  async function handleCreateGroupSubmit(
    createConsentTypeGroupDTO: CreateConsentTypeGroupDTO
  ) {
    handleCloseModal();
    await axios.post("/consentTypeGroups", createConsentTypeGroupDTO);
    queryCache.invalidateQueries("dashboardConsentTypeGroups");
  }

  async function handleCreateConsentTypeSubmit(
    createConsentTypeDTO: CreateConsentTypeDTO
  ) {
    handleCloseModal();

    if (activeGroupId === null) {
      await axios.post(`/consentTypes`, createConsentTypeDTO);
    } else {
      await axios.post(
        `/consentTypeGroups/${activeGroupId}/consentTypes`,
        createConsentTypeDTO
      );
    }

    queryCache.invalidateQueries(DASHBOARD_TYPES_KEY);
  }

  async function handleEditConsentTypeGroupSubmit(
    editConsentTypeGroupDTO: EditConsentTypeGroupDTO
  ) {
    handleCloseModal();
    await axios.put(
      `/consentTypeGroups/${currentlyEditingGroupId}`,
      editConsentTypeGroupDTO
    );
    queryCache.invalidateQueries(DASHBOARD_GROUPS_KEY);
  }

  function handleEditConsentTypeSuccess() {
    handleCloseModal();
    queryCache.invalidateQueries(DASHBOARD_TYPES_KEY);
  }

  return (
    <Container maxWidth="xl" className={classes.groupContainer}>
      <Grid container>
        <Grid
          item
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
          className={classes.tableContainerItem}
        >
          <Grid item>
            <Typography variant="h3">Consent Types</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenModal()}
            >
              <IconText icon={AddIcon}>
                {activeGroupId === null ? "New group" : "New consent type"}
              </IconText>
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          container
          className={classes.folderContainer}
          alignItems="center"
          justify="flex-start"
          spacing={3}
          onContextMenu={handleClick}
        >
          <FolderView
            groups={consentTypeGroups}
            onMenu={handleItemContextMenu}
            consentTypes={consentTypes}
            groupsStatus={consentTypeGroupsStatus}
            consentTypesStatus={consentTypesStatus}
            isInGroup={activeGroupId !== null}
            onGroupSelected={handleGroupSelected}
            onGoBack={handleGoBack}
            onTypeClicked={handleTypeClicked}
          />
        </Grid>
      </Grid>
      <CreateGroupDialog
        key={newConsentTypeGroupNonce}
        isOpen={dialogOpen === "group"}
        onClose={handleCloseModal}
        onSubmit={handleCreateGroupSubmit}
      />
      <CreateConsentTypeDialog
        isOpen={dialogOpen === "consent-type"}
        onClose={handleCloseModal}
        onSubmit={handleCreateConsentTypeSubmit}
      />
      <EditConsentTypeDialog
        consentType={currentlyEditingConsentType}
        isOpen={dialogOpen === "edit-consent-type"}
        onCancel={handleCloseModal}
        onSuccess={handleEditConsentTypeSuccess}
      />
      <EditGroupDialog
        group={currentlyEditingGroup}
        isOpen={dialogOpen === "edit-group"}
        onClose={handleCloseModal}
        onSubmit={handleEditConsentTypeGroupSubmit}
      />
      <ConfirmDialog
        open={dialogOpen === "delete"}
        title={deleteState === "group" ? "Group" : "Consent Type"}
        onClose={handleCloseModal}
        onDelete={handleDelete}
      >
        Are you sure you want to delete the{" "}
        {deleteState === "group" ? "group" : "consent type"}?
      </ConfirmDialog>
      <ContextMenu
        mouseState={mouseState}
        canDelete={canDelete}
        contextMenuPlacementState={contextMenuPlacementState}
        activeGroupId={activeGroupId}
        onMenuAction={handleMenuAction}
        onMenuClose={handleMenuClose}
      />
    </Container>
  );
};

export default ConsentTypeBrowser;
