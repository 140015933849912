import { TextField, Tooltip } from "@material-ui/core";
import * as React from "react";
import { MutableRefObject, useEffect } from "react";
import { useAxios } from "../../../../../../api/useAxios";
import { ServiceProviderAgreementCreatorSubmit } from "./ServiceProviderAgreementCreator";

interface Props {
  agreement: EditableServiceProviderAgreementDTO;
  onSubmit: MutableRefObject<ServiceProviderAgreementCreatorSubmit | undefined>;
}

const ServiceProviderAgreementDetails = (props: Props) => {
  const axios = useAxios();

  const [name, setName] = React.useState<string>("");
  const [serviceProviderIdForRid, setServiceProviderIdForRid] =
    React.useState<string>("");
  const [serviceProviderIdForPid, setServiceProviderIdForPid] =
    React.useState<string>("");
  const [logonto, setLogonto] = React.useState<string>("");
  const [isProduction, setProduction] = React.useState<boolean>(false);
  const isEditing = !!(props.agreement as ServiceProviderAgreementDTO).id;

  useEffect(() => {
    // Propagate values from outside to this
    setName(props.agreement.name || "");
    setServiceProviderIdForRid(props.agreement.ridServiceProviderId || "");
    setServiceProviderIdForPid(props.agreement.pidServiceProviderId || "");
    setLogonto(props.agreement.logOnTo || "");
    setProduction(!!props.agreement.production);
  }, [
    props.agreement.name,
    props.agreement.ridServiceProviderId,
    props.agreement.pidServiceProviderId,
    props.agreement.logOnTo,
    props.agreement.production,
  ]);

  props.onSubmit.current = () => {
    // Save data if parent tells us to
    const result = Object.assign(props.agreement, {
      name,
      pidServiceProviderId: serviceProviderIdForPid,
      ridServiceProviderId: serviceProviderIdForRid,
      production: isProduction,
      logOnTo: logonto,
    } as EditableServiceProviderAgreementDTO);
    const id = (props.agreement as ServiceProviderAgreementDTO).id || "";
    return axios[id ? "put" : "post"](
      `/nem-id/service-provider-agreements${id ? "/" + id : ""}`,
      result
    ).then((response) => {
      return response.data;
    });
  };

  return (
    <div>
      {isEditing ? (
        <Tooltip title="Use this API key in requests to the NemID server in your clients">
          <TextField
            label="Api key"
            fullWidth={true}
            inputProps={{
              readOnly: true,
              disabled: true,
            }}
            type="text"
            margin="normal"
            variant="outlined"
            value={(props.agreement as ServiceProviderAgreementDTO).apiKey}
          />
        </Tooltip>
      ) : null}

      <TextField
        label="Name"
        autoFocus
        fullWidth={true}
        type="text"
        required={true}
        margin="normal"
        variant="outlined"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
      <TextField
        label="LogOnTo Name"
        fullWidth={true}
        type="text"
        required={true}
        margin="normal"
        variant="outlined"
        value={logonto}
        onChange={(event) => setLogonto(event.target.value)}
      />
      <TextField
        label="Service Provider Id for PID"
        type="text"
        fullWidth={true}
        margin="normal"
        variant="outlined"
        value={serviceProviderIdForPid}
        onChange={(event) => setServiceProviderIdForPid(event.target.value)}
      />
      <TextField
        label="Service Provider Id for RID"
        type="text"
        fullWidth={true}
        margin="normal"
        variant="outlined"
        value={serviceProviderIdForRid}
        onChange={(event) => setServiceProviderIdForRid(event.target.value)}
      />
    </div>
  );
};

export default ServiceProviderAgreementDetails;
