import React from "react";
import styled from "styled-components/macro";

const NotFoundText = styled.h1`
  text-align: center;
`;

const NotFound = () => {
  return (
    <NotFoundText>
      <h1>404</h1>
      <h3>
        <i>This is not the page you are looking for</i>
      </h3>
    </NotFoundText>
  );
};

export default NotFound;
