import { queryCache, useMutation, useQuery } from "react-query";
import { useAxios } from "./useAxios";

export const useApiKeys = () => {
  const axios = useAxios();

  return useQuery(["apiKeys"], async () => {
    const { data } = await axios.get<ApiKeyBareDTO[]>("/apiKeys");
    return data.map<ApiKeyBareDTO>((x) => ({
      ...x,
      createdAt: new Date(x.createdAt),
    }));
  });
};

export const useCreateApiKey = () => {
  const axios = useAxios();
  return useMutation(
    async (dto: CreateApiKeyDTO): Promise<ApiKeyFullDTO> => {
      const { data } = await axios.post<ApiKeyFullDTO>("/apiKeys", dto);
      return { ...data, createdAt: new Date(data.createdAt) };
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["apiKeys"]);
      },
    }
  );
};

export const useUpdateApiKey = () => {
  const axios = useAxios();
  return useMutation(
    async (input: { id: string; dto: UpdateApiKeyDTO }) => {
      await axios.put(`/apiKeys/${input.id}`, input.dto);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["apiKeys"]);
      },
    }
  );
};

export const useDeleteApiKey = () => {
  const axios = useAxios();
  return useMutation(
    async (id: string) => {
      await axios.delete(`/apiKeys/${id}`);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["apiKeys"]);
      },
    }
  );
};
