import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { queryCache, useMutation, useQuery } from "react-query";
import { useAxios } from "../../../../../api/useAxios";
import {
  CreateWebhookDTO,
  UpdateWebhookDTO,
  WebhookDTO,
} from "../../../../../models/Webhook";
import CreateOrUpdateWebhookDialog from "./CreateOrUpdateWebhookDialog";
import WebhookTable from "./WebhookTable";

const useStyles = makeStyles((theme) =>
  createStyles({
    errorText: {
      color: theme.palette.error.main,
    },
  })
);

export interface WebhookMutateVariables {
  dto: CreateWebhookDTO | UpdateWebhookDTO;
  webhookId?: string;
}

const WebhooksOverview: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [webhookDialogOpen, setWebhookDialogOpen] = React.useState(false);
  const {
    status: webhookStatus,
    data: webhooks,
    error: webhookError,
  } = useQuery("webhooks", fetchWebhooks);
  const axios = useAxios();
  const classes = useStyles();
  const [currentEditingWebhook, setCurrentEditingWebhook] = React.useState<
    WebhookDTO | undefined
  >(undefined);
  const [mutate] = useMutation(createOrUpdateWebhook);
  const [deleteMutate] = useMutation(deleteWebhook);
  const [refreshTokenMutate] = useMutation(refreshWebhookToken);

  function handleWebhookEdit(webhookId: string) {
    const webhook = webhooks?.find((x) => x.id === webhookId);
    console.log("webhook editing", webhook);
    setCurrentEditingWebhook(webhook);
    setWebhookDialogOpen(true);
  }

  async function handleWebhookDelete(webhookId: string) {
    await deleteMutate(webhookId);
  }

  async function handleWebhookDialogClose(
    data?: CreateWebhookDTO | UpdateWebhookDTO,
    webhookId?: string
  ) {
    if (data) {
      await mutate({ dto: data, webhookId });
    }

    setWebhookDialogOpen(false);
  }

  async function handleWebhookTokenRefresh(webhookId: string) {
    await refreshTokenMutate(webhookId);
  }

  async function fetchWebhooks(): Promise<WebhookDTO[]> {
    const result = await axios.get<WebhookDTO[]>("/webhooks");
    setIsLoading(false);
    return result.data.map((x) => {
      return { ...x, lastUpdated: new Date(x.lastUpdated) };
    });
  }

  async function createOrUpdateWebhook(data: WebhookMutateVariables) {
    setIsLoading(true);
    if (data.webhookId) {
      try {
        await axios.put<UpdateWebhookDTO>(
          `/webhooks/${data.webhookId}`,
          data.dto
        );
        queryCache.invalidateQueries("webhooks");
      } catch (e) {
        console.error("PUT error", e);
      }
    } else {
      try {
        await axios.post<CreateWebhookDTO>("/webhooks", data.dto);
        queryCache.invalidateQueries("webhooks");
      } catch (e) {
        console.error("POST error", e);
      }
    }
  }

  async function refreshWebhookToken(webhookId: string) {
    setIsLoading(true);
    try {
      await axios.post(`/webhooks/${webhookId}/refreshToken`);
      queryCache.invalidateQueries("webhooks");
    } catch (e) {
      console.error("POST error", e);
    }
  }

  async function deleteWebhook(webhookId: string) {
    setIsLoading(true);
    try {
      await axios.delete(`/webhooks/${webhookId}`);
      queryCache.invalidateQueries("webhooks");
    } catch (e) {
      console.error("DELETE error", e);
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography>
            Here you can add webhooks that will send data to your defined
            endpoints when events happen.
          </Typography>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setWebhookDialogOpen(true)}
            >
              Add webhook
            </Button>
          </Box>
        </Grid>
        {isLoading && (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        )}
        <Grid item xs={12}>
          <WebhookOverviewStatus
            errorTextClassName={classes.errorText}
            handleWebhookDelete={handleWebhookDelete}
            handleWebhookEdit={handleWebhookEdit}
            handleWebhookTokenRefresh={handleWebhookTokenRefresh}
            webhookError={webhookError}
            webhookStatus={webhookStatus}
            webhooks={webhooks}
          />
        </Grid>
      </Grid>
      <CreateOrUpdateWebhookDialog
        webhookData={currentEditingWebhook}
        isOpen={webhookDialogOpen}
        onClose={handleWebhookDialogClose}
      />
    </>
  );
};

interface StatusProps {
  webhookStatus: "loading" | "error" | "success" | "idle";
  webhookError: unknown;
  webhooks: WebhookDTO[] | undefined;
  handleWebhookTokenRefresh: (webhookId: string) => void;
  handleWebhookDelete: (webhookId: string) => void;
  handleWebhookEdit: (webhookId: string) => void;
  errorTextClassName: string;
}

const WebhookOverviewStatus: React.FC<StatusProps> = ({
  webhookStatus,
  webhookError,
  webhooks,
  handleWebhookTokenRefresh,
  handleWebhookDelete,
  handleWebhookEdit,
  errorTextClassName,
}) => {
  if (webhookStatus === "loading") {
    return <Typography>Loading webhooks...</Typography>;
  }

  if (webhookStatus === "error") {
    return (
      <Typography className={errorTextClassName}>
        An error has occurred:{" "}
        {webhookError != null && webhookError instanceof Error
          ? webhookError.message
          : "Unknown error"}
      </Typography>
    );
  }

  if (
    webhookStatus === "success" &&
    webhooks !== undefined &&
    webhooks.length === 0
  ) {
    return (
      <Typography>
        You currently have no webhooks. Click the button above to create one.
      </Typography>
    );
  }

  if (webhooks === undefined) {
    return (
      <Typography className={errorTextClassName}>
        Returned webhooks are undefined.
      </Typography>
    );
  }

  return (
    <WebhookTable
      handleTokenRefresh={handleWebhookTokenRefresh}
      handleDelete={handleWebhookDelete}
      handleEdit={handleWebhookEdit}
      webhooks={webhooks}
    />
  );
};

export default WebhooksOverview;
