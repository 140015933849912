import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { Save as SaveIcon } from "@material-ui/icons";
import * as React from "react";
import { usePaginatedQuery } from "react-query";
import styled from "styled-components/macro";
import { useAxios } from "../../../../../api/useAxios";
import IconText from "../../../../../components/IconText";
import Rows from "../../../../../components/Rows";
import { PagedDTO } from "../../../../../models/Paged";
import { ScopeDTO } from "../../../../../models/Scopes";

const Loading = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
`;

interface Props {
  consentVersionId: string;
  currentScopeIds: string[];
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}

const EditScopes = ({
  consentVersionId,
  currentScopeIds,
  isOpen,
  onCancel,
  onSuccess,
}: Props) => {
  const [currentPage, setPage] = React.useState(0);
  const currentPageSize = 20;
  const scopesStatus = usePaginatedQuery(
    ["scopes", currentPage, currentPageSize],
    fetchScopes
  );

  const [selectedScopeIds, setSelectedScopeIds] =
    React.useState<string[]>(currentScopeIds);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const axios = useAxios();

  const isLoading = isSubmitting || scopesStatus.status === "loading";

  async function fetchScopes(key: string, page = 0, pageSize = 20) {
    const result = await axios.get<PagedDTO<ScopeDTO>>(
      `/scopes?page=${page}&size=${pageSize}`
    );
    return result.data;
  }

  function onSubmit(event: React.FormEvent) {
    event.preventDefault();

    setIsSubmitting(true);
    axios
      .put(`/consentVersions/${consentVersionId}/scopes`, selectedScopeIds)
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        // TODO: Show error
        setIsSubmitting(false);
      });
  }

  function isSelected(scopeId: string) {
    return selectedScopeIds.includes(scopeId);
  }

  function clickedScopeId(scopeId: string) {
    if (isSelected(scopeId)) {
      setSelectedScopeIds((currentIds) =>
        currentIds.filter((id) => id !== scopeId)
      );
    } else {
      setSelectedScopeIds((currentIds) => [...currentIds, scopeId]);
    }
  }

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Edit Scopes</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit} id="edit-scopes-form">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Note</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Rows
                colSpan={3}
                dataApiState={scopesStatus}
                data={
                  scopesStatus.resolvedData && scopesStatus.resolvedData.data
                }
                fetchingText="We are fetching the scopes, please wait..."
                noDataText=""
                errorText=""
                rowRender={(item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={isSelected(item.id)}
                        onChange={() => clickedScopeId(item.id)}
                      />
                    </TableCell>
                  </TableRow>
                )}
              />
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={
              (scopesStatus.resolvedData &&
                scopesStatus.resolvedData.totalItems) ||
              0
            }
            rowsPerPage={currentPageSize}
            page={currentPage}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onPageChange={handleChangePage}
          />
        </form>
        <Fade
          in={isLoading}
          style={{
            transitionDelay: isLoading ? "800ms" : "0ms",
          }}
          unmountOnExit
        >
          <Loading size={50} />
        </Fade>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button
          type="submit"
          form="edit-scopes-form"
          disabled={isLoading}
          onClick={onSubmit}
          color="primary"
          variant="contained"
        >
          <IconText icon={SaveIcon} fontSize="small">
            Save
          </IconText>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditScopes;
