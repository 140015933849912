import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useDataController } from "../../auth/DataControllerContext";
import { DataControllerDTO } from "../../models/Datacontrollers";
import { useAuth } from "../../auth/AuthContext";

export default function DataControllerSelect() {
  const { dataControllers, selectedDataController, selectDataController } =
    useDataController();
  const { updatePermissions } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    dataController: DataControllerDTO
  ) => {
    selectDataController(dataController);
    setAnchorEl(null);
    updatePermissions();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List component="nav" aria-label="Active Datacontroller">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          onClick={handleClickListItem}
        >
          <ListItemText
            primary="Datacontroller"
            secondary={selectedDataController?.displayName}
          />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {dataControllers.map((option) => (
          <MenuItem
            key={option.id}
            selected={option === selectedDataController}
            onClick={(event) => handleMenuItemClick(event, option)}
          >
            {option.displayName}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
