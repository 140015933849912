import { CssBaseline } from "@material-ui/core";
import { blueGrey, deepOrange } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import ReactDOM from "react-dom";
import { createGlobalStyle } from "styled-components/macro";
import App from "./App";
import { AuthProvider } from "./auth/AuthContext";
import { DataControllerProvider } from "./auth/DataControllerContext";
import "./index.css";
import { windowProcess } from "./processUtils";
import * as serviceWorker from "./serviceWorker";

document.title = `${windowProcess.env.pageTitle || "Trifork"} Admin Center`;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  #root {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

const muiTheme = createTheme({
  palette: {
    primary: deepOrange,
    secondary: blueGrey,
  },
});

ReactDOM.render(
  <ThemeProvider theme={muiTheme}>
    <DataControllerProvider>
      <AuthProvider>
        <CssBaseline />
        <GlobalStyle />
        <App />
      </AuthProvider>
    </DataControllerProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
