import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Assignment, Close, Settings } from "@material-ui/icons";
import * as React from "react";
import { RefObject, useEffect } from "react";
import { useAxios } from "../../../../../../api/useAxios";
import DeleteButton from "../../../../../../components/DeleteButton";
import { Timestamps } from "../Timestamps";
import { ProgressBar } from "../progressbar/ProgressBar";
import { ProgressBarItem } from "../progressbar/ProgressBarItem";
import ServiceProviderAgreementDetails from "./ServiceProviderAgreementDetails";
import ServiceProviderAgreementInitialize from "./ServiceProviderAgreementInitialize";
import ServiceProviderAgreementSecurity from "./ServiceProviderAgreementSecurity";
import ServiceProviderAgreementValidation from "./ServiceProviderAgreementValidation";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: "2.5rem 3rem 0.5rem",
    },
    titleContainer: {
      paddingBottom: "1.5rem",
    },
    title: {
      paddingRight: "1rem",
    },
    detailsContainer: {
      marginTop: "2rem",
      padding: "2rem",
      maxWidth: "1200px",
    },
    saveButton: {
      marginLeft: "1.2rem",
    },
    closeButton: {
      float: "right",
    },
    form: {
      height: "500px",
    },
  })
);

interface Props {
  isOpen: boolean;
  onClose: () => any;
  afterSubmit: () => any;
  id?: string;
}

enum State {
  INITIAL,
  DETAILS,
  SECURITY,
  VALIDATION,
}

export type ServiceProviderAgreementCreatorSubmit =
  () => Promise<ServiceProviderAgreementDTO>;

const ServiceProviderAgreementCreator = (props: Props) => {
  const axios = useAxios();
  const classes = useStyles();
  const [state, setState] = React.useState<State>(State.INITIAL);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [agreement, setAgreement] = React.useState<ServiceProviderAgreementDTO>(
    {} as ServiceProviderAgreementDTO
  );
  const [certificate, setCertificate] = React.useState<
    | ServiceProviderAgreementKeyvaultConfigurationDTO
    | ServiceProviderAgreementSecurityConfigurationDTO
  >({} as ServiceProviderAgreementKeyvaultConfigurationDTO);
  const form = React.useRef() as RefObject<HTMLFormElement>;
  const doSubmit = React.useRef<ServiceProviderAgreementCreatorSubmit>();

  const isEditing = !!agreement.id;

  const saveButtonText = React.useMemo(() => {
    switch (state) {
      case State.INITIAL:
        return "Continue";
      case State.VALIDATION:
        return "Done";
      default:
        return "Save and Continue";
    }
  }, [state]);

  useEffect(() => {
    // Fires when we open/close the dialog
    const fetchData = async () => {
      const result = await axios.get<ServiceProviderAgreementDTO>(
        `/nem-id/service-provider-agreements/${props.id}`
      );
      setAgreement(result.data);
      setState(State.DETAILS);
    };
    setState(State.INITIAL);
    if (props.id && props.isOpen) {
      fetchData();
    } else {
      setAgreement({} as ServiceProviderAgreementDTO);
    }
  }, [props.isOpen, props.id, axios]);

  function save() {
    if (!form.current?.reportValidity()) {
      return;
    }
    setIsSubmitting(true);
    doSubmit
      .current?.()
      .then(nextStep)
      .finally(() => setIsSubmitting(false));
  }

  function nextStep(updatedAgreement?: ServiceProviderAgreementDTO) {
    setIsSubmitting(false);
    if (!updatedAgreement) {
      return;
    }
    setAgreement(updatedAgreement);
    if (state === State.VALIDATION) {
      return props.afterSubmit();
    }
    setState(state + 1);
  }

  function deleteAgreement(): Promise<any> {
    return axios
      .delete(`/nem-id/service-provider-agreements/${props.id!}`)
      .finally(() => props.afterSubmit());
  }

  function previousStep(): void {
    setState(state - 1);
  }

  function getFormContent(): any {
    switch (state) {
      case State.INITIAL:
        return (
          <ServiceProviderAgreementInitialize
            agreement={agreement}
            onSubmit={doSubmit}
          />
        );
      case State.DETAILS:
        return (
          <ServiceProviderAgreementDetails
            agreement={agreement}
            onSubmit={doSubmit}
          />
        );
      case State.SECURITY:
        return (
          <ServiceProviderAgreementSecurity
            agreement={agreement}
            onSubmit={doSubmit}
            certificate={certificate}
            onCertificateUpdate={setCertificate}
          />
        );
      case State.VALIDATION:
        return (
          <ServiceProviderAgreementValidation
            agreement={agreement}
            onSubmit={doSubmit}
          />
        );
      default:
        return `ERROR: Unknown flow state ${state}`;
    }
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth="lg"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {props.id ? "Edit" : "Create"} Service Provider Agreement
        <IconButton onClick={props.onClose} className={classes.closeButton}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ProgressBar>
          <ProgressBarItem
            isActive={true}
            text="Initial"
            onClick={isEditing ? setState : undefined}
            value={State.INITIAL}
          >
            <Settings />
          </ProgressBarItem>
          <ProgressBarItem
            isActive={state >= State.DETAILS}
            text="Details"
            onClick={isEditing ? setState : undefined}
            value={State.DETAILS}
          >
            <Assignment />
          </ProgressBarItem>
          <ProgressBarItem
            isActive={state >= State.SECURITY}
            text="Security"
            onClick={isEditing ? setState : undefined}
            value={State.SECURITY}
          >
            <Settings />
          </ProgressBarItem>
          <ProgressBarItem
            isActive={state >= State.VALIDATION}
            text="Validation"
            onClick={isEditing ? setState : undefined}
            value={State.VALIDATION}
          >
            <Settings />
          </ProgressBarItem>
        </ProgressBar>
        <form ref={form} className={classes.form}>
          {/*The form content is dynamically loaded for each step in the create flow*/}
          {getFormContent()}
        </form>
      </DialogContent>
      <DialogActions>
        <Timestamps agreement={agreement} />

        {isEditing ? (
          <DeleteButton
            title="Service Provider Agreement"
            buttonText="Delete"
            children="Are you sure you want to delete this service provider agreement?"
            onDelete={deleteAgreement}
            variant="contained"
          />
        ) : null}
        {state >= State.DETAILS ? (
          <Button
            variant="contained"
            type="submit"
            onClick={previousStep}
            className={classes.saveButton}
          >
            Back
          </Button>
        ) : null}
        <Button
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          type="submit"
          onClick={save}
          className={classes.saveButton}
        >
          {saveButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceProviderAgreementCreator;
