import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import { CreateScopeDTO } from "../../../../../models/Scopes";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (createScopeDTO: CreateScopeDTO) => void;
}

const CreateScopeDialog = ({ isOpen, onClose, onSubmit }: Props) => {
  const [name, setName] = React.useState<string | null>(null);
  const [description, setDescription] = React.useState<string | null>(null);
  function submit() {
    if (name == null || name === "") {
      // TODO: Show error
      return;
    }

    onSubmit({ name, description: description || "" });
  }

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">New Scope</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          margin="dense"
          id="description"
          label="Description"
          fullWidth
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button type="submit" onClick={submit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateScopeDialog;
