import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add, Check } from "@material-ui/icons";
import * as React from "react";
import { useQuery } from "react-query";
import { useAxios } from "../../../../../../api/useAxios";
import { useAuth } from "../../../../../../auth/AuthContext";
import ClickableTableRow from "../../../../../../components/ClickableTableRow";
import { RequirePermission } from "../../../../../../components/RequirePermission";
import Rows from "../../../../../../components/Rows";
import CreateUserDialog from "./CreateUserDialog";
import { EditUserDialog } from "./EditUserDialog";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      paddingRight: "1rem",
    },
    detailsContainer: {
      marginTop: "2rem",
      padding: "2rem",
      width: "100%",
    },
    headerButton: {
      float: "right",
    },
  })
);

export const ManageUsers = () => {
  const axios = useAxios();
  const { permissions } = useAuth();
  const classes = useStyles();
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState<string>("");
  const usersState = useQuery(["users", new Date().getHours()], fetchUsers);
  const { data: users, refetch: refreshUsers } = usersState;

  async function fetchUsers() {
    const response = await axios.get<UserDTO[]>(`/users`);
    return response.data;
  }

  function create(): void {
    setOpenCreateDialog(true);
  }

  function edit(id: string): void {
    setCurrentUser(id);
    setOpenEditDialog(true);
  }

  function renderTableRow(user: UserDTO) {
    if (permissions.includes("USERS_WRITE")) {
      return (
        <Tooltip title="Edit User" key={user.id}>
          <ClickableTableRow onClick={() => edit(user.id)} hover={true}>
            <TableCell>{user.username}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>
              {user.firstName} {user.lastName}
            </TableCell>
            <TableCell align="center">
              {user.fromIdentityProvider ? <Check /> : null}
            </TableCell>
          </ClickableTableRow>
        </Tooltip>
      );
    } else {
      return (
        <TableRow key={user.id}>
          <TableCell>{user.username}</TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell>
            {user.firstName} {user.lastName}
          </TableCell>
          <TableCell align="center">
            {user.fromIdentityProvider ? <Check /> : null}
          </TableCell>
        </TableRow>
      );
    }
  }

  return (
    <Paper elevation={4} className={classes.detailsContainer}>
      <Typography variant="h5" className={classes.title}>
        Users
        <RequirePermission permission="USERS_WRITE">
          <Tooltip className={classes.headerButton} title="Invite User">
            <Button
              color="primary"
              variant="contained"
              onClick={() => create()}
            >
              <Add />
            </Button>
          </Tooltip>
        </RequirePermission>
      </Typography>
      <RequirePermission permission="USERS_READ">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="center">From Identity Provider?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <Rows
              colSpan={6}
              data={users}
              dataApiState={usersState}
              fetchingText="We are fetching relevant users, please wait..."
              noDataText="No users (which is odd.. you should at least see yourself)"
              errorText="Failed to fetch users. Try again later"
              rowRender={renderTableRow}
            />
          </TableBody>
        </Table>
      </RequirePermission>
      <CreateUserDialog
        isOpen={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        afterSubmit={() => {
          refreshUsers();
          setOpenCreateDialog(false);
        }}
      />
      <EditUserDialog
        isOpen={openEditDialog}
        id={currentUser}
        onClose={() => setOpenEditDialog(false)}
        afterSubmit={() => {
          refreshUsers();
          setOpenEditDialog(false);
        }}
      />
    </Paper>
  );
};
