import {
  Button,
  createStyles,
  makeStyles,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import * as React from "react";
import { useAxios } from "../../../../../../api/useAxios";

interface Props {
  required?: boolean;
  serviceProviderId: string;
  autoFocus?: boolean;
  certificate?: ServiceProviderAgreementKeyvaultConfigurationDTO | null;
  onChange: (
    cert: ServiceProviderAgreementKeyvaultConfigurationDTO | null
  ) => any;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    fullWidth: {
      width: "100%",
    },
  })
);

const AzureKeyVaultConfig = (props: Props) => {
  const axios = useAxios();
  const classes = useStyles();
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  const [certificate, setCertificate] =
    React.useState<ServiceProviderAgreementKeyvaultConfigurationDTO>(
      props.certificate ??
        ({} as ServiceProviderAgreementKeyvaultConfigurationDTO)
    );

  function handleChange(
    property: keyof ServiceProviderAgreementKeyvaultConfigurationDTO,
    value: any
  ): void {
    certificate[property] = value;
    setCertificate({ ...certificate });
    props.onChange(
      Object.values(certificate).every((x) => !x) ? null : certificate
    );
  }

  function downloadOurCertificate() {
    // This certificate is used when the nemId server authenticates with the customers azure AD
    setIsDownloading(true);
    axios
      .get<FileDTO>(`/nem-id/certificate`, {
        params: { onBehalfOf: props.serviceProviderId },
      })
      .then((response) => {
        let element = document.createElement("a");
        element.setAttribute(
          "href",
          `data:${response.data.type};charset=utf-8,${response.data.base64Content}`
        );
        element.setAttribute("download", response.data.name);
        element.style.display = "none";

        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      })
      .finally(() => setIsDownloading(false));
  }

  return (
    <div className={classes.fullWidth}>
      <TextField
        label="Azure Tenant Id"
        autoFocus={props.autoFocus}
        fullWidth={true}
        type="text"
        required={props.required || Object.values(certificate).some((x) => !!x)}
        margin="normal"
        variant="outlined"
        value={certificate.azureTenantId ?? ""}
        onChange={(event) => handleChange("azureTenantId", event.target.value)}
      />
      <TextField
        label="Client Id"
        fullWidth={true}
        type="text"
        required={props.required || Object.values(certificate).some((x) => !!x)}
        margin="normal"
        variant="outlined"
        value={certificate.clientId ?? ""}
        onChange={(event) => handleChange("clientId", event.target.value)}
      />
      <TextField
        label="Key Name"
        fullWidth={true}
        type="text"
        required={props.required || Object.values(certificate).some((x) => !!x)}
        margin="normal"
        variant="outlined"
        value={certificate.keyName ?? ""}
        onChange={(event) => handleChange("keyName", event.target.value)}
      />
      <TextField
        label="KeyVault Url"
        fullWidth={true}
        type="text"
        required={props.required || Object.values(certificate).some((x) => !!x)}
        margin="normal"
        variant="outlined"
        value={certificate.keyVaultUrl ?? ""}
        onChange={(event) => handleChange("keyVaultUrl", event.target.value)}
      />

      <Tooltip title="Download cerificate and upload it to your Azure AD - this will grant access to the NemID server, so it can sign NemID requests in accordance with your certificate">
        <Button
          color="secondary"
          variant="contained"
          disabled={isDownloading}
          type="button"
          onClick={downloadOurCertificate}
        >
          <CloudDownload />
          &nbsp;&nbsp;&nbsp; Download Certificate
        </Button>
      </Tooltip>
    </div>
  );
};

export default AzureKeyVaultConfig;
