import React from "react";
import {
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from "@material-ui/core";
import { ConsentDocumentDTO } from "../../../../../models/Consents";
import Code from "../../../../../components/Code";

interface Props {
  consentDocument: ConsentDocumentDTO;
  isOpen: boolean;
  handleClose: () => void;
}

const ViewSourceDialog: React.FC<Props> = (props) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Source for consent document</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography variant="body2">HTML</Typography>
            <Code codeText={props.consentDocument.html} copyable />
          </Grid>
          <Grid item sm={6}>
            <Typography variant="body2">CSS</Typography>
            <Code codeText={props.consentDocument.css} copyable />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewSourceDialog;
