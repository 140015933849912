import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { useUpdateApiKey } from "../../../../../api/useApiKeysApi";

interface Props {
  data: ApiKeyBareDTO;
  isOpen: boolean;
  onClose: () => void;
}

const UpdateApiKeyDialog = ({ data, isOpen, onClose }: Props) => {
  const [name, setName] = useState<string>(data.name);
  const [updateApiKey] = useUpdateApiKey();

  function handleSubmit() {
    if (name !== "") {
      updateApiKey({ id: data.id, dto: { name } });
      onClose();
    }
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => onClose()}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle>Update api key</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            label="Name"
            value={name}
            required
            onChange={(event) => setName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button type="submit" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateApiKeyDialog;
