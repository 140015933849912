import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { CreateUserValidationSettingDTO } from "../../../../../models/UserValidationSettings";

const useStyles = makeStyles((theme) =>
  createStyles({
    errorText: {
      color: theme.palette.error.main,
    },
  })
);

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (createSettingDTO: CreateUserValidationSettingDTO) => void;
}

const CreateSettingDialog = ({ isOpen, onClose, onSubmit }: Props) => {
  const [name, setName] = React.useState<string>("");
  const [clientId, setClientId] = React.useState<string>("");
  const [clientSecret, setClientSecret] = React.useState<string>("");
  const [validationUrl, setValidationUrl] = React.useState<string>("");
  const [useAdminRealm, setUseAdminRealm] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const classes = useStyles();

  function submit() {
    if (
      clientId == null ||
      clientId === "" ||
      name == null ||
      name === "" ||
      clientSecret == null ||
      clientSecret === "" ||
      validationUrl == null ||
      validationUrl === ""
    ) {
      setErrorMessage(
        "One or more of the values are empty, please make sure all fields are filled out."
      );
      return;
    }

    onSubmit({
      clientId: clientId,
      name: name,
      clientSecret: clientSecret,
      validationUrl: validationUrl,
      useAdminRealm: useAdminRealm,
    });
  }

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        New User Validation Setting
      </DialogTitle>
      <DialogContent>
        {errorMessage == null ? null : (
          <span className={classes.errorText}>{errorMessage}</span>
        )}
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          required
          margin="dense"
          id="clientId"
          label="Client ID"
          fullWidth
          value={clientId}
          onChange={(event) => setClientId(event.target.value)}
        />
        <TextField
          required
          margin="dense"
          id="clientSecret"
          label="Client Secret"
          fullWidth
          value={clientSecret}
          onChange={(event) => setClientSecret(event.target.value)}
        />
        <TextField
          required
          margin="dense"
          id="validationUrl"
          label="Validation URL"
          fullWidth
          value={validationUrl}
          onChange={(event) => setValidationUrl(event.target.value)}
        />
        <FormControlLabel
          label="Use Admin Realm"
          control={
            <Checkbox
              id="useAdminRealm"
              onChange={(event) => setUseAdminRealm(event.target.checked)}
              checked={useAdminRealm}
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button type="submit" onClick={submit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSettingDialog;
