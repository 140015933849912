import * as React from "react";
import { PropsWithChildren, ReactElement } from "react";
import style from "./progressbar.module.css";

export const ProgressBar = (props: PropsWithChildren<any>) => {
  const width = `${100 / (props.children.length || 1)}%`;
  const children = props.children.map((child: ReactElement, index: number) =>
    React.cloneElement(child, { width, key: index })
  );

  return <ul className={style.progressbar}>{children}</ul>;
};
