import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Close, ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import * as React from "react";
import { MutableRefObject, useEffect } from "react";
import { useAxios } from "../../../../../../api/useAxios";
import DeleteButton from "../../../../../../components/DeleteButton";
import DeleteIconButton from "../../../../../../components/DeleteIconButton";
import { Timestamps } from "../Timestamps";
import ServiceProviderAgreementValidation from "../create/ServiceProviderAgreementValidation";
import AzureKeyVaultConfig from "../securityconfiguration/AzureKeyVaultConfig";
import Pkcs12Config from "../securityconfiguration/Pkcs12Config";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: "2.5rem 3rem 0.5rem",
    },
    titleContainer: {
      paddingBottom: "1.5rem",
    },
    title: {
      paddingRight: "1rem",
    },
    detailsContainer: {
      marginTop: "2rem",
      padding: "2rem",
      maxWidth: "1200px",
    },
    saveButton: {
      marginLeft: "1.2rem",
    },
    closeButton: {
      float: "right",
    },
    timestamps: {
      position: "absolute",
      left: "24px",
      width: "calc(100% - 340px)",
    },
    form: {
      height: "500px",
    },
    environment: {
      width: "170px",
      marginRight: "10px",
    },
    apiKey: {
      width: "calc(100% - 180px)",
    },
    certificateContainer: {
      padding: "1rem",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    deleteCertificateButton: {
      float: "right",
    },
    panelHeading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    panelSecondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    securityPanel: {
      marginBottom: "15px",
    },
  })
);

interface Props {
  isOpen: boolean;
  onClose: () => any;
  afterSubmit: () => any;
  id: string;
}

const ServiceProviderAgreementEditor = (props: Props) => {
  const axios = useAxios();
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [agreement, setAgreement] = React.useState<ServiceProviderAgreementDTO>(
    { apiKey: "" } as ServiceProviderAgreementDTO
  );
  const [name, setName] = React.useState<string>("");
  const [serviceProviderIdForRid, setServiceProviderIdForRid] =
    React.useState<string>("");
  const [serviceProviderIdForPid, setServiceProviderIdForPid] =
    React.useState<string>("");
  const [logonto, setLogonto] = React.useState<string>("");
  const [hasDeletedCertificate, setDeleteCertificate] =
    React.useState<boolean>(false);

  const [pkcs12, setPkcs12] =
    React.useState<ServiceProviderAgreementSecurityConfigurationDTO | null>(
      null
    );
  const [azure, setAzure] =
    React.useState<ServiceProviderAgreementKeyvaultConfigurationDTO | null>(
      null
    );
  const formRef =
    React.useRef<HTMLFormElement>() as MutableRefObject<HTMLFormElement>;
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const togglePanel =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    // Fires when we open/close the dialog
    setExpanded(false);
    const fetchData = async () => {
      const result = await axios.get<ServiceProviderAgreementDTO>(
        `/nem-id/service-provider-agreements/${props.id}`
      );
      setAgreement(result.data);
      setName(result.data.name);
      setServiceProviderIdForRid(result.data.ridServiceProviderId || "");
      setServiceProviderIdForPid(result.data.pidServiceProviderId || "");
      setLogonto(result.data.logOnTo);
      setDeleteCertificate(false);
      setIsSubmitting(false);
      setExpanded(
        !result.data.certificateDetails ? "newSecurityConfigPanel" : false
      );
    };
    if (props.isOpen) {
      fetchData();
    }
  }, [props.isOpen, props.id, axios]);

  function getSecurityConfigurationFields() {
    switch (agreement.certificateType) {
      case "PKCS12":
        return (
          <Pkcs12Config
            required={!agreement.certificateDetails}
            onChange={setPkcs12}
          />
        );
      case "AZURE":
        return (
          <AzureKeyVaultConfig
            required={!agreement.certificateDetails}
            serviceProviderId={agreement.id}
            onChange={setAzure}
          />
        );
      default:
        return (
          <b>
            ERROR: Unknown security configuration type '
            {JSON.stringify(agreement.certificateType) || "[no value]"}'
          </b>
        );
    }
  }

  function deleteCertificateIfNeeded(): Promise<void> {
    if (hasDeletedCertificate) {
      switch (agreement.certificateType) {
        case "PKCS12":
          return axios.delete<ServiceProviderAgreementDTO>(
            `/nem-id/service-provider-agreements/${props.id}/certificate`
          ) as any as Promise<void>;
        case "AZURE":
          return axios.patch<ServiceProviderAgreementDTO>(
            `/nem-id/service-provider-agreements/${props.id}/keyvault`,
            null
          ) as any as Promise<void>;
      }
    }
    return Promise.resolve();
  }

  function uploadCertificateIfNeeded(): Promise<void> {
    switch (agreement.certificateType) {
      case "PKCS12":
        if (!!pkcs12) {
          return axios.patch<ServiceProviderAgreementDTO>(
            `/nem-id/service-provider-agreements/${props.id}/certificate`,
            pkcs12
          ) as any as Promise<void>;
        }
        break;
      case "AZURE":
        if (!!azure) {
          return axios.patch<ServiceProviderAgreementDTO>(
            `/nem-id/service-provider-agreements/${props.id}/keyvault`,
            azure
          ) as any as Promise<void>;
        }
        break;
    }
    return Promise.resolve();
  }

  function save() {
    const form = formRef.current;
    if (!form.reportValidity()) {
      return;
    }
    setIsSubmitting(true);

    const result = Object.assign(agreement, {
      name,
      pidServiceProviderId: serviceProviderIdForPid,
      ridServiceProviderId: serviceProviderIdForRid,
      logOnTo: logonto,
    } as EditableServiceProviderAgreementDTO);

    deleteCertificateIfNeeded()
      .then(() => uploadCertificateIfNeeded())
      .then(() =>
        axios.put<ServiceProviderAgreementDTO>(
          `/nem-id/service-provider-agreements/${props.id}`,
          result
        )
      )
      .finally(() => {
        setIsSubmitting(false);
        props.afterSubmit();
      });
  }

  function deleteAgreement(): Promise<any> {
    return axios
      .delete(`/nem-id/service-provider-agreements/${props.id!}`)
      .finally(() => props.afterSubmit());
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      fullScreen={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Edit Service Provider Agreement
        <IconButton onClick={props.onClose} className={classes.closeButton}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form ref={formRef} className={classes.form}>
          <div>
            <TextField
              label="Environment"
              inputProps={{
                readOnly: true,
                disabled: true,
              }}
              type="text"
              margin="normal"
              className={classes.environment}
              variant="outlined"
              value={agreement.production ? "Production" : "Test"}
            />
            <Tooltip title="Use this API key in requests to the NemID server in your clients">
              <TextField
                label="Api key"
                inputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                type="text"
                margin="normal"
                className={classes.apiKey}
                variant="outlined"
                value={agreement.apiKey}
              />
            </Tooltip>
          </div>
          <TextField
            label="Name"
            autoFocus
            fullWidth={true}
            type="text"
            required={true}
            margin="normal"
            variant="outlined"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            label="LogOnTo Name"
            fullWidth={true}
            type="text"
            required={true}
            margin="normal"
            variant="outlined"
            value={logonto}
            onChange={(event) => setLogonto(event.target.value)}
          />
          <TextField
            label="Service Provider Id for PID"
            type="text"
            fullWidth={true}
            margin="normal"
            variant="outlined"
            value={serviceProviderIdForPid}
            onChange={(event) => setServiceProviderIdForPid(event.target.value)}
          />
          <TextField
            label="Service Provider Id for RID"
            type="text"
            fullWidth={true}
            margin="normal"
            variant="outlined"
            value={serviceProviderIdForRid}
            onChange={(event) => setServiceProviderIdForRid(event.target.value)}
          />
          {agreement.certificateDetails && !hasDeletedCertificate ? (
            <div>
              <Typography variant="h5" className={classes.title}>
                Current Certificate
              </Typography>
              <Paper elevation={2} className={classes.certificateContainer}>
                <DeleteIconButton
                  title="certificate"
                  className={classes.deleteCertificateButton}
                  onDelete={() => {
                    setDeleteCertificate(true);
                    setExpanded("newSecurityConfigPanel");
                  }}
                >
                  Are you sure you want to delete this certificate? Note that
                  the deletion won't be saved until you hit the "Save"-button.
                </DeleteIconButton>
                <p>Issuer: {agreement.certificateDetails?.issuer}</p>
                <p>Subject: {agreement.certificateDetails?.subject}</p>
                <p>
                  Valid From:{" "}
                  {agreement.certificateDetails?.validFrom.toLocaleDateString()}
                </p>
                <p>
                  Valid To:{" "}
                  {agreement.certificateDetails?.validTo.toLocaleDateString()}
                </p>
              </Paper>
            </div>
          ) : null}

          <ExpansionPanel
            className={classes.securityPanel}
            expanded={expanded === "newSecurityConfigPanel"}
            onChange={togglePanel("newSecurityConfigPanel")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="newSecurityConfigPanelbh-content"
              id="newSecurityConfigPanelbh-header"
            >
              <Typography className={classes.panelHeading}>
                {" "}
                New{" "}
                {agreement.certificateType === "PKCS12"
                  ? "Pkcs12 Certificate"
                  : "Certificate in KeyVault"}
              </Typography>
              <Typography className={classes.panelSecondaryHeading}>
                Replace existing security configuration
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {getSecurityConfigurationFields()}
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ServiceProviderAgreementValidation agreement={agreement} />
        </form>
      </DialogContent>
      <DialogActions>
        <Timestamps agreement={agreement} />
        <DeleteButton
          title="Service Provider Agreement"
          buttonText="Delete"
          children="Are you sure you want to delete this service provider agreement?"
          onDelete={deleteAgreement}
          variant="contained"
        />
        <Button
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          type="submit"
          onClick={save}
          className={classes.saveButton}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceProviderAgreementEditor;
