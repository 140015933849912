import * as React from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Tooltip,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { IconButtonProps } from "@material-ui/core/IconButton";

interface OwnProps {
  title: string;
  onDelete: () => void;
}

type Props = OwnProps & IconButtonProps;

const DeleteIconButton: React.FC<Props> = ({
  title,
  onDelete,
  children,
  ...rest
}) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  function onClose() {
    setIsDialogOpen(false);
  }

  function onOpen() {
    setIsDialogOpen(true);
  }

  return (
    <>
      <Tooltip title="Delete">
        <IconButton onClick={onOpen} {...rest}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={isDialogOpen}
        onClose={onClose}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Delete {title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onDelete} variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteIconButton;
